import React, { useState, useEffect } from 'react';
import { Card, Select, Tag, Space, Form, Button, DatePicker } from 'antd';
import { Link } from 'react-router-dom'
import { ShowButton, PageTitle, Spinner, DataTable } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import { setSessionStorage, getSessionStorage } from '../../../helpers/sessionStorage';
import moment from 'moment'

const { Option } = Select

const Report = () => {
    const [searchForm] = Form.useForm()
    const [loaded, setLoaded] = useState(true)
    const [divisions, setDivisions] = useState([])
    const [employees, setEmployees] = useState([])
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedPeriods, setSelectedPeriods] = useState(null)

    const fetchEmployeeSchema = (division, date) => {
        setLoaded(false)
        SentralModel.action('Payroll', 'getDailyPaymentList', { division_id: division, date: date }, 0).then((res) => {
            setEmployees(res.data)
            setLoaded(true)
        })
    }

    const fetchPeriods = (year, date, toDate) => {
        SentralModel.list('PayrollPeriod', { filter: [['year', year], ['from_date', '<=', date], ['to_date','<=', toDate]], orderBy: 'payroll_period_id', order: 'DESC', limit: 1 }).then(res => {
            setSelectedPeriods(res.data[0].payroll_period_id)
        })
    }

    const fetchDivisions = () => {
        SentralModel.list('Division').then((res) => {
            setDivisions(res.data)
        })
    }

    const onChangeDate = (v) => {
        let date = moment(v).format('yyyy-MM-DD');

        let year = moment(v).format('yyyy');
        let month = moment(v).format('MM');
        let from = moment(v).format('DD');

        let toMonth = (from > 20) ? (parseInt(month)+1).toString() : month

        let toDate = (year+'-'+toMonth+'-'+'20')

        fetchPeriods(year, date, toDate)

        setSelectedDate(date)
    }

    const disabledDate = (current) => {
        let currentMonth = moment().format('MM');
        let addMonth = 1;

        if (currentMonth === '12') {
            addMonth = 2;
        }

        let customDate = moment(new Date()).add(addMonth, 'y').format('YYYY');
        return current && current > moment(customDate, "YYYY");

    };

    useEffect(() => {
        let division = getSessionStorage('payrollPaymentDivision');
        let date = selectedDate != null ? selectedDate : moment().format('yyyy-MM-DD');

        if (division && date) {
            searchForm.setFieldsValue({
                division: parseInt(division),
                date: date
            })
            fetchEmployeeSchema(division, date)
            setLoaded(true)
        }

        fetchDivisions()
    }, [searchForm]);

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    }, {
        title: 'Position',
        dataIndex: 'position_name',
        key: 'position_name',
    }, {
        title: 'Schema',
        width: '20%',
        align: 'center',
        render: (row) => (
            <Link to={'/payroll/schema/' + row.employee_code}>
                {
                    (row.payroll_schema === 0) ?
                        <Tag style={{ width: 150, cursor: 'pointer' }} color="red">SCHEMA NOT FOUND</Tag> :
                        <Tag style={{ width: 150, cursor: 'pointer' }} color="green">SCHEMA AVAILABLE</Tag>
                }
            </Link>
        )
    }, {
        title: 'Payment',
        width: '20%',
        dataIndex: 'payment',
        align: 'center',
        render: (text) => (
            (text === 0) ?
                <Tag style={{ width: 100 }} color="red">UNPAID</Tag> :
                <Tag style={{ width: 100 }} color="green">PAID</Tag>
        )
    }, {
        title: 'Action',
        align: 'center',
        width: '5%',
        render: (row) => (
            <ShowButton link to={'/payroll/daily-payment/' + row.employee_code + '/' + selectedPeriods + '/' + selectedDate} />
        )
    }
    ];

    const filterPayroll = (v) => {
        fetchEmployeeSchema(v.division, selectedDate)
        setSessionStorage('payrollPaymentDivision', v.division)

        console.log(selectedPeriods)
    }

    return (
        <Card className="content-container">
            <PageTitle title="Daily Payment" breadcrumbs={[['Daily Payment']]}></PageTitle>
            <div className="full-width">
                <span className="mb-3" style={{ float: 'right' }}>
                    <Space>
                        <Form form={searchForm} onFinish={filterPayroll} layout="inline">
                            <Form.Item name="division" rules={[{ required: true, message: 'select division' }]}>
                                <Select placeholder="Select Division" style={{ width: 250 }}>
                                    {
                                        divisions.map((el, key) => (
                                            <Option key={'division_' + key} value={el.division_id}>{el.division_name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item valuePropName={'date'} name="date" rules={[{ required: true, message: 'select date' }]}>
                                <DatePicker onChange={onChangeDate} disabledDate={disabledDate} placeholder="Select Date" />
                            </Form.Item>
                            <Form.Item style={{ marginRight: 0 }}>
                                <Button htmlType="submit" type="primary">Show</Button>
                            </Form.Item>
                        </Form>
                    </Space>
                </span>
            </div>
            {
                (loaded) ?
                    <DataTable bordered size="small" columns={columns} dataSource={employees} />
                    : <Spinner />
            }
        </Card>
    );
}

export default Report;

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, Button, Tabs, Space, Form, Input, DatePicker, Switch, Select, message, Tag } from 'antd';
import { CalendarOutlined, ClockCircleOutlined, CheckOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Spinner } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import authData from '../../../helpers/authData';
import { DataTable, EditButton, FormModal } from '../../../components';
import { toFullDate } from '../../../utils/dateHandler';

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Task = () => {
    const [loaded, setLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [myTasks, setMyTasks] = useState([]);
    const [assignTasks, setAssignTasks] = useState([]);
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({ job_id: 0, finished: false });
    const [modalTitle, setModalTitle] = useState(null);
    const [status, setStatus] = useState(null);
    const [action, setAction] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [taskTo, setTaskTo] = useState([]);
    const [tab, setTab] = useState('in');
    const [disabled, setDisabled] = useState(false);
    const [filterDateRange, setFilterDateRange] = useState([]);

    const fetchMyTask = (v) => {
        setLoaded(false)
        SentralModel.action('EmployeeTask', 'getMyTask', v, null).then((res) => {
            setMyTasks(res.data)
            setLoaded(true)
        })
    }

    const fetchAssignTask = (v) => {
        setLoaded(false)
        SentralModel.action('EmployeeTask', 'getAssignTask', v, null).then((res) => {
            setAssignTasks(res.data)
            setLoaded(true)
        })
    }

    const fetchEmployees = () => {
        SentralModel.list('Employee', { filter: [['is_active', '=', '1']], orderBy: 'name', order: 'asc' }).then(res => {
            setEmployees(res.data)
        })
    }

    useEffect(() => {
        fetchMyTask({ start_date: null, end_date: null })
        fetchAssignTask({ start_date: null, end_date: null })
        fetchEmployees()
    }, []);

    const column = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
        }, {
            title: tab === 'in' ? 'Task From' : 'Task To',
            render: (row) => (
                tab === 'in' ?
                    row?.from?.name ?? '-'
                    :
                    row?.assign?.length > 0 ?
                        row.assign.map((i) => (
                            <Tag>{i.employee.name}</Tag>
                        ))
                        : '-'
            ),
            align: 'center'
        }, {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        }, {
            title: 'Deadline',
            key: 'deadline',
            render: (row) => (
                toFullDate(row?.deadline)
            )
        }, {
            title: 'Note',
            render: (row) => (
                row?.reply ?? '-'
            ),
            key: 'reply',
        }, {
            title: 'Finished Date',
            render: (row) => (
                row?.finished_date === null ? '-' : moment(row?.finished_date).format('DD MMMM YYYY')
            ),
            key: 'finished_date',
        }, {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        }
    ];

    if (tab === 'in') {
        column.push({
            title: 'Action',
            align: 'center',
            render: (row) => (
                <Space>
                    <EditButton onEdit={() => editTask(row)} />
                </Space>
            )
        })
    }

    const addTask = () => {
        setModalTitle('Add Task')
        setAction('add')
        setDefaultValues({
            emp_task_id: 0,
            employee_code: authData.employee_code,
            description: null,
            deadline: null
        })
        setDisabled(false)
        setFormModal(true)
    }

    const editTask = (v) => {
        setModalTitle('Edit Task')
        setAction('edit')
        setDefaultValues({
            emp_task_id: v.emp_task_id,
            employee_code: v.created_by,
            data_from: v.data_from,
            task_to: v.assign_to,
            description: v.description,
            deadline: moment(v.due_date),
            note: v.reply,
            finished_date: v.finished_date === null ? null : moment(v.finished_date),
            status: v.status === 'Finished' ? true : false
        })
        setTaskTo(v.assign_to)
        setDisabled(true)
        setFormModal(true)
    }

    const saveJob = (v) => {
        setSaving(true)
        let payload;
        if (v === 'in') {
            payload = {
                action: action,
                emp_task_id: v.emp_task_id,
                employee_code: authData.employee_code,
                data_from: 'TASK KARAJO',
                task_to: taskTo,
                description: v.description,
                deadline: moment(v.deadline).format('YYYY-MM-DD'),
                status: 'In Progress'
            }
        } else {
            payload = {
                reply: v.reply,
                status: status === true ? 'Finished' : 'In Progress',
                finished_date: moment(v.finished_date).format('YYYY-MM-DD')
            }
        }

        SentralModel.action('EmployeeTask', 'saveTask', payload, v.emp_task_id).then((res) => {
            setFormModal(false)
            message.success('Task Saved', 2)
            setSaving(false)
            fetchAssignTask({ start_date: null, end_date: null })
            fetchMyTask({ start_date: null, end_date: null })
        }).catch((err) => {
            message.warning('Failed Save Task')
            setSaving(false)
        })
    }

    const changeTab = e => {
        setTab(e)
    }

    const changeEmployee = e => {
        setTaskTo(e)
    }

    const changeStatus = e => {
        console.log(e)
        setStatus(e)
    }

    const filterTask = (v) => {
        if (filterDateRange.length === 0) {
            fetchAssignTask({ start_date: null, end_date: null })
            fetchMyTask({ start_date: null, end_date: null })
        } else {
            fetchAssignTask({ start_date: filterDateRange[0], end_date: filterDateRange[1] })
            fetchMyTask({ start_date: filterDateRange[0], end_date: filterDateRange[1] })
        }
    }

    const resetTask = () => {
        fetchAssignTask({ start_date: null, end_date: null })
        fetchMyTask({ start_date: null, end_date: null })
    }

    return (
        <div>
            <Row justify='end' className='mb-3'>
                <Col span={24} >
                    <span style={{ float: 'left' }}>
                        <Button type='primary' color='#ededed' onClick={() => addTask()} icon={<PlusCircleOutlined />}>
                            Add Task
                        </Button>
                    </span>
                </Col>
            </Row>
            {
                loaded ?
                    <>
                        <Row justify='end'>
                            <Col>
                                <Space>
                                    <RangePicker onChange={(v) => setFilterDateRange([moment(v[0]).format('YYYY-MM-DD'), moment(v[1]).format('YYYY-MM-DD')])} />
                                    <Button type='primary' onClick={filterTask}>
                                        Filter
                                    </Button>
                                    <Button type='primary' danger onClick={resetTask}>
                                        Reset
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Tabs defaultActiveKey='task' type='card' onChange={changeTab}>
                            <TabPane tab={<span><CalendarOutlined style={{ color: '#4ca3ff' }} /> My Task</span>} key='in'>
                                <Tabs defaultActiveKey='status' type='card'>
                                    <TabPane tab={<span><ClockCircleOutlined style={{ color: '#4ca3ff' }} /> On Progress</span>} key='progress'>
                                        <DataTable size='small' columns={column} dataSource={myTasks.filter(el => el.status !== 'Finished')} bordered />
                                    </TabPane>
                                    <TabPane tab={<span><CheckOutlined style={{ color: 'green' }} /> Finished</span>} key='finished'>
                                        <DataTable size='small' columns={column} dataSource={myTasks.filter(el => el.status === 'Finished')} bordered />
                                    </TabPane>
                                </Tabs>
                            </TabPane>
                            <TabPane tab={<span><ClockCircleOutlined style={{ color: 'red' }} /> Assigned Task</span>} key='out'>
                                <Tabs defaultActiveKey='status' type='card'>
                                    <TabPane tab={<span><ClockCircleOutlined style={{ color: '#4ca3ff' }} /> On Progress</span>} key='progress'>
                                        <DataTable size='small' columns={column} dataSource={assignTasks.filter(el => el.status !== 'Finished')} bordered />
                                    </TabPane>
                                    <TabPane tab={<span><CheckOutlined style={{ color: 'green' }} /> Finished</span>} key='finished'>
                                        <DataTable size='small' columns={column} dataSource={assignTasks.filter(el => el.status === 'Finished')} bordered />
                                    </TabPane>
                                </Tabs>
                            </TabPane>
                        </Tabs>
                    </>
                    : <Spinner />
            }

            {
                (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveJob(v)} width={800} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name='emp_task_id' hidden>
                            <Input placeholder='ID' />
                        </Form.Item>
                        <Form.Item name='employee_code' hidden>
                            <Input placeholder='Employee Code' />
                        </Form.Item>
                        <Form.Item name='data_from' hidden>
                            <Input placeholder='Data From' />
                        </Form.Item>
                        {
                            action === 'add' ?
                                <Form.Item label='Task To' name='task_to' rules={[{ required: true }]}>
                                    <Select mode='multiple' showSearch allowClear placeholder='Select employee' optionFilterProp='children' style={{ width: '100%' }} onChange={changeEmployee} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {
                                            employees.map((el, key) => (
                                                <Option key={key} value={el.employee_code}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                : null
                        }
                        <Form.Item label='Description' name='description' rules={[{ required: true }]}>
                            <Input placeholder='Description' disabled={disabled} />
                        </Form.Item>
                        <Form.Item label='Deadline' name='deadline' rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} disabled={disabled} />
                        </Form.Item>
                        {
                            action === 'edit' ?
                                <>
                                    <Form.Item label='Reply' name='reply' rules={[{ required: true }]}>
                                        <Input.TextArea rows={3} placeholder='Reply' />
                                    </Form.Item>
                                    <Form.Item label='Finished Date' name='finished_date' rules={[{ required: true }]}>
                                        <DatePicker format='DD MMMM YYYY' style={{ width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item label='Status' name='status'>
                                        <Switch checkedChildren='Finished' unCheckedChildren='In Progress' defaultChecked={defaultValues.status} onChange={changeStatus} />
                                    </Form.Item>
                                </>
                                : null
                        }
                    </FormModal>
                    : null
            }
        </div>
    );
}

export default Task;

import React, { useState } from 'react';
import { Descriptions, Modal, Typography, Input, Button, message } from 'antd';
import { toIdr } from '../../../utils/currencyHandler'
import { Spinner } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
const { TextArea } = Input
const { Text } = Typography

const ConfirmPayment = (props) => {
    const { data, paymentId = 0, periodId = 0, employee = null } = props
    const [remarks, setRemarks] = useState(null)
    const [saving, setSaving] = useState(false)
    
    const salaryValue = {
        float: 'right'
    }

    const getArray = (type) => {
        let keys = Object.keys(data[type])
        let values = Object.values(data[type])
        let x = [];
        keys.forEach((el, key) => {
            x.push([el, values[key]])
        })
        return x
    }

    const getTotal = (type) => {
        // if(type==='deductions'){
        //     console.log(Object.values(data['deductions']))
        // }
        let values = Object.values(data[type])
        let total = 0;
        values.forEach(el => {
            // if (typeof el === "object") {
            //     let values2 = Object.values(el)
            //     values2.forEach(el2 => {
            //         total+=el2
            //     })
            // } else {
            //     total+=el
            // }

            if (typeof el !== "object") {
                total += el
            }
        })
        return total;
    }

    const allAllowances = () => {
        let alw = [['Gaji Pokok', data.earnings.base]]
        getArray('bonus').forEach(el => {
            alw.push(el)
        })
        getArray('allowances').forEach(el => {
            alw.push(el)
        })
        return alw
    }

    const allDeductions = () => {
        return getArray('deductions')
    }

    const getTotalAllowances = () => {
        return getTotal('allowances') + getTotal('bonus') + data.earnings.base
    }
    const getTotalDeductions = () => {
        return getTotal('deductions')
    }

    const submitPayment = () => {
        setSaving(true)
        
        let a = [];
        allAllowances().filter(alw => alw[1] > 0).map((el) => {
            return a.push({ description: el[0], amount: el[1] })
        })

        let d = [];
        allDeductions().filter(ded => ded[1] > 0).map((el) => {
            return d.push({ description: el[0], amount: el[1] })
        })

        let x = [];
        allAllowances().filter(alw => alw[1] > 0 && (alw[0] !== 'Gaji Pokok' && alw[0] !== 'Overtime' && alw[0] !== 'JKK' && alw[0] !== 'JKM' && alw[0] !== 'BPJSKesehatan' && !alw[0].match('Daily Incentive') && !alw[0].match('Weekly Incentive'))).map((el) => {
            return x.push({ description: el[0], amount: el[1] })
        })

        let l = [];
        allDeductions().filter(alw => alw[1] > 0 && alw[0].match('Late')).map((el) => {
            return l.push({ description: el[0], amount: el[1] })
        })

        let y = [];
        allDeductions().filter(alw => alw[1] > 0 && (alw[0] !== 'JHT' && alw[0] !== 'JIP' && alw[0] !== 'presence' && !alw[0].match('PPh21') && !alw[0].match('Late'))).map((el) => {
            return y.push({ description: el[0], amount: el[1] })
        })

        let totalA = 0;
        a.forEach(el => {
            totalA += el.amount;
        })

        let totalD = 0;
        d.forEach(el => {
            totalD += el.amount;
        })

        let totalX = 0;
        x.forEach(el => {
            totalX += el.amount;
        })

        let totalL = 0;
        l.forEach(el => {
            totalL += el.amount;
        })

        let totalY = 0;
        y.forEach(el => {
            totalY += el.amount;
        })
        
        let bpjsKes = a.find(el => el.description === 'BPJSKesehatan')
        let base = a.find(el => el.description === 'Gaji Pokok')
        let jkk = a.find(el => el.description === 'JKK')
        let jkm = a.find(el => el.description === 'JKM')
        let daily_incentive = a.find(el => el.description.match('Daily Incentive'))
        let weekly_incentive = a.find(el => el.description.match('Weekly Incentive'))
        let overtime = a.find(el => el.description === 'Overtime')

        let absence = d.find(el => el.description === 'presence')
        let jht = d.find(el => el.description === 'JHT')
        let jip = d.find(el => el.description === 'JIP')
        let pph = d.find(el => el.description === 'PPh21 Monthly Tax')

        const payload = {
            remarks: remarks,
            allowances: a,
            deductions: d,
            payment_id: paymentId,
            period_id: periodId,
            employee_code: employee.employee_code,
            base_salary: (base) ? base.amount : 0,
            bruto: data.earnings.bruto,
            daily_incentive: (daily_incentive) ? daily_incentive.amount : 0,
            weekly_incentive: (weekly_incentive) ? weekly_incentive.amount : 0,
            overtime: (overtime) ? overtime.amount : 0,
            jkk: (jkk) ? jkk.amount : 0,
            jkm: (jkm) ? jkm.amount : 0,
            bpjs_kes: (bpjsKes) ? bpjsKes.amount : 0,
            other_allowance: totalX,
            total_allowance: totalA,
            late_fee: totalL,
            absence: (absence) ? absence.amount : 0,
            jht: (jht) ? jht.amount : 0,
            jip: (jip) ? jip.amount : 0,
            pph21: (pph) ? pph.amount : 0,
            other_deduction: totalY,
            total_deduction: totalD,
            take_home_pay: (totalA - totalD)
        }

        SentralModel.action('Payroll', 'confirmPayment', payload, 0).then(() => {
            message.success('Payment Confirmed', 2)
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }).catch(() => {
            setSaving(false)
        })
    }

    return (
        <div>
            <Modal
                title="Salary Detail"
                style={{ top: 20 }}
                visible={props.showModal}
                width={1000}
                onCancel={() => props.setModal(false)}
                footer={null}>
                {
                    (employee) ?
                        <Descriptions className="mb-3" size="middle" column={5} bordered layout="vertical">
                            <Descriptions.Item label={<div className="text-center"><Text strong>Employee Name</Text></div>}>
                                <div className="text-center">{employee.name}</div>
                            </Descriptions.Item>
                            <Descriptions.Item label={<div className="text-center"><Text strong>Division</Text></div>}>
                                <div className="text-center">{employee.division_name}</div>
                            </Descriptions.Item>
                            <Descriptions.Item label={<div className="text-center"><Text strong>Position</Text></div>}>
                                <div className="text-center">{employee.position_name}</div>
                            </Descriptions.Item>
                            <Descriptions.Item label={<div className="text-center"><Text strong>Period</Text></div>}>
                                <div className="text-center">{employee.period_name}</div>
                            </Descriptions.Item>
                            <Descriptions.Item label={<div className="text-center"><Text strong>Method</Text></div>}>
                                <div className="text-center">{(data) ? data.type : null}</div>
                            </Descriptions.Item>
                        </Descriptions>
                        : null
                }
                {
                    (data) ?
                        <div>
                            <table className="table-collapse table-default mb-3" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ width: '50%' }}>Allowance</th>
                                        <th className="text-center" style={{ width: '50%' }}>Deduction</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}>
                                            <Descriptions size="small" column={1} bordered layout="horizontal">
                                                {
                                                    (allAllowances().filter(alw => alw[1] > 0).map((el, key) => (
                                                        <Descriptions.Item key={key} label={<Text strong>{el[0]}</Text>}>
                                                            <span style={salaryValue}>{toIdr(el[1])}</span>
                                                        </Descriptions.Item>
                                                    )))
                                                }
                                            </Descriptions>
                                        </td>
                                        <td style={{ verticalAlign: 'top' }}>
                                            <Descriptions size="small" column={1} bordered layout="horizontal">
                                                {
                                                    (allDeductions().filter(ded => ded[1] > 0).map((el, key) => (
                                                        <Descriptions.Item key={key} label={<Text strong>{el[0]}</Text>}>
                                                            <span style={salaryValue}>{toIdr(el[1])}</span>
                                                        </Descriptions.Item>
                                                    )))
                                                }
                                            </Descriptions>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-right">
                                            <Text strong>Allowance Total : {toIdr(getTotalAllowances())}</Text>
                                        </td>
                                        <td className="text-right">
                                            <Text strong>Deduction Total: {toIdr(getTotalDeductions())}</Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-right">
                                            <Text strong>Take Home Pay : {toIdr(getTotalAllowances() - getTotalDeductions())}</Text>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <TextArea onChange={(v) => setRemarks(v.target.value)} placeholder="Write remarks" />
                            <div className="full-width mt-2 text-right">
                                <Button size="middle" type="primary" onClick={submitPayment} loading={saving}>Confirm</Button>
                            </div>
                        </div> : <Spinner />
                }
            </Modal>
        </div>
    );
}

export default ConfirmPayment;

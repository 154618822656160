import React, { useEffect, useState } from 'react';
import { Card, Typography, Form, Input, TimePicker, message, Space } from 'antd'
import { AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable, CustomButton } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { SettingOutlined } from '@ant-design/icons'
import moment from 'moment'

const { Text } = Typography
const Index = () => {
    const [divisions, setDivisions] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)

    const fetchDivisions = () => {
        SentralModel.list('Division', { orderBy: "is_active", order: "desc" }).then(res => {
            setDivisions(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchDivisions()
    }, []);

    const editDivision = (v) => {
        setModalTitle('Edit Division')
        setDefaultValues({
            division_id: v.id,
            division_name: v.division_name,
            daily_in: moment(v.daily_in, 'HH:mm'),
            daily_out: moment(v.daily_out, 'HH:mm'),
        })
        setFormModal(true)
    }

    const deleteDivision = (id) => {
        message.loading('deleting division')
        SentralModel.deleteById('Division', id).then((res) => {
            message.destroy()
            message.success('Division deleted', 2)
            fetchDivisions()
        })
    }

    const dataSource = [
        ...divisions.map(el => {
            return {
                id: el.division_id,
                division_name: el.division_name,
                daily_in: el.daily_in,
                daily_out: el.daily_out
            }
        })
    ]

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    },
    {
        title: 'Division Name',
        dataIndex: 'division_name',
        key: 'division_name',
    },
    {
        title: 'Working Hours',
        render: (row) => (
            <div className="text-center">
                <span>
                    {row.daily_in} - {row.daily_out}
                </span>
            </div>
        )
    },
    {
        title: 'Action',
        width: 120,
        render: (row) => (
            <div className="text-center">
                <Space>
                    <EditButton onEdit={() => editDivision(row)} />
                    <CustomButton icon={<SettingOutlined />} link to={'/organization/division/custom?id=' + row.id} />
                    <DeleteButton onConfirm={() => deleteDivision(row.id)} />
                </Space>
            </div>
        )
    },
    ];

    const addDivision = () => {
        setModalTitle('Add Division')
        setDefaultValues({
            division_id: 0,
            division_name: '',
            daily_in: '',
            daily_out: '',
        })
        setFormModal(true)
    }

    const saveDivision = (v) => {
        setSaving(true)
        let payload = {
            division_id: v.division_id,
            division_name: v.division_name,
            daily_in: moment(v.daily_in).format('HH:mm'),
            daily_out: moment(v.daily_out).format('HH:mm')
        }
        SentralModel.save('Division', payload, v.division_id).then((res) => {
            setFormModal(false)
            fetchDivisions();
            message.success('Division saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }


    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Divisions" breadcrumbs={[['Division']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Division List</Text>
                        <AddButton right onAdd={addDivision} title="New Division" />
                    </div>}>

                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>

                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveDivision(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="division_id" hidden>
                                <Input placeholder="ID" />
                            </Form.Item>
                            <Form.Item label="Division Name" name="division_name" required rules={[{ required: true }]}>
                                <Input placeholder="Division Name" />
                            </Form.Item>
                            <Form.Item label="Daily In" name="daily_in" rules={[{ required: true }]}>
                                <TimePicker format="HH:mm" style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item label="Daily Out" name="daily_out" rules={[{ required: true }]}>
                                <TimePicker format="HH:mm" style={{ width: '100%' }} />
                            </Form.Item>
                        </FormModal>
                        : null
                }
            </Card>
        </div>
    );
}

export default Index;

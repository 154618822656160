import React, { useState, useEffect } from 'react';
import { Card, Select, Tabs, Col, Row, Empty, DatePicker } from 'antd';
import { PageTitle, Spinner } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import moment from 'moment'
// import {useParams} from 'react-router-dom'

import SptMasa from './SptMasa'
import SptBulanan from './SptBulanan'
import SptNonFinal from './SptNonFinal'
import SptFinal from './SptFinal'
// import SptSsp from './SptSsp'

const { TabPane } = Tabs
const { Option } = Select

const Detail = () => {
    // const {employeeCode} = useParams()
    const [selectedPeriod, setSelectedPeriod] = useState(null)
    const [pemotong, setPemotong] = useState(null)
    const [employee, setEmployee] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [periods, setPeriods] = useState([])

    const fetchPemotongData = () => {
        SentralModel.list('Company', { filter: [['is_main', 1]] }).then((res) => {
            setPemotong(res.data[0] || null)
            setLoaded(true)
        })
    }

    const fetchEmployee = () => {
        SentralModel.get('PayrollRegulation',{},1).then(res=>{
            setEmployee(res.data.spt_pemotong.toString())
            setLoaded(true)
        })
    }

    const fetchPeriods = (year) => {
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, { orderBy: 'from_date', order: 'desc' }, 0).then((res) => {
            setPeriods(res.data)
        })
    }

    const onChangeYear = (v) => {
        let year = moment(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
    }

    useEffect(() => {
        fetchPemotongData()
        fetchEmployee()
    }, []);

    const selectPeriod = (v) => {
        setLoaded(false)
        setSelectedPeriod(v)
        setTimeout(() => {
            setLoaded(true)
        }, 1000);
    }

    const disabledDate = (current) => {
        let currentMonth = moment().format('MM');
        let addMonth = 1;

        if (currentMonth === '12') {
            addMonth = 2;
        }

        let customDate = moment(new Date()).add(addMonth, 'y').format('YYYY');
        return current && current > moment(customDate, "YYYY");
    };

    return (
        <Card className="content-container">
            <PageTitle title="Lapor SPT" breadcrumbs={[['Lapor SPT', '/payroll/spt'], ['Detail']]}></PageTitle>
            <Row>
                <Col span={24}>
                    <span style={{ float: 'right' }}>
                        <Select placeholder="Select Period" value={selectedPeriod} onChange={selectPeriod} style={{ width: 250, float: 'right' }}>
                            {
                                periods.map((el, key) => (
                                    <Option key={key} value={el.payroll_period_id}>{el.period}</Option>
                                ))
                            }
                        </Select>
                    </span>
                    <span style={{ float: 'right', marginRight: '20px' }}>
                        <DatePicker picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year" />
                    </span>
                </Col>
            </Row>
            <div className="full-width mt-3">
                {
                    (loaded) ?
                        (pemotong && selectedPeriod) ?
                            <Tabs defaultActiveKey="masa" tabPosition='left'>
                                <TabPane tab='Masa (1721)' key='masa'>
                                    <SptMasa pemotong={pemotong} period={selectedPeriod} employee={employee} />
                                </TabPane>
                                <TabPane tab='Bulanan (1721 - I)' key='bulanan'>
                                    <SptBulanan pemotong={pemotong} period={selectedPeriod} />
                                </TabPane>
                                <TabPane tab='Tidak Final (1721 - II)' key='tidakfinal'>
                                    <SptNonFinal pemotong={pemotong} period={selectedPeriod} />
                                </TabPane>
                                <TabPane tab='Final (1721 - III)' key='final'>
                                    <SptFinal pemotong={pemotong} period={selectedPeriod} />
                                </TabPane>
                                {/* <TabPane tab='SSP (1721 - IV)' key='ssp'>
                                    <SptSsp/>
                                </TabPane> */}
                            </Tabs>
                            : <Empty />
                        : <Spinner />
                }
            </div>
        </Card>
    );
}

export default Detail;

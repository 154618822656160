// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spt-item-masa{
    position: absolute;
    font-size: 17px;
}

.spt-item-bulanan{
    position: absolute;
    font-size: 12px;
}

@media print{
    .spt-masa-box{
        margin:0px;
        padding:0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/spt.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI;QACI,UAAU;QACV,WAAW;IACf;AACJ","sourcesContent":[".spt-item-masa{\n    position: absolute;\n    font-size: 17px;\n}\n\n.spt-item-bulanan{\n    position: absolute;\n    font-size: 12px;\n}\n\n@media print{\n    .spt-masa-box{\n        margin:0px;\n        padding:0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

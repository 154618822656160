import React, { useEffect, useState } from 'react';
import { Card, Descriptions, Typography, Form, Checkbox, Input, message, Select, Row, Button, Col } from 'antd'
import { useParams } from 'react-router-dom';
import { PageTitle, Spinner, SubmitButton } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { toFullDate } from '../../../utils/dateHandler'

const { Text } = Typography
const { Option } = Select

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const SetItem = () => {
    const forceUpdate = useForceUpdate()
    const { employeeCode } = useParams()
    const [kpiItems, setKpiItems] = useState([])
    const [currentSelected, setCurrentSelected] = useState({})
    const [employee, setEmployee] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [saving, setSaving] = useState(false)
    const [totalCategory, setTotalCategory] = useState([])
    const [totalPercentage, setTotalPercentage] = useState(0)
    const [setItemForm] = Form.useForm()
    const [division, setDivision] = useState([])
    const [position, setPosition] = useState([])
    const [totalCategoryValue, setTotalCategoryValue] = useState(0)
    const [totalItemByCategory, setTotalItemByCategory] = useState([])
    const [itemCategories, setItemCategories] = useState({})
    const [items, setItems] = useState({})
    const [totalItems, setTotalItems] = useState({})
    const [totalCategories, setTotalCategories] = useState({})
    const [listCategory, setListCategory] = useState([])

    const fetchEmployee = (employeeCode) => {
        SentralModel.get('Employee', { fields: 'name,employee_code,division_id,position_id,join_date' }, employeeCode).then(res => {
            setLoaded(true)
            setEmployee(res.data)
        })
    }

    const fetchDivision = () => {
        SentralModel.list('Division', { filter: [["is_active", "1"]], orderBy: "division_name", order: "asc" }).then(res => {
            setDivision(res.data)
        })
    }

    const fetchPosition = () => {
        SentralModel.list('Position', { filter: [["is_active", "1"], ["deleted_at", null]], orderBy: "position_name", order: "asc" }).then(res => {
            const v = res.data
            let arr = []
            v.forEach((el) => {
                arr.push({ value: el.position_id, label: el.position_name })
            })
            setPosition(arr)
        })
    }

    const fetchKpiItems = (employeeCode, setItemForm) => {
        SentralModel.action('Appraisal', 'getAppraisalSetup', { employee_code: employeeCode }).then(res => {
            setKpiItems(res.data)
            let x = {};
            let objValue = {};
            let ctgValue = {};
            let totalAll = 0;
            let totalCategory = 0;
            let dataCtg = [];
            let dataItemByCtg = [];
            let listCtg = [];

            res.data.forEach(ctg => {
                let totalValueCategory = 0;
                ctg.items.forEach(item => {
                    x['kpi_item_' + item.kpi_item_id] = item.selected
                    setItemForm.setFieldsValue({
                        ['kpi_item_' + item.kpi_item_id]: item.selected
                    })

                    setItemForm.setFieldsValue({
                        ['kpi_item_value_' + item.kpi_item_id]: item.rate
                    })

                    if (item.rate !== 0) {
                        Object.assign(objValue, { ['kpi_item_id_' + item.kpi_item_id]: item.rate })
                    }

                    totalValueCategory += item.rate
                })

                if (ctg.rate > 0) {
                    Object.assign(ctgValue, { ['kpi_item_category_id_' + ctg.kpi_item_category_id]: ctg.rate })
                }

                setItemForm.setFieldsValue({
                    ['kpi_category_value_' + ctg.kpi_item_category_id]: ctg.rate
                })

                setItemForm.setFieldsValue({
                    ['kpi_category_' + ctg.kpi_item_category_id]: true
                })

                totalAll += totalValueCategory;
                let totalByCtg = ctg.items.reduce((total, item) => total + item.rate, 0)
                let category = {
                    category_id: ctg.kpi_item_category_id,
                    total: ctg.rate ?? 0
                }

                let itemByCategory = {
                    category_id: ctg.kpi_item_category_id,
                    total: totalByCtg ?? 0
                }

                dataItemByCtg.push(itemByCategory)
                dataCtg.push(category);

                totalCategory += ctg.rate

                if (ctg.rate !== 0) {
                    listCtg.push(ctg.kpi_item_category_id)
                }
            });

            setCurrentSelected(x)
            setTotalPercentage(totalAll)
            setTotalCategory(dataCtg)
            setTotalCategoryValue(totalCategory)
            setTotalItemByCategory(dataItemByCtg)
            setListCategory(listCtg)
            setTotalItems(objValue)
            setTotalCategories(ctgValue)
            setLoaded(true)
        })
    }

    const resetValue = () => {
        SentralModel.action("Appraisal", "resetKpiAppraisalSetup", { employee_code: employeeCode }).then((res) => {
            message.success("Success reset value")
            fetchKpiItems(employeeCode, setItemForm)
        }).catch((err) => {
            message.warning("Failed reset value")
        })
    }

    const handleChangeDivision = (v) => {
        let payload = {
            employee_code: employeeCode,
            division_id: v
        }

        SentralModel.action("Division", "updateDivision", payload, 0).then((res) => {
            message.success("Success Update Division")
            fetchEmployee(employeeCode)
            fetchKpiItems(employeeCode, setItemForm)
        }).catch((err) => {
            message.warning("Failed Update Division")
        })
    }

    const handleChangePosition = (v) => {
        let payload = {
            employee_code: employeeCode,
            position_id: v
        }

        SentralModel.action("Position", "updatePosition", payload, 0).then((res) => {
            message.success("Success Update Position")
            fetchEmployee(employeeCode)
            fetchKpiItems(employeeCode, setItemForm)
        }).catch((err) => {
            message.warning("Failed Update Position")
        })
    }


    const saveKpiItem = (v) => {
        const newObj = Object.assign(totalItems, items)
        const arrObj = Object.values(newObj)

        if (arrObj.includes(0)) {
            message.warning("Failed save data, item percentage value cannot be 0")
            return
        }
        if ((totalPercentage / listCategory.length) !== 100) {
            message.warning("Failed save data, please check item percentage")
            return
        }
        if (totalCategoryValue !== 100) {
            message.warning("Failed save data, please check total percentage")
            return
        }

        let payload = {
            categories: Object.assign(totalCategories, itemCategories),
            list_item: Object.assign(totalItems, items),
            employee_code: employeeCode
        }

        setSaving(true)
        SentralModel.action('Appraisal', 'saveAppraisalComponentNew', payload, 0).then((res) => {
            setLoaded(false)
            fetchEmployee(employeeCode)
            message.success('Appraisal Component saved', 2);
            setSaving(false)
        }).catch((err) => {
            message.warning('Failed save appraisal component')
            setSaving(false)
        })
    }

    useEffect(() => {
        fetchEmployee(employeeCode)
        fetchKpiItems(employeeCode, setItemForm)
        fetchDivision()
        fetchPosition()

    }, [employeeCode, setItemForm]);

    // const checkAll = (e, v) => {
    //     let selected = kpiItems.filter((el) =>
    //         el.kpi_item_category_id === v
    //     )
    //     let x = currentSelected
    //     if (e.target.checked) {
    //         selected[0].items.forEach(el => {
    //             setItemForm.setFieldsValue({
    //                 ['kpi_item_' + el.kpi_item_id]: true
    //             })
    //             x['kpi_item_' + el.kpi_item_id] = true
    //         })
    //     } else {
    //         selected[0].items.forEach(el => {
    //             setItemForm.setFieldsValue({
    //                 ['kpi_item_' + el.kpi_item_id]: false
    //             })
    //             x['kpi_item_' + el.kpi_item_id] = false
    //         })
    //     }
    //     setCurrentSelected(x)
    //     forceUpdate()

    // }

    // const countTotalPercentage = () => {
    //     let totalAll = 0;
    //     kpiItems.forEach(ctg => {
    //         let totalByCtg = 0;
    //         ctg.items.forEach(item => {
    //             let v = setItemForm.getFieldsValue()['kpi_item_value_' + item.kpi_item_id]
    //             totalByCtg += (v) ? parseInt(v) : 0
    //         })
    //         setItemForm.setFieldsValue({
    //             ['kpi_category_value_' + ctg.kpi_item_category_id]: totalByCtg
    //         })
    //         totalAll += totalByCtg
    //     })
    //     setTotalPercentage(totalAll)

    // }

    const totalItem = (v) => {
        let total = 0

        v.items.forEach((el) => {
            if (el.selected === true) {
                total += el.rate
            }
        })

        return total;
    }

    const checkItem = (v, id, ctg) => {
        let x = currentSelected
        let y = totalItems
        if (v.target.checked) {
            x['kpi_item_' + id] = true
            y['kpi_item_id_' + id] = 0
        } else {
            x['kpi_item_' + id] = false
            setItemForm.setFieldsValue({
                ['kpi_item_value_' + id]: 0
            })

            let category = kpiItems.find(el => el.kpi_item_category_id === ctg)
            let item = category.items.find(el => el.kpi_item_id === id)
            item.selected = false
            item.rate = 0

            delete totalItems['kpi_item_id_' + id];
        }

        setCurrentSelected(x)
        forceUpdate()
    }

    const changeCategoryValue = (e, ctgId) => {
        let totalCtg = 0;
        let v = itemCategories
        v['kpi_item_category_id_' + ctgId] = parseInt(e.target.value)

        setItemCategories(v)

        const ctg = totalCategory.find((el) => el.category_id === ctgId)
        ctg.total = parseInt(e.target.value)

        totalCategory.forEach((el) => {
            totalCtg += parseInt(el.total)
        })

        setTotalCategoryValue(totalCtg)
    }

    const changeItemValue = (e, id, ctgId) => {
        let arr = listCategory

        if (!arr.find(e => e === ctgId)) {
            arr.push(ctgId)
        }

        setListCategory(arr)

        let arrItem = items
        arrItem[`kpi_item_id_${id}`] = parseInt(e.target.value)

        const newArrItem = Object.assign(totalItems, arrItem)
        setItems(newArrItem)

        let category = kpiItems.find(el => el.kpi_item_category_id === ctgId)
        let item = category.items.find(el => el.kpi_item_id === id)
        item.rate = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)

        let totalByCtg = category.items.reduce((total, item) => total + item.rate, 0)

        let ctg = totalItemByCategory.find(el => el.category_id === ctgId)
        ctg.total = totalByCtg

        let totalAll = totalItemByCategory.reduce((total, cat) => total + cat.total, 0)

        setTotalPercentage(totalAll)
    }

    // const changeItemValue = (e, id, ctgId) => {
    //     // let x = (e.target.value) ? e.target.value : 0
    //     // countTotalPercentage()

    //     let category = kpiItems.find(el => el.kpi_item_category_id === ctgId)
    //     let item = category.items.find(el => el.kpi_item_id === id)
    //     item.rate = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)

    //     let totalByCtg = category.items.reduce((total, item) => total + item.rate, 0)

    //     let ctg = totalCategory.find(el => el.category_id === ctgId)
    //     ctg.total = totalByCtg

    //     setItemForm.setFieldsValue({
    //         ['kpi_category_value_' + ctg.category_id]: totalByCtg
    //     })

    //     let totalAll = totalCategory.reduce((total, cat) => total + cat.total, 0)
    //     setTotalPercentage(totalAll)
    // }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Point penilaian" breadcrumbs={[['Appraisal', '/management/appraisal'], ['Setup point penilaian']]}></PageTitle>
                <Card title={
                    <Row justify='space-between'>
                        <Col>
                            <Text strong style={{ float: 'left' }}>Setup Point Penilaian</Text>
                        </Col>
                        <Col>
                            {/* <Button type='primary' onClick={resetValue}>Reset Value</Button> */}
                        </Col>
                    </Row>}>

                    {
                        (loaded && employee) ?
                            <div>
                                <Descriptions className="mb-3" layout="horizontal" column={1} bordered size="small" style={{ width: "500px" }}>
                                    <Descriptions.Item label="Name">{employee.name}</Descriptions.Item>
                                    <Descriptions.Item label="Division">
                                        <Select placeholder="Select Divsiion" defaultValue={employee.division_id} onChange={handleChangeDivision} style={{ width: "100%" }}>
                                            {
                                                division.map((el, key) => (
                                                    <Option value={el.division_id} key={key}>
                                                        {el.division_name}
                                                    </Option>
                                                ))
                                            }
                                        </Select>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Position">
                                        <Select
                                            showSearch
                                            placeholder="Select Position"
                                            options={position}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            defaultValue={employee.position_id}
                                            onChange={handleChangePosition}
                                            style={{ width: "100%" }}
                                        />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Join Date">{toFullDate(employee.join_date)}</Descriptions.Item>
                                </Descriptions >
                                <Form form={setItemForm} onFinish={saveKpiItem} size="small">
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th className="text-center" style={{ width: '30px' }}>No</th>
                                                <th className="text-left" colSpan="2">Component</th>
                                                <th>Rate (%)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                kpiItems.map((el, i) => (
                                                    (el.items.length > 0) ?
                                                        <React.Fragment key={'category_' + i}>
                                                            <tr style={{ backgroundColor: '#dedcdc' }}>
                                                                <th className="text-left" colSpan={3}>
                                                                    {/* <Checkbox onChange={(e) => checkAll(e, el.kpi_item_category_id)} />  */}
                                                                    {el.name}
                                                                </th>
                                                                <th style={{ width: '100px' }}>
                                                                    <Form.Item name={'kpi_category_value_' + el.kpi_item_category_id} style={{ margin: 0, padding: 0 }}>
                                                                        <Input
                                                                            disabled
                                                                            style={{ fontWeight: 'bold', textAlign: 'center' }}
                                                                            type="number"
                                                                            onBlur={(e) => {
                                                                                if (e.target.value === '') {
                                                                                    setItemForm.setFieldsValue({ ['kpi_category_value_' + el.kpi_item_category_id]: 0 })
                                                                                }
                                                                            }}
                                                                            onChange={(v) => changeCategoryValue(v, el.kpi_item_category_id)}
                                                                        />
                                                                    </Form.Item>
                                                                </th>
                                                            </tr>
                                                            <tr style={{ backgroundColor: '#f6f6f6' }}>
                                                                <th className="text-left" colSpan={3}>Total Item</th>
                                                                <th>
                                                                    <Input
                                                                        disabled
                                                                        style={{ fontWeight: 'bold', textAlign: 'center' }}
                                                                        type="number"
                                                                        value={totalItem(el)} />
                                                                </th>
                                                            </tr>
                                                            {
                                                                el.items.map((item, j) => (
                                                                    <tr key={'item_' + item.kpi_item_id}>
                                                                        <td style={{ verticalAlign: "top", }} className="text-center">{j + 1}</td>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            <Form.Item name={'kpi_item_' + item.kpi_item_id} style={{ margin: 0, padding: 0 }} valuePropName="checked">
                                                                                <Checkbox disabled onChange={(e) => checkItem(e, item.kpi_item_id, el.kpi_item_category_id)} />
                                                                            </Form.Item>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>{item.description}</td>
                                                                        <td>
                                                                            <Form.Item name={'kpi_item_value_' + item.kpi_item_id} style={{ margin: 0, padding: 0 }}>
                                                                                <Input
                                                                                    style={{ textAlign: 'center' }}
                                                                                    type='number'
                                                                                    disabled
                                                                                    // disabled={!currentSelected['kpi_item_' + item.kpi_item_id]}
                                                                                    min={0}
                                                                                    onChange={(v) => changeItemValue(v, item.kpi_item_id, el.kpi_item_category_id)}
                                                                                    onBlur={(e) => {
                                                                                        if (e.target.value === '') {
                                                                                            setItemForm.setFieldsValue({ ['kpi_item_value_' + item.kpi_item_id]: 0 })
                                                                                        }
                                                                                    }} />
                                                                            </Form.Item>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                        : null
                                                ))
                                            }
                                            <tr>
                                                <th colSpan={3} className="text-right">Total</th>
                                                <th>{totalCategoryValue} %</th>
                                            </tr>
                                        </tbody>

                                    </table>
                                    <div className="full-width text-right mt-3">
                                        <SubmitButton loading={saving} />
                                    </div>
                                </Form>
                            </div >
                            :
                            <Spinner />
                    }
                </Card >
            </Card >
        </div >
    );
}

export default SetItem;

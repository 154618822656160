import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Input, Typography, Button, DatePicker } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'
import { SubmitButton, AddButton, BasicModal, SelectButton, DataTable } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import authData from '../../../helpers/authData'
import { toIdr, extractNumber } from '../../../utils/currencyHandler';
import moment from 'moment'

const { TextArea, Search } = Input
const { Text } = Typography

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const RequestForm = (props) => {
    const forceUpdate = useForceUpdate()
    const [prForm] = Form.useForm()
    const [showBudgetModal, setShowBudgetModal] = useState(false)
    const [time, setTime] = useState(0)
    const [searching, setSearching] = useState(false)
    const [budgetList, setBudgetList] = useState([])
    const [prItem, setPrItem] = useState([{ item_name: '', remarks: '', price: null, quantity: null, total: null }])
    const [prTotal, setPrTotal] = useState(0)

    const fetchBudget = () => {
        SentralModel.action('Search', 'budgetDivision', { description: '', division_id: authData.division_id === 25 ? 24 : authData.division_id }, 0).then((res) => {
            console.log(res.data)
            setBudgetList(res.data)
        })
    }

    useEffect(() => {
        fetchBudget()
    }, []);

    const selectBudget = (v) => {
        setShowBudgetModal(false)
        prForm.setFieldsValue({
            budget_description: v.description,
            budget_coa_code: v.coa_code,
            budget_id: v.budget_planning_id,
            budget_used: toIdr(v.used),
            budget_remaining: toIdr(v.amount - v.used)
        })
    }

    const coaColumns = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
    },
    {
        title: 'Budget',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'COA Code',
        dataIndex: 'coa_code',
        key: 'coa_code',
    },
    {
        title: 'Budget',
        dataIndex: 'amount',
        render: (text) => toIdr(text)
    }, {
        title: 'Target Realization',
        dataIndex: 'realization_month',
        render: (text) => moment(text).format('MMMM')
    }, {
        title: 'Action',
        align: 'center',
        render: (row) => (
            <SelectButton size="small" title="Select" onSelect={() => selectBudget(row)} />
        )
    }
    ];

    const coaData = [
        ...budgetList.map((el, key) => {
            return {
                key: key,
                budget_planning_id: el.detail.budget_planning_id,
                description: el.detail.description,
                coa_code: el.coa_code,
                realization_month: el.detail.realization_month,
                amount: el.budget.amount,
                used: el.budget.used,
            }
        })
    ];

    const addPrItem = () => {
        let x = prItem;
        let key = prItem.length
        x.push({ item_name: '', remarks: '', price: null, quantity: null, total: null })
        prForm.setFieldsValue({
            ['item_name_' + key]: null,
            ['item_description_' + key]: null,
            ['item_price_' + key]: null,
            ['item_qty_' + key]: null,
            ['item_total_' + key]: null
        })
        setPrItem(x)
        forceUpdate()
    }

    const removeItem = (key) => {
        // console.log(key)
        let x = prItem
        x.splice(key, 1)
        setPrItem(x)
        changePrTotal()
        forceUpdate()
    }

    const searchBudget = (v) => {
        if (time) clearTimeout(time)
        if (v) {
            setTime(setTimeout(() => {
                setSearching(true)
                SentralModel.action('Search', 'budgetDivision', { description: v, division_id: authData.division_id }, 0).then((res) => {
                    setBudgetList(res.data)
                    setSearching(false)
                })
            }, 500))
        } else {
            setSearching(false)
        }
    }

    const changeItemPrice = (v, key) => {
        let x = prItem;
        if (v.target.value) {
            const newPrice = extractNumber(v.target.value)
            const qty = x[key].quantity
            x[key].price = newPrice
            prForm.setFieldsValue({ ['item_price_' + key]: toIdr(newPrice) })
            if (qty) {
                x[key].total = newPrice * qty
                prForm.setFieldsValue({ ['item_total_' + key]: toIdr(newPrice * qty) })
            }
            setPrItem(x)
            changePrTotal()
        } else {
            x[key].total = 0
            prForm.setFieldsValue({ ['item_total_' + key]: 0 })
            setPrItem(x)
            changePrTotal()
        }
    }

    const changeItemQty = (v, key) => {
        let x = prItem;
        if (v.target.value) {
            const newQty = parseInt(v.target.value)
            const price = x[key].price
            x[key].quantity = newQty
            if (price) {
                x[key].total = price * newQty
                prForm.setFieldsValue({ ['item_total_' + key]: toIdr(price * newQty) })
            }
            setPrItem(x)
            changePrTotal()
        } else {
            x[key].total = 0
            prForm.setFieldsValue({ ['item_total_' + key]: 0 })
            setPrItem(x)
            changePrTotal()
        }
    }

    const changePrTotal = () => {
        let t = 0
        prItem.forEach(el => {
            t += el.total
        })
        setPrTotal(t)
    }

    const submitPr = (v) => {
        let data = {
            budget_planning_id: v.budget_id,
            coa_code: v.budget_coa_code,
            division_id: authData.division_id,
            due_date: moment(v.budget_due_date).format('YYYY-MM-DD'),
            total_amount: prTotal,
            items: prItem
        }
        
        props.onSubmit(data)
    }

    return (
        <div>
            <Form size="middle" form={prForm} layout="vertical" onFinish={submitPr}>
                <Row justify="space-between">
                    <Col span={6}>
                        <Card bordered title="Budget">
                            <Row>
                                <Col span={24} style={{ padding: 3 }}>
                                    <Form.Item name="budget_description" label="Budget Description" rules={[{ required: true, message: 'Budget is required' }]}>
                                        <Input placeholder="click to select" readOnly onClick={() => setShowBudgetModal(true)} />
                                    </Form.Item>
                                    <Form.Item name="budget_coa_code" hidden>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="budget_id" hidden>
                                        <Input readOnly />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{ padding: 3 }}>
                                    <Form.Item name="budget_used" label="Used">
                                        <Input readOnly />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{ padding: 3 }}>
                                    <Form.Item name="budget_remaining" label="Remaining">
                                        <Input readOnly />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{ padding: 3 }} rules={[{ required: true, message: 'Due Date is required' }]}>
                                    <Form.Item name="budget_due_date" label="Due Date">
                                        <DatePicker style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col span={17}>
                        <Card title={
                            <div className="full-width">
                                <Text strong style={{ float: 'left' }}>Purchase Request Item</Text>
                                <AddButton right onAdd={() => addPrItem()} title="Add Item" />
                            </div>}>
                            <table className="table-item" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: '35%' }}>Item</th>
                                        <th style={{ width: '20%' }}>Price</th>
                                        <th style={{ width: '10%' }}>Qty</th>
                                        <th style={{ width: '25%' }}>Total</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        prItem.map((el, key) => (
                                            <tr key={'rmb_item' + key}>
                                                <td>
                                                    <Form.Item name={'item_name_' + key} rules={[{ required: true, message: 'Item Name is required' }]}>
                                                        <Input placeholder="Item Name" value={prItem[key].item_name} onChange={(v) => {
                                                            let x = prItem;
                                                            x[key].item_name = v.target.value
                                                            setPrItem(x)
                                                        }} />
                                                    </Form.Item>
                                                    <Form.Item name={'item_description_' + key} rules={[{ required: true, message: 'Item Description is required' }]}>
                                                        <TextArea placeholder="Remarks" value={prItem[key].remarks} onChange={(v) => {
                                                            let x = prItem;
                                                            x[key].remarks = v.target.value
                                                            setPrItem(x)
                                                        }} />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name={'item_price_' + key} rules={[{ required: true, message: 'Item Price is required' }]}>
                                                        <Input placeholder="Price" value={prItem[key].value} onChange={(v) => changeItemPrice(v, key)} />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name={'item_qty_' + key} rules={[{ required: true, message: 'Item QTY is required' }]}>
                                                        <Input placeholder="Qty" type="number" onChange={(v) => {
                                                            let x = prItem;
                                                            x[key].quantity = v.target.value
                                                            setPrItem(x)
                                                            changeItemQty(v, key)
                                                        }} />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name={'item_total_' + key} rules={[{ required: true, message: 'Item Total is required' }]}>
                                                        <Input placeholder="Total" readOnly />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    {
                                                        ((key + 1) === prItem.length && prItem.length > 1) ?
                                                            <Button onClick={() => removeItem(key)} danger icon={<DeleteOutlined />} />
                                                            : null
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    <tr>
                                        <td className="text-right" colSpan={3} style={{ borderTop: '1px solid #ededed' }}>
                                            Total :
                                        </td>
                                        <td className="text-right" style={{ borderTop: '1px solid #ededed' }}>
                                            {toIdr(prTotal)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Card>
                        <br />
                        <Row>
                            <Col span={24} className="text-right">
                                <SubmitButton loading={props.saving} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <BasicModal width={1000} onCloseModal={() => setShowBudgetModal(false)} showModal={showBudgetModal} onCancel={() => setShowBudgetModal(false)} title="Select Budget">
                <div className="full-width">
                    <Search
                        className="mb-2"
                        placeholder="Search Budget"
                        onChange={(val) => searchBudget(val.target.value)}
                        enterButton
                        style={{ width: 300, float: 'right' }}
                        loading={searching}
                    />
                </div>
                <DataTable bordered dataSource={coaData} columns={coaColumns} search={false} size="small" />
            </BasicModal>
        </div>
    );
}

export default RequestForm;

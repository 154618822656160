import React, { useState } from 'react';
import { Card, message } from 'antd';
import { PageTitle } from '../../../../components'
import RequestForm from './RequestForm'
import { useHistory } from 'react-router-dom'
import { SentralModel } from '../../../../models/SentralModel'

const Create = () => {
    const history = useHistory()
    const [saving, setSaving] = useState(false)

    const submitReimbursement = (v) => {
        setSaving(true)

        SentralModel.action('Submission', 'saveReimbursement', v, 0).then(() => {
            message.destroy()
            message.success('Reimbursement submitted', 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/reimbursement');
            }, 1000);
        }).catch(() => {
            message.destroy()
            message.error('Terjadi kesalahan, silahkan coba lagi', 2)
            setSaving(false)
        })
    }
    
    return (
        <Card className="content-container">
            <PageTitle title="Reimbursement Submission" breadcrumbs={[['Reimbursement', '/reimbursement'], ['Create']]}></PageTitle>
            <RequestForm onSubmit={(v) => submitReimbursement(v)} saving={saving} />
        </Card>
    );
}

export default Create;

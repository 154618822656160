import React from 'react';
import LeaveRequest from './request/Index'

const Index = (props) => {
    return (
        <div>
            <LeaveRequest data={props.data.requests} requestNotif={props.requestNotif} cancellationNotif={props.cancellationNotif} />
        </div>
    );
}

export default Index;

import { useEffect, useState } from 'react'
import { Col, Form, Input, Row, Select, Space, Typography, message } from 'antd'
import { AddButton, DataTable, DeletationButton, DeleteButton, EditButton, FormModal } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { extractNumber, toIdr } from '../../../utils/currencyHandler'

const { Title } = Typography

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);

    return () => setValue(
        value => ++value
    );
}

const BasicSalary = () => {
    const forceUpdate = useForceUpdate()
    const [form] = Form.useForm()
    const [salaryData, setSalaryData] = useState([])
    const [employees, setEmployees] = useState([])
    const [divisions, setDivisions] = useState([])
    const [empStatus, setEmpStatus] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showModalAll, setShowModalAll] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [selectedDivision, setSelectedDivision] = useState(0)
    const [selectedEmpStatus, setSelectedEmpStatus] = useState(null)
    const [modalTitle, setModalTitle] = useState('')

    const fetchSalaryData = () => {
        SentralModel.action("EmployeeBasicSalary", "getSalary", null, null).then((res) => {
            setSalaryData(res.data)
        })
    }

    const fetchEmployees = () => {
        SentralModel.list('Employee', { filter: [['employee_status', ['ESPR', 'ESPB', 'ESKN']], ['is_active', '=', '1']], orderBy: 'name', order: 'asc' }).then(res => {
            const arr = []
            res.data.forEach((el) => {
                arr.push({
                    value: el.employee_code,
                    label: el.name,
                    employee_status: el.employee_status
                })
            })
            setEmployees(arr)
        })
    }

    const fetchEmployeeStatus = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'ES'], ['detail_code', ['ESMG', 'ESPR', 'ESKN', 'ESPB']]], orderBy: 'order_key', order: 'asc' })
            .then(res => {
                let arr = []
                res.data.forEach((el) => {
                    arr.push({
                        value: el.detail_code,
                        label: el.description
                    })
                })

                setEmpStatus(arr)
            })
    }

    const fetchDivision = () => {
        SentralModel.list('Division', { filter: [['is_active', '=', '1']], orderBy: 'division_name', order: 'asc' }).then(res => {
            const arr = []
            res.data.forEach((el) => {
                arr.push({
                    value: el.division_id,
                    label: el.division_name
                })
            })
            setDivisions(arr)
        })
    }

    useEffect(() => {
        fetchSalaryData()
        fetchEmployees()
        fetchDivision()
        fetchEmployeeStatus()

    }, [])

    const addComponent = () => {
        setShowModal(true)
        setModalTitle('Add Salary')
        form.setFieldsValue({
            employee_basic_salary_id: 0,
            employee_code: null,
            basic_salary: null
        })
    }

    const editComponent = (v) => {
        setShowModal(true)
        setSelectedEmpStatus(v.employee.employee_status)
        setModalTitle('Edit Salary')
        form.setFieldsValue({
            employee_basic_salary_id: v.employee_basic_salary_id,
            employee_status: v.employee.employee_status,
            employee_code: v.employee_code,
            basic_salary: toIdr(v.basic_salary),
        })
    }

    const allComponent = () => {
        setShowModalAll(true)
        form.resetFields()
    }

    const generateAll = (v) => {
        setLoaded(true)

        let payload = {
            basic_salary: extractNumber(v.basic_salary)
        }

        SentralModel.action("EmployeeBasicSalary", "generateAll", payload, null).then((res) => {
            message.success("Success Generate All Data")
            fetchSalaryData()
            setShowModalAll(false)
            setLoaded(false)
        }).catch((err) => {
            message.warning("Failed Generate Data")
            setLoaded(false)
        })
    }

    const saveSalary = (v) => {
        setLoaded(true)

        let payload = {
            employee_code: v.employee_code,
            basic_salary: extractNumber(v.basic_salary),
        }

        SentralModel.action("EmployeeBasicSalary", "saveSalary", payload, v.employee_basic_salary_id === 0 ? 0 : v.employee_basic_salary_id).then((res) => {
            if (v.employee_basic_salary_id === 0) {
                message.success("Success Add Salary")
            } else {
                message.success("Success Edit Salary")
            }
            fetchSalaryData()
            setShowModal(false)
            setLoaded(false)
            form.resetFields()
        }).catch((err) => {
            if (v.employee_basic_salary_id === 0) {
                message.warning("Failed Add Salary")
            } else {
                message.warning("Failed Edit Salary")
            }
        })
    }

    const deleteSalary = (v) => {
        SentralModel.action("EmployeeBasicSalary", "deleteSalary", null, v).then((res) => {
            message.success("Success Delete Data")
            fetchSalaryData()
        }).catch((err) => {
            message.warning("Failed Delete Data")
        })
    }

    const deleteAllSalary = (v) => {
        SentralModel.action("EmployeeBasicSalary", "deleteAllSalary", null, v).then((res) => {
            message.success("Success Delete Data")
            fetchSalaryData()
        }).catch((err) => {
            message.warning("Failed Delete Data")
        })
    }

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
        align: 'center',
    },
    {
        title: 'Employee',
        key: 'employee',
        render: (row) => (
            row?.employee?.name ?? "-"
        )
    },
    {
        title: 'Grade',
        key: 'grade',
        width: '100px',
        align: 'center',
        render: (row) => (
            row?.employee?.employee_grade ?? "-"
        )
    },
    {
        title: 'Basic Salary',
        key: 'basic_salary',
        render: (row) => (
            toIdr(row?.basic_salary ?? 0)
        )
    },
    {
        title: 'Action',
        align: 'center',
        render: (row) => (
            <Space>
                <EditButton onEdit={() => editComponent(row)} />
                <DeleteButton onConfirm={() => deleteSalary(row.employee_basic_salary_id)} />
            </Space>
        )
    },
    ];

    const changeBasicSalary = (v) => {
        let number = extractNumber(v.target.value)
        form.setFieldsValue({ basic_salary: toIdr(number) })
    }

    return (
        <div>
            <Title level={4}>Basic Salary</Title>
            <Row justify='space-between' style={{ marginBottom: 10 }}>
                <Col span={5}>
                    <Select
                        allowClear
                        showSearch
                        options={divisions}
                        placeholder="Select Division Filter"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: '100%' }}
                        onChange={(v) => setSelectedDivision(v)}
                        onClear={() => {
                            setSelectedDivision(0)
                            forceUpdate()
                        }}
                    />
                </Col>
                <Col>
                    <Space>
                        <AddButton onAdd={addComponent} disabled={loaded} title="Add Basic Salary" />
                        <AddButton onAdd={allComponent} disabled={loaded} title="Generate All Basic Salary" />
                        <DeletationButton onConfirm={deleteAllSalary} disabled={loaded} title="Delete All Basic Salary" />
                    </Space>
                </Col>
            </Row>

            {
                salaryData &&
                <DataTable
                    dataSource={
                        selectedDivision === 0 ? salaryData : salaryData.filter((el) => el.employee.division_id === selectedDivision)
                    }
                    columns={columns}
                    bordered
                />
            }

            {
                showModal ?
                    <FormModal submitForm={saveSalary} form={form} title={modalTitle} showModal={showModal} onCloseModal={() => setShowModal(false)}>
                        <Form.Item name="employee_basic_salary_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="employee_status" label="Employee Status">
                            <Select
                                disabled={modalTitle === 'Edit Salary' ? true : false}
                                showSearch
                                options={empStatus}
                                placeholder="Select Employee Status"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(v) => setSelectedEmpStatus(v)}
                            />
                        </Form.Item>
                        <Form.Item name="employee_code" label="Employee">
                            <Select
                                disabled={modalTitle === 'Edit Salary' ? true : false}
                                showSearch
                                options={
                                    selectedEmpStatus === null ?
                                        [] :
                                        employees.filter((el) => el.employee_status === selectedEmpStatus)
                                }
                                placeholder="Select Employee"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                        <Form.Item name="basic_salary" label="Basic Salary" onChange={changeBasicSalary} >
                            <Input />
                        </Form.Item>
                    </FormModal> : null
            }

            {
                showModalAll ?
                    <FormModal submitForm={generateAll} showModal={showModalAll} onCloseModal={() => setShowModalAll(false)} title="Generate All Basic Salary" loading={loaded}>
                        <Form.Item name="basic_salary" label="Basic Salary" onChange={changeBasicSalary} >
                            <Input />
                        </Form.Item>
                    </FormModal> : null
            }
        </div>
    )
}

export default BasicSalary

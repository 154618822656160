import { useEffect, useState } from 'react';
import { Row, Col, Form, Input, InputNumber, Button, Space, Card, message } from 'antd';
import { SubmitButton } from '../../../components'
import currentUser from '../../../helpers/authData';
import { SentralModel } from '../../../models/SentralModel';
import { extractNumber, toIdr } from '../../../utils/currencyHandler';
import moment from 'moment';

const RequestForm = (props) => {
    const { loanData = null } = props
    const [loanForm] = Form.useForm()
    const [setupData, setSetupData] = useState({})
    const [simulationData, setSimulationData] = useState([])
    const [amount, setTotalAmount] = useState(0)
    const [period, setPeriod] = useState(0)
    const [totalPayment, setTotalPayment] = useState(0)
    const [showSimulation, setShowSimulaton] = useState(false)

    const fetchSetupPeriod = () => {
        SentralModel.action("Loan", "getSetupLoanByStatus", { employee_status: currentUser.employee_status }, null)
            .then((res) => {
                setSetupData(res.data)
            })
    }

    const setDefaultFormValue = (loanData, loanForm) => {
        loanForm.setFieldsValue({
            remarks: loanData.remarks,
            total_amount: loanData.total_amount,
            loan_period: loanData.loan_period
        })
    }

    useEffect(() => {
        if (loanData) {
            setDefaultFormValue(loanData, loanForm)
        }

        fetchSetupPeriod()
    }, [loanData, loanForm]);

    const generateSimulation = () => {
        let arr = []
        const payment = extractNumber(toIdr(amount)) / period
        const ratePayment = (extractNumber(toIdr(amount)) * (setupData.loan_rate / 100)) / period

        for (let i = 1; i <= period; i++) {
            arr.push({
                month_period: parseInt(moment().format("MM")) + i,
                totalPayment: payment + ratePayment
            })
        }

        setTotalPayment((payment + ratePayment) * period)
        setSimulationData(arr)
        return arr
    }

    const calculateSimulation = () => {
        generateSimulation()
        setShowSimulaton(true)
    }

    const onSave = (v) => {
        if (amount > setupData.total_loan_limit) {
            message.warning("Loan Exceeds The Limit")
            return
        }

        let payload = {
            remarks: v.remarks,
            total_amount: extractNumber(v.total_amount),
            loan_period: v.loan_period,
            payment: generateSimulation()
        }

        props.onSubmit(payload)
    }

    const changeTotalLoan = (v) => {
        let number = extractNumber(v.target.value)
        loanForm.setFieldsValue({ total_amount: toIdr(number) })
    }

    return (
        <>
            <div className='mb-5'>
                <Form form={loanForm} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={(v) => onSave(v)}>
                    <Row justify="center">
                        <Col span={10}>
                            <Form.Item name="remarks" label="Remark" rules={[{ required: true }]}>
                                <Input.TextArea rows={3} placeholder='Remark...' />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item name="loan_period" label="Loan Period" rules={[{ required: true }]}>
                                <InputNumber min={1} max={12} onChange={(v) => setPeriod(v)} placeholder='1' style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='center'>
                        <Col span={10}>
                            <Form.Item name="total_amount" label="Total Amount" onChange={changeTotalLoan} rules={[{ required: true }]}>
                                <Input min={0} max={setupData.total_loan_limit} onChange={(v) => setTotalAmount(extractNumber(v.target.value))} placeholder='1000000' style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item label="Rate" rules={[{ required: true }]}>
                                <InputNumber disabled value={`${setupData.loan_rate}%`} placeholder='100%' style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='end' style={{ marginRight: '110px' }}>
                        <Space>
                            <Col>
                                <Button onClick={calculateSimulation}>Simulation</Button>
                            </Col>
                            <Col>
                                <SubmitButton loading={props.saving} />
                            </Col>
                        </Space>
                    </Row>
                </Form>
            </div >
            {
                showSimulation && simulationData.length > 0 ?
                    <Card title="Simulation">
                        <Row justify='center'>
                            <table className='table-custom'>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center", fontSize: 14 }}>Period</th>
                                        <th style={{ textAlign: "center", fontSize: 14 }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        simulationData.map((el, key) => (
                                            <tr key={key}>
                                                <td style={{ textAlign: "left" }}>{`Payment Periode ${moment(`${el.month_period > 12 ? moment().year() + 1 : moment().year()}-${el.month_period > 12 ? el.month_period - 12 : el.month_period}-01`).format("MMMM YYYY")}`}</td>
                                                <td style={{ textAlign: "center" }}>{toIdr(el.totalPayment)}</td>
                                            </tr>
                                        ))
                                    }
                                    <tr>
                                        <th>Total Payment</th>
                                        <th>{toIdr(totalPayment)}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </Row>
                    </Card>
                    : null
            }
        </>
    );
}

export default RequestForm;

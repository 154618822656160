import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography } from 'antd'
import { PageTitle, Spinner } from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import { useParams } from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import { toIdr } from '../../../utils/currencyHandler'
import { toFullDate } from '../../../utils/dateHandler'

const { Text } = Typography

const Detail = () => {
    const { requestId } = useParams()
    const [businessTrip, setBusinessTrip] = useState(null)

    const fetchBusinessTrip = (id) => {
        SentralModel.get('SubmissionBusinessTrip', {}, id).then((res) => {
            setBusinessTrip(res.data)
        })
    }

    useEffect(() => {
        fetchBusinessTrip(requestId)
    }, [requestId]);

    const statusUpdated = () => {
        setBusinessTrip(null)
        fetchBusinessTrip(requestId)
        window.location.reload();
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Business Trip Request" breadcrumbs={[['Submission List', '/submission-list'], ['Detail']]}></PageTitle>
            </div>
            {
                (businessTrip) ?
                    <div>
                        <DetailHeader
                            name={businessTrip?.employee.name}
                            date={businessTrip?.created_at}
                            status={businessTrip?.status} />
                        <Card bordered className="mb-4">
                            <Text strong>BUSINESS TRIP DETAIL</Text>
                            <Row className="mb-4 mt-1">
                                <Col span={12}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <th>Employee Name</th><td>{businessTrip?.employee.name}</td>
                                            </tr>
                                            <tr>
                                                <th>Division</th><td>{businessTrip?.employee.division.division_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Position</th><td>{businessTrip?.employee.position.position_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Date</th><td>{toFullDate(businessTrip?.from_date)} - {toFullDate(businessTrip?.to_date)}</td>
                                            </tr>
                                            <tr>
                                                <th>Purpose</th><td>{businessTrip?.purpose}</td>
                                            </tr>
                                            <tr>
                                                <th>Destination</th><td>{(businessTrip?.trip_type.detail_code === "TTD") ? (businessTrip?.city ? businessTrip?.city.city_name : '') : businessTrip?.country.country_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Total Amount</th><td>{toIdr(businessTrip?.total_amount)}</td>
                                            </tr>
                                            <tr>
                                                <th>Trip Planning</th><td>{businessTrip?.trip_plan}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered className="mb-4">
                            <Text strong>COST PLANNING</Text>
                            <Row className="mb-4 mt-1">
                                <Col span={24}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>Item Name</th>
                                                <th>Amount</th>
                                                <th>Quantity</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                businessTrip?.costs?.map((el, key) => (
                                                    <tr key={key}>
                                                        <td>{el.description}</td>
                                                        <td>{toIdr(el.amount)}</td>
                                                        <td>{el.quantity}</td>
                                                        <td>{toIdr(el.total)}</td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered>
                            <ApprovalLog category="BUSINESSTRIP"
                                requestId={businessTrip?.sub_business_trip_id}
                                approvers={businessTrip?.approver}
                                onSubmitted={() => statusUpdated()} />
                        </Card>
                    </div> : <Spinner />
            }

        </Card>
    );
}

export default Detail;

import React from 'react'
import {
    ReconciliationOutlined, FieldTimeOutlined, DollarCircleOutlined, WalletOutlined, FormOutlined, FileProtectOutlined, FileDoneOutlined, ProfileOutlined, SettingOutlined, BankOutlined, ClusterOutlined, ApartmentOutlined, ScissorOutlined, CalendarOutlined, BarsOutlined, LockOutlined, EnvironmentOutlined, UserOutlined, CarOutlined, LoginOutlined, SolutionOutlined, BranchesOutlined, EditOutlined, SoundOutlined, SnippetsOutlined, InfoCircleOutlined, FileTextOutlined, SyncOutlined, TeamOutlined, ScheduleOutlined, AppstoreOutlined, BarcodeOutlined, UserSwitchOutlined
} from '@ant-design/icons';

export const documentationMenus = [
    {
        name: 'SOP',
        to: '/documentation/sop',
        icon: <FileProtectOutlined />,
        role: ['USER'],
        key: 'documentation-sop'
    }, {
        name: 'User Manual',
        to: '/',
        icon: <ProfileOutlined />,
        role: ['USER'],
        component: React.forwardRef(() => (
            <a href={process.env.REACT_APP_API_FILE + '/user_manual/f7042c31-c103-447c-8970-61d25d9e2f50.pdf'} target='_blank' rel='noopener noreferrer'>
                <ProfileOutlined /> User Manual
            </a>
        )),
        key: 'documentation-user-manual'
    },
]

export const submissionMenus = [
    {
        name: 'Leave',
        to: '/leave',
        icon: <ReconciliationOutlined />,
        role: ['USER'],
        key: 'submission-menu-leave'
    }, {
        name: 'Overtime ',
        to: '/overtime',
        icon: <FieldTimeOutlined />,
        role: ['USER'],
        key: 'submission-menu-overtime'
    }, {
        name: 'Fund',
        icon: <DollarCircleOutlined />,
        role: ['USER'],
        key: 'submission-menu-fund',
        items: [{
            name: 'Reimbursement',
            to: '/reimbursement',
            role: ['USER'],
            key: 'submission-menu-reimbursement'
        }, {
            name: 'Medical',
            to: '/medical',
            role: ['USER'],
            key: 'submission-menu-medical'
        }]
    }, {
        name: 'Loan',
        icon: <DollarCircleOutlined />,
        role: ['USER'],
        key: 'submission-menu-loan',
        items: [
            {
                name: 'Create Loan',
                to: '/loan',
                role: ['USER'],
                key: 'submission-menu-create-loan'
            },
            {
                name: 'Setup Loan',
                to: '/setup-loan',
                role: ['SUPERADMIN', 'ADMIN', 'HR'],
                key: 'submission-menu-setup-loan'
            },
        ]
    }, {
        name: 'Business Trip',
        to: '/business-trip',
        icon: <CarOutlined />,
        role: ['USER'],
        key: 'submission-menu-business-trip'
    }, {
        name: 'Office Stationery',
        to: '/office-stationery',
        icon: <ScissorOutlined />,
        role: ['USER'],
        key: 'submission-menu-office-stationery'
    }, {
        name: 'Purchase Request',
        to: '/purchase-request',
        icon: <WalletOutlined />,
        role: ['USER'],
        key: 'submission-menu-purchase-request'
    },
]

export const payrollMenus = [
    {
        name: 'Payroll',
        to: '/payroll',
        icon: <DollarCircleOutlined />,
        role: ['USER'],
        key: 'payroll-menu-payroll'
    }, 
    {
        name: 'Setup Payroll',
        to: '/payroll/setup',
        icon: <SettingOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'payroll-menu-setup'
    }, 
    {
        name: 'Setup Overtime',
        to: '/payroll/setup-overtime',
        icon: <BarsOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'overtime-menu-schema'
    }, 
    {
        name: 'Payroll Schema',
        to: '/payroll/schema',
        icon: <BarsOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'payroll-menu-schema'
    },
    {
        name: 'Payment',
        to: '/payroll/payment',
        icon: <WalletOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'payroll-menu-payment'
    },
    {
        name: 'Daily Payroll',
        icon: <WalletOutlined />,
        role: ['SUPERADMIN','ADMIN'],
        key: 'payroll-menu-daily',
        items: [
            {
                name: 'Setup Rate Hourly',
                to: '/payroll/setup-rate-hourly',
                role: ['SUPERADMIN','ADMIN'],
                key: 'payroll-menu-setup-rate-hourly'
            },
            {
                name: 'Daily Payment',
                to: '/payroll/daily-payment',
                role: ['SUPERADMIN', 'ADMIN'],
                key: 'payroll-menu-daily-payment'
            },
        ]
    },
    {
        name: 'SPT',
        to: '/payroll/spt',
        icon: <FileProtectOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'payroll-menu-spt'
    },
    {
        name: 'Sync',
        to: '/payroll/sync',
        icon: <SyncOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'payroll-menu-sync'
    }
]

export const managementMenus = [
    {
        name: 'Employee',
        icon: <UserOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'management-menu-employee',
        items: [{
            name: 'Status',
            to: '/management/employee-status',
            role: ['ADMIN', 'HR'],
            key: 'management-menu-employee-status'
        }, {
            name: 'Leave Rights',
            to: '/management/leave-rights',
            role: ['ADMIN', 'HR'],
            key: 'management-menu-leave-rights'
        }, {
            name: 'Medical Allowance',
            to: '/management/medical-allowance',
            role: ['ADMIN', 'HR'],
            key: 'management-menu-medical-allowance'
        },]
    }, {
        name: 'Announcement',
        to: '/management/announcement',
        icon: <SoundOutlined />,
        role: ['ADMIN', 'HR', 'GA'],
        key: 'management-menu-announcement'
    }, {
        name: 'Important Date',
        to: '/management/important-date',
        icon: <CalendarOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'management-menu-important-date'
    }, {
        name: 'Approval Schema',
        to: '/management/approval-schema',
        icon: <FileProtectOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'management-menu-approval-schema'
    }, {
        name: 'Budget',
        icon: <WalletOutlined />,
        role: ['ADMIN', 'HEAD', 'HR'],
        key: 'management-menu-budget',
        items: [{
            name: 'Planning',
            to: '/management/budget',
            role: ['ADMIN', 'HEAD', 'HR'],
            key: 'management-menu-budget-planning'
        },
            // {
            //     name: 'Realization',
            //     to: '/management/budget-realization',
            //     role: ['ADMIN', 'HEAD', 'HR'],
            //     key: 'management-menu-budget-realization'
            // }
        ]
    }, {
        name: 'Vacancy',
        to: '/management/vacancy',
        icon: <SolutionOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'management-menu-vacancy'
    }, {
        name: 'Appraisal',
        icon: <FormOutlined />,
        role: ['ADMIN', 'HR', 'HEAD'],
        key: 'management-menu-appraisal',
        items: [{
            name: 'Component',
            to: '/management/appraisal/component',
            role: ['ADMIN', 'HR', 'HEAD'],
            key: 'management-menu-appraisal-component'
        }, {
            name: 'Template Component',
            to: '/management/template-component',
            role: ['ADMIN', 'HR'],
            key: 'management-menu-master-item'
        }, {
            name: 'Appraisal',
            to: '/management/appraisal',
            role: ['ADMIN', 'HR', 'HEAD'],
            key: 'management-menu-apprisal'
        }]
    }, {
        name: 'Shift',
        to: '/management/shift',
        icon: <UserSwitchOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'management-menu-shift'
    },
]

export const organizationMenus = [
    {
        name: 'Employee',
        to: '/organization/employee',
        icon: <UserOutlined />,
        role: ['ADMIN', 'HR', 'USER'],
        key: 'organization-menu-employee'
    }, {
        name: 'Division',
        to: '/organization/division',
        icon: <ClusterOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'organization-menu-division'
    }, {
        name: 'Position',
        to: '/organization/position',
        icon: <ApartmentOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'organization-menu-position'
    }, {
        name: 'KPI',
        to: '/organization/kpi',
        icon: <EditOutlined />,
        role: ['HR'],
        key: 'organization-menu-kpi'
    }
]

export const recruitmentMenus = [
    {
        name: 'Employee',
        to: '/organization/employee',
        icon: <UserOutlined />,
        role: ['HR', 'ADMIN'],
        key: 'recruitment-menu-employee'
    }, {
        name: 'Company Information',
        to: '/organization/company',
        icon: <BankOutlined />,
        role: ['HR', 'ADMIN'],
        key: 'recruitment-menu-company'
    }, {
        name: 'Branch',
        to: '/organization/branch',
        icon: <BranchesOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'recruitment-menu-branch'
    }, {
        name: 'Division',
        to: '/organization/division',
        icon: <ClusterOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'recuritment-menu-division'
    }, {
        name: 'Position',
        to: '/organization/position',
        icon: <ApartmentOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'recruitment-menu-position'
    }, {
        name: 'KPI',
        to: '/organization/kpi',
        icon: <EditOutlined />,
        role: ['HR'],
        key: 'recruitment-menu-kpi'
    },
]

export const reportMenus = [
    {
        name: 'Employee',
        to: '/report/employee',
        icon: <UserOutlined />,
        role: ['ADMIN', 'HR', 'GA'],
        key: 'report-menu-employee'
    },
    {
        name: 'Annual Report',
        to: '/report/annual-report',
        icon: <FileDoneOutlined />,
        role: ['HR'],
        key: 'report-manu-annual-report'
    },
    {
        name: 'Activity',
        icon: <LoginOutlined />,
        role: ['ADMIN', 'HR', 'GA', 'OPR', 'HEAD'],
        key: 'report-menu-activity',
        items: [
            {
                name: 'Attendance',
                to: '/report/attendance',
                role: ['ADMIN', 'HR', 'GA', 'HEAD'],
                key: 'report-menu-attendance'
            }, {
                name: 'Daily Jobs',
                to: '/report/jobs',
                role: ['ADMIN', 'HR', 'GA'],
                key: 'report-menu-daily-jobs'
            }, {
                name: 'Payroll',
                to: '/report/payroll',
                role: ['ADMIN', 'HR', 'GA'],
                key: 'report-menu-payroll'
            }, {
                name: 'Salary',
                to: '/report/salary',
                role: ['ADMIN', 'HR'],
                key: 'report-menu-salary'
            },
            // {
            //     name: 'Payroll Operational',
            //     to: '/report/payroll-operational',
            //     role: ['ADMIN', 'HR', 'GA', 'OPR'],
            //     key: 'report-menu-payroll-operational'
            // },
        ]
    },
    {
        name: 'Information',
        icon: <InfoCircleOutlined />,
        role: ['ADMIN', 'HR', 'GA', 'ACC'],
        key: 'report-menu-information',
        items: [
            {
                name: 'Medical History',
                to: '/report/medical-history',
                role: ['ADMIN', 'HR', 'GA'],
                key: 'report-menu-medical-history'
            },
            {
                name: 'Budget',
                to: '/report/budget',
                role: ['ADMIN', 'ACC'],
                key: 'report-menu-budget'
            }
        ]
    },
    {
        name: 'Submission',
        icon: <SolutionOutlined />,
        role: ['ADMIN', 'HR', 'GA'],
        key: 'report-menu-submission',
        items: [
            {
                name: 'Leave',
                to: '/report/leave',
                role: ['ADMIN', 'HR', 'GA'],
                key: 'report-menu-leave'
            }, {
                name: 'Overtime',
                to: '/report/overtime',
                role: ['ADMIN', 'HR', 'GA'],
                key: 'report-menu-overtime'
            }, {
                name: 'Reimbursement',
                to: '/report/reimbursement',
                role: ['ADMIN', 'HR', 'GA'],
                key: 'report-menu-reimbursement'
            }, {
                name: 'Medical',
                to: '/report/medical',
                role: ['ADMIN', 'HR', 'GA'],
                key: 'report-menu-medical'
            }, {
                name: 'Business Trip',
                to: '/report/business-trip',
                role: ['ADMIN', 'HR', 'GA'],
                key: 'report-menu-business-trip'
            }, {
                name: 'Office Stationery',
                to: '/report/office-stationery',
                role: ['ADMIN', 'HR', 'GA'],
                key: 'report-menu-office-stationery'
            }, {
                name: 'Purchase Request',
                to: '/report/purchase-request',
                // role: ['ADMIN', 'HR', 'GA'],
                role: ['SUPERADMIN'],
                key: 'report-menu-purchase-request'
            },
        ]
    },
    {
        name: 'KPI',
        icon: <FormOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'report-menu-kpi',
        items: [
            {
                name: 'Component',
                to: '/report/component-kpi',
                role: ['ADMIN', 'HR'],
                key: 'report-component-kpi'
            },
            // {
            //     name: 'KPI',
            //     to: '/report/kpi',
            //     role: ['ADMIN', 'HR'],
            //     key: 'report-menu-kpi-children'
            // }
        ]
    },
    {
        name: 'Vacancy',
        to: '/report/vacancy',
        icon: <UserOutlined />,
        role: ['ADMIN', 'HR', 'GA'],
        key: 'report-menu-vacancy'
    },
]

export const dashboardMenus = [
    {
        name: 'Attendance',
        to: '/dashboard/attendance',
        icon: <LoginOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'dashboard-menu-attendance'
    },
    {
        name: 'Admin',
        to: '/dashboard/admin',
        icon: <LoginOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'dashboard-menu-admin'
    },
]

export const dashboardHrMenus = [
    {
        name: 'Employee',
        to: '/dashboardHr/employee',
        icon: <TeamOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'dashboard-hr-menu-employee'
    },
    {
        name: 'Attendance',
        to: '/dashboardHr/attendance',
        icon: <ScheduleOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'dashboard-hr-menu-attendance'
    },
    {
        name: 'Admin',
        to: '/dashboardHr/admin',
        icon: <AppstoreOutlined />,
        role: ['ADMIN', 'HR'],
        key: 'dashboard-hr-menu-admin'
    },
]

export const settingMenus = [
    {
        name: 'Company Information',
        to: '/organization/company',
        icon: <BankOutlined />,
        role: ['SUPERADMIN', 'ADMIN', 'HR'],
        key: 'organization-menu-company'
    },
    {
        name: 'Branch',
        to: '/organization/branch',
        icon: <BranchesOutlined />,
        role: ['SUPERADMIN', 'ADMIN', 'HR'],
        key: 'organization-menu-branch'
    },
    {
        name: 'User Previlages',
        to: '/setting/user',
        icon: <LockOutlined />,
        // role: ['SUPERADMIN', 'ADMIN', 'HR'],
        role: ['SUPERADMIN'],
        key: 'setting-menu-user'
    },
    {
        name: 'Attendance Area',
        to: '/setting/area',
        icon: <EnvironmentOutlined />,
        role: ['SUPERADMIN', 'ADMIN', 'HR'],
        key: 'setting-menu-attendance'
    },
    {
        name: 'Email',
        to: '/setting/email',
        icon: <SolutionOutlined />,
        role: ['SUPERADMIN'],
        key: 'setting-menu-email'
    },
    {
        name: 'Bank',
        to: '/setting/bank',
        icon: <BankOutlined />,
        role: ['SUPERADMIN', 'ADMIN', 'HR'],
        key: 'setting-menu-bank'
    },
    {
        name: 'COA',
        to: '/setting/coa',
        icon: <DollarCircleOutlined />,
        role: ['SUPERADMIN', 'ADMIN', 'HR'],
        key: 'setting-menu-coa'
    },
    {
        name: 'Instructions',
        to: '/setting/instruction',
        icon: <SnippetsOutlined />,
        role: ['SUPERADMIN', 'ADMIN', 'HR'],
        key: 'setting-menu-instruction'
    },
    {
        name: 'Version Patch',
        to: '/setting/version-patch',
        icon: <BarcodeOutlined />,
        role: ['SUPERADMIN'],
        key: 'setting-menu-version-patch'
    },
]

export const masterMenus = [
    {
        name: 'Company',
        to: '/setting/company',
        icon: <BankOutlined />,
        role: ['SUPERADMIN'],
        key: 'setting-menu-company'
    },
    {
        name: 'General',
        to: '/setting/general',
        icon: <SettingOutlined />,
        role: ['SUPERADMIN'],
        key: 'setting-menu-general'
    },
    {
        name: 'Location',
        icon: <EnvironmentOutlined />,
        role: ['SUPERADMIN', 'HR'],
        key: 'setting-menu-location',
        items: [{
            name: 'Country',
            to: '/setting/country',
            role: ['SUPERADMIN', 'HR'],
            key: 'setting-menu-country'
        },
        {
            name: 'Province',
            to: '/setting/province',
            role: ['SUPERADMIN', 'HR'],
            key: 'setting-menu-province'
        },
        {
            name: 'City',
            to: '/setting/city',
            role: ['SUPERADMIN', 'HR'],
            key: 'setting-menu-city'
        }]
    },
]

export const generalAffairMenus = [
    {
        name: 'Stationery',
        icon: <ScissorOutlined />,
        role: ['SUPERADMIN', 'GA'],
        key: 'ga-stationery',
        items: [
            {
                name: 'Category',
                to: '/stationery/category',
                role: ['SUPERADMIN', 'GA'],
                key: 'ga-stationery-category'
            }, {
                name: 'Item',
                to: '/stationery/item',
                role: ['SUPERADMIN', 'GA'],
                key: 'ga-stationery-item'
            }, {
                name: 'Goods Receipt',
                to: '/stationery/goods-receipt',
                role: ['SUPERADMIN', 'GA'],
                key: 'ga-stationery-good-receipt',
            }, {
                name: 'Goods Distribution',
                to: '/stationery/goods-distribution',
                role: ['SUPERADMIN', 'GA'],
                key: 'ga-stationery-good-distribution'
            }, {
                name: 'Stock',
                to: '/stationery/stock',
                role: ['SUPERADMIN', 'GA'],
                key: 'ga-stationery-stock'
            }
        ]
    },
    {
        name: 'Asset',
        icon: <BarsOutlined />,
        role: ['SUPERADMIN', 'GA'],
        key: 'ga-asset',
        items: [
            {
                name: 'Vendor',
                to: '/asset/vendor',
                role: ['SUPERADMIN', 'GA'],
                key: 'ga-vendor'
            }, {
                name: 'Category',
                to: '/asset/category',
                role: ['SUPERADMIN', 'GA'],
                key: 'ga-category'
            }, {
                name: 'Item',
                to: '/asset/item',
                role: ['SUPERADMIN', 'GA'],
                key: 'ga-item'
            }, {
                name: 'Goods Receipt',
                to: '/asset/goods-receipt',
                role: ['SUPERADMIN', 'GA'],
                key: 'ga-good-receipt'
            }, {
                name: 'Goods Distribution',
                to: '/asset/goods-distribution',
                role: ['SUPERADMIN', 'GA'],
                key: 'ga-goods-distribution'
            }, {
                name: 'Stock',
                to: '/asset/stock',
                role: ['SUPERADMIN', 'GA'],
                key: 'ga-stock'
            }
        ]
    },
    {
        name: 'Letter',
        to: '/letter',
        icon: <FileTextOutlined />,
        role: ['SUPERADMIN', 'GA'],
        key: 'ga-letter'
    },
]

import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography } from 'antd'
import { PageTitle, Spinner } from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import { useParams } from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import { toIdr } from '../../../utils/currencyHandler'

const { Text } = Typography

const Detail = () => {
    const { requestId } = useParams()
    const [medical, setMedical] = useState(null)

    const fetchMedical = (id) => {
        SentralModel.get('SubmissionMedical', {}, id).then((res) => {
            setMedical(res.data)
        })
    }

    useEffect(() => {
        fetchMedical(requestId)
    }, [requestId]);

    const statusUpdated = () => {
        setMedical(null)
        fetchMedical(requestId)
        window.location.reload();
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Medical Request" breadcrumbs={[['Submission List', '/submission-list'], ['Detail']]}></PageTitle>
            </div>
            {
                (medical) ?
                    <div>
                        <DetailHeader
                            name={medical?.employee.name}
                            date={medical?.created_at}
                            status={medical?.status} />
                        <Card bordered className="mb-4">
                            <Text strong>MEDICAL DETAIL</Text>
                            <Row className="mb-4 mt-1">
                                <Col span={12}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <th>Employee Name</th><td>{medical?.employee.name}</td>
                                            </tr>
                                            <tr>
                                                <th>Division</th><td>{medical?.employee.division.division_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Position</th><td>{medical?.employee.position.position_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Necessity</th><td>{medical?.description}</td>
                                            </tr>
                                            <tr>
                                                <th>Amount</th><td>{toIdr(medical?.medical_amount)}</td>
                                            </tr>
                                            <tr>
                                                <th>Claimed Amount</th><td>{toIdr(medical?.claimed_amount)}</td>
                                            </tr>
                                            <tr>
                                                <th>Attachment</th>
                                                <td>
                                                    {
                                                        (medical?.attachment) ?
                                                            <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + medical?.attachment}>
                                                                Show Attachment
                                                            </a> : '-'
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered>
                            <ApprovalLog category="MEDICAL"
                                requestId={medical?.sub_medical_id}
                                approvers={medical?.approver}
                                onSubmitted={() => statusUpdated()} />
                        </Card>
                    </div> : <Spinner />
            }

        </Card>
    );
}

export default Detail;

import { useEffect, useState } from 'react'
import { Col, Form, Input, Row, Select, Space, Typography, message } from 'antd'
import { AddButton, DataTable, DeletationButton, DeleteButton, EditButton, FormModal } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { extractNumber, toIdr } from '../../../utils/currencyHandler'

const { Title } = Typography

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const Incentive = () => {
    const forceUpdate = useForceUpdate()
    const [form] = Form.useForm()
    const [showModal, setShowModal] = useState(false)
    const [showModalAll, setShowModalAll] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [employees, setEmployees] = useState([])
    const [divisions, setDivisions] = useState([])
    const [grade, setGrade] = useState(null)
    const [empCode, setEmpCode] = useState(null)
    const [incentiveData, setIncentiveData] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(0)
    const [empGrade, setEmpGrade] = useState([])
    const [selectedEmpGrade, setSelectedEmpGrade] = useState(null)

    const fetchEmployees = () => {
        SentralModel.list('Employee', { filter: [['employee_status', ['ESPR', 'ESPB', 'ESKN']], ['is_active', '=', '1']], orderBy: 'name', order: 'asc' }).then(res => {
            const arr = []
            res.data.forEach((el) => {
                arr.push({
                    value: el.employee_code,
                    employee_grade: el.employee_grade,
                    label: el.name,
                    employee_status: el.employee_status
                })
            })
            setEmployees(arr)
        })
    }

    const fetchDivision = () => {
        SentralModel.list('Division', { filter: [['is_active', '=', '1']], orderBy: 'division_name', order: 'asc' }).then(res => {
            const arr = []
            res.data.forEach((el) => {
                arr.push({
                    value: el.division_id,
                    label: el.division_name
                })
            })
            setDivisions(arr)
        })
    }

    const fetchIncentiveData = () => {
        SentralModel.action("EmployeeIncentive", "getIncentive", null, null).then((res) => {
            setIncentiveData(res.data)
        })
    }

    const fetchEmployeeStatus = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'GR']], orderBy: 'order_key', order: 'asc' })
            .then(res => {
                let arr = []
                res.data.forEach((el) => {
                    arr.push({
                        value: el.detail_code,
                        label: el.description + ' (' + el.detail_code + ')'
                    })
                })

                setEmpGrade(arr)
            })
    }

    useEffect(() => {
        fetchEmployees()
        fetchDivision()
        fetchIncentiveData()
        fetchEmployeeStatus()

    }, [])

    useEffect(() => {
        const fetchEmployee = () => {
            SentralModel.list('Employee', { filter: [['employee_code', empCode], ['is_active', '=', '1']], orderBy: 'name', order: 'asc' }).then(res => {
                setGrade(res.data[0].employee_grade)
            })
        }
        fetchEmployee()

    }, [empCode])

    const addComponent = () => {
        setShowModal(true)
        setModalTitle('Add Incentive')
        form.setFieldsValue({
            employee_incentive_id: 0,
            employee_code: null,
            grade: null,
            daily: null,
            weekly: null
        })
    }

    const editComponent = (v) => {
        setShowModal(true)
        setEmpCode(v.employee_code)
        setModalTitle('Edit Incentive')
        form.setFieldsValue({
            employee_incentive_id: v.employee_incentive_id,
            employee_code: v.employee_code,
            grade: grade,
            daily: toIdr(v.daily),
            weekly: toIdr(v.weekly),
        })
    }

    const saveIncentive = (v) => {
        setLoaded(true)

        let payload = {
            employee_code: v.employee_code,
            grade: grade,
            daily: extractNumber(v.daily),
            weekly: extractNumber(v.weekly),
        }
        // console.log(payload)

        SentralModel.action("EmployeeIncentive", "saveIncentive", payload, v.employee_incentive_id === 0 ? 0 : v.employee_incentive_id).then((res) => {
            if (v.employee_prorate_id === 0) {
                message.success("Success Add Incentive")
            } else {
                message.success("Success Edit Incentive")
            }
            fetchIncentiveData()
            setShowModal(false)
            setLoaded(false)
            form.resetFields()
        }).catch((err) => {
            if (v.employee_incentive_id === 0) {
                message.warning("Failed Add Incentive")
            } else {
                message.warning("Failed Edit Incentive")
            }
        })
    }

    const allComponent = () => {
        setShowModalAll(true)
        setModalTitle('Generate All Incentive')
        form.resetFields()
    }

    const generateAll = (v) => {
        setLoaded(true)

        let payload = {
            employee_grade: selectedEmpGrade,
            daily: extractNumber(v.daily),
            weekly: extractNumber(v.weekly)
        }
        // console.log(payload)

        SentralModel.action("EmployeeIncentive", "generateAll", payload, null).then((res) => {
            message.success("Success Generate All Data")
            fetchIncentiveData()
            setShowModalAll(false)
            setLoaded(false)
        }).catch((err) => {
            message.warning("Failed Generate Data")
            setLoaded(false)
        })
    }

    const deleteIncentive = (v) => {
        SentralModel.action("EmployeeIncentive", "deleteIncentive", null, v).then((res) => {
            message.success("Success Delete Data")
            fetchIncentiveData()
        }).catch((err) => {
            message.warning("Failed Delete Data")
        })
    }

    const deleteAllIncentive = (v) => {
        SentralModel.action("EmployeeIncentive", "deleteAllIncentive", null, v).then((res) => {
            message.success("Success Delete Data")
            fetchIncentiveData()
        }).catch((err) => {
            message.warning("Failed Delete Data")
        })
    }

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    }, {
        title: 'Employee',
        key: 'employee',
        render: (row) => (
            row?.employee?.name ?? "-"
        )
    }, {
        title: 'Grade',
        dataIndex: 'grade',
        key: 'grade',
        width: '100px',
        align: 'center',
    }, {
        title: 'Daily Incentive',
        dataIndex: 'daily',
        render: (text) => (
            toIdr(text)
        )
    }, {
        title: 'Weekly Incentive',
        dataIndex: 'weekly',
        render: (text) => (
            toIdr(text)
        )
    }, {
        title: 'Action',
        align: 'center',
        width: '10%',
        render: (row) => (
            <Space>
                <EditButton onEdit={() => editComponent(row)} />
                <DeleteButton onConfirm={() => deleteIncentive(row.employee_incentive_id)} />
            </Space>
        )
    },
    ];

    const changeDailyIncentive = (v) => {
        let number = extractNumber(v.target.value)
        form.setFieldsValue({ daily: toIdr(number) })
    }

    const changeWeeklyIncentive = (v) => {
        let number = extractNumber(v.target.value)
        form.setFieldsValue({ weekly: toIdr(number) })
    }

    return (
        <div>
            <Title level={4}>Incentive Category</Title>
            <Row justify='space-between' style={{ marginBottom: 10 }}>
                <Col span={5}>
                    <Select
                        allowClear
                        showSearch
                        options={divisions}
                        placeholder="Select Division Filter"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: '100%' }}
                        onChange={(v) => setSelectedDivision(v)}
                        onClear={() => {
                            setSelectedDivision(0)
                            forceUpdate()
                        }}
                    />
                </Col>
                <Col>
                    <Space>
                        <AddButton onAdd={addComponent} disabled={loaded} title="Add Incentive" />
                        <AddButton onAdd={allComponent} disabled={loaded} title="Generate All Incentive" />
                        <DeletationButton onConfirm={deleteAllIncentive} disabled={loaded} title="Delete All Incentive" />
                    </Space>
                </Col>
            </Row>

            {
                incentiveData &&
                <DataTable
                    dataSource={
                        selectedDivision === 0 ? incentiveData : incentiveData.filter((el) => el.employee.division_id === selectedDivision)
                    }
                    columns={columns}
                    bordered
                />
            }

            {
                showModal ?
                    <FormModal submitForm={saveIncentive} form={form} title={modalTitle} showModal={showModal} onCloseModal={() => setShowModal(false)}>
                        <Form.Item name="employee_incentive_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="employee_code" label="Employee" rules={[{ required: true }]}>
                            <Select
                                disabled={modalTitle === 'Edit Incentive' ? true : false}
                                showSearch
                                options={employees}
                                placeholder="Select Employee"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(v) => {
                                    setEmpCode(v)
                                }}
                            />
                        </Form.Item>
                        <Form.Item name="daily" label="Daily Incentive" onChange={changeDailyIncentive} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="weekly" label="Weekly Incentive" onChange={changeWeeklyIncentive} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </FormModal> : null
            }

            {
                showModalAll ?
                    <FormModal submitForm={generateAll} form={form} title={modalTitle} showModal={showModalAll} onCloseModal={() => setShowModalAll(false)} loading={loaded}>
                        <Form.Item name="employee_grade" label="Employee Grade" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                options={empGrade}
                                placeholder="Select Employee Grade"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(v) => setSelectedEmpGrade(v)}
                            />
                        </Form.Item>
                        <Form.Item name="daily" label="Daily Incentive" onChange={changeDailyIncentive} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="weekly" label="Weekly Incentive" onChange={changeWeeklyIncentive} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </FormModal> : null
            }
        </div>
    )
}

export default Incentive

import { useEffect, useState } from 'react';
import { Tabs, Space, Timeline, Form, Input, Row, Col, Button, Switch, Typography, Select, TimePicker, DatePicker, message, Empty } from 'antd'
import { FileExcelOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { DeleteButton, EditButton, FormModal, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import authData from '../../../helpers/authData'
import moment from 'moment'

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = TimePicker;
const { Text } = Typography;

const JobDesc = () => {
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({ job_id: 0, finished: false })
    const [loaded, setLoaded] = useState(true);
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [periods, setPeriods] = useState([]);
    const [datePeriods, setDatePeriods] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [measurements, setMeasurements] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [category, setCategory] = useState(null);
    const [company, setCompany] = useState(null);
    const [status, setStatus] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(0);

    const fetchPeriods = (year) => {
        setLoaded(false)
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, { orderBy: 'from_date', order: 'asc' }).then(res => {
            setLoaded(true)
            setPeriods(res.data)
        })
    }
    const fetchJobs = () => {
        setLoaded(false)
        SentralModel.action('PayrollPeriod', 'getPeriod', { period_id: selectedPeriod }, 0).then(res => {
            setLoaded(true)
            setDatePeriods(res.message)
        })
    }
    const fetchMeasurements = () => {
        setLoaded(false)
        SentralModel.list('Measurement').then(res => {
            setLoaded(true)
            setMeasurements(res.data)
        })
    }
    const fetchCompany = () => {
        setLoaded(false)
        SentralModel.list('Company', { orderBy: 'company_id', order: 'asc' }).then(res => {
            setLoaded(true)
            setCompanies(res.data)
        })
    }

    const onChangeYear = (v) => {
        let year = moment(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
    }

    useEffect(() => {
        fetchMeasurements();
        fetchCompany();
    }, []);

    const addJob = () => {
        setModalTitle('Add Job')
        setDefaultValues({
            emp_to_do_id: 0,
            milestone_id: null,
            status: false
        })
        setFormModal(true)
    }

    const editJob = (v) => {
        setModalTitle('Edit Job')
        setDefaultValues({
            emp_to_do_id: v.emp_to_do_id,
            measurement_id: parseInt(v.measurement_id),
            description: v.description,
            company_id: v.company_id,
            do_date: moment(v.do_date, 'YYYY-MM-DD'),
            time: [moment(v.time_start, 'HH:mm:ss'), moment(v.time_end, 'HH:mm:ss')],
            status: v.status === 'Finished' ? true : false
        })
        setStatus(v.status === 'Finished' ? true : false)
        setFormModal(true)
    }

    const saveJob = (v) => {
        setSaving(true)
        let payload = {
            emp_to_do_id: v.emp_to_do_id,
            employee_code: authData.employee_code,
            measurement_id: category === null ? v.measurement_id : category,
            description: v.description,
            company_id: company === null ? v.company_id : company,
            do_date: moment(v.do_date, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            time_start: moment(v.time[0], 'HH:mm').format('HH:mm:ss'),
            time_end: moment(v.time[1], 'HH:mm').format('HH:mm:ss'),
            status: (status === null ? v.status : status) ? 'Finished' : 'In Progress'
        }

        SentralModel.action('EmployeeTodo', 'saveEmployeeTodo', payload, v.emp_to_do_id).then((res) => {
            setFormModal(false)
            if (selectedPeriod) {
                fetchJobs();
                setCategory(null)
            }
            message.success('Job saved', 2);
            setStatus(null)
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteJob = (id) => {
        message.loading('deleting job')
        SentralModel.deleteById('EmployeeToDo', id).then((res) => {
            message.destroy()
            message.success('Job deleted', 2)
            let tmp = jobs
            let index = tmp.findIndex(el => el.emp_to_do_id === id)
            tmp.splice(index, 1);
            setJobs([])
            setJobs(tmp)
            if (selectedPeriod) {
                fetchJobs();
            }
        })
    }

    const changePeriod = (v) => {
        setLoaded(false)
        SentralModel.action('PayrollPeriod', 'getPeriod', { period_id: v }, 0).then(res => {
            setDatePeriods(res.message)
            setSelectedPeriod(v)
            setLoaded(true)
        })
    }

    const changeCategory = e => {
        setCategory(e)
    }

    const changeCompany = e => {
        setCompany(e)
    }

    const changeStatus = e => {
        setStatus(e)
    }

    const changeStatusSave = (id, value) => {
        let payload = {
            emp_to_do_id: id,
            status: value ? 'Finished' : 'In Progress',
            change_status: true
        }
        console.log(payload)
        SentralModel.save('EmployeeToDo', payload, id).then((res) => {
            SentralModel.action('EmployeeTodo', 'saveEmployeeTodo', payload, id)
                .then((res) => {
                    if (id) {
                        let tmp = jobs
                        let index = tmp.findIndex(el => el.emp_to_do_id = id)
                        tmp[index] = res.data
                        setJobs(tmp)
                    } else {
                        let tmp = jobs
                        tmp.unshift(res.data)
                        setJobs(tmp)
                    }
                    message.success('Job saved', 2);
                })
        })
    }

    const disabledDate = (current) => {
        let currentMonth = moment().format('MM');
        let addMonth = 1;

        if (currentMonth === '12') {
            addMonth = 2;
        }

        let customDate = moment(new Date()).add(addMonth, 'y').format('YYYY');
        return current && current > moment(customDate, "YYYY");
    };

    const exportReport = () => {
        if (selectedPeriod === 0) {
            message.error('Please select Period first !!')
        } else {
            window.open(process.env.REACT_APP_API_URL + '/report/jobByEmployee?employee_code=' + authData.employee_code + '&period_id=' + selectedPeriod)
        }
    }

    return (
        <div>
            <Row justify="end" className="mb-3">
                <Col span={24} >
                    <span style={{ float: 'left' }}>
                        <Button type="primary" color="#ededed" onClick={addJob} icon={<PlusCircleOutlined />}>
                            Add Jobs
                        </Button>
                    </span>
                    <span style={{ float: 'right' }}>
                        <Select placeholder="Select Period" style={{ width: 200, marginRight: 10 }} onChange={changePeriod}>
                            {
                                periods.map((el, key) => (
                                    <Option key={key} value={el.payroll_period_id}>{el.period}</Option>
                                ))
                            }
                        </Select>
                        <Button type="primary" onClick={() => exportReport()} color="#ededed" icon={<FileExcelOutlined />}>
                            Export
                        </Button>
                    </span>
                    <span style={{ float: 'right', marginRight: '20px' }}>
                        <DatePicker picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year" />
                    </span>
                </Col>
            </Row>

            {
                loaded ?
                    selectedPeriod !== 0 ?
                        <Tabs defaultActiveKey="1" tabPosition="left" style={{ minHeight: 250, maxHeight: 350 }}>
                            {
                                datePeriods.map((period, i) => (
                                    <TabPane tab={moment(period.date).format('DD MMMM YYYY')} key={'tab_' + i}>
                                        <div className="full-width" style={{ maxHeight: 440, overflowY: 'scroll', border: '1px solid #ededed', borderRadius: 10 }}>
                                            <div className="full-width" style={{ minHeight: 50, padding: 20 }}>
                                                <Row style={{ minHeight: 250, maxHeight: 300 }}>
                                                    <Col span={24}>
                                                        {
                                                            period.jobs.length === 0 ?
                                                                <div key={'noJob_' + i} style={{ padding: 20 }}>
                                                                    <Text type="secondary">
                                                                        No job detail yet
                                                                    </Text>
                                                                </div>
                                                                : period.jobs.map((job, i) => (
                                                                    <Timeline key={'job_' + i}>
                                                                        <Timeline.Item key={'jobList_' + i}>
                                                                            <Row style={{ borderBottom: '1px solid #ededed' }}>
                                                                                <Col span={17}>
                                                                                    <Text strong style={{ display: 'block' }}>{job.measurement.title}</Text>
                                                                                    <Text type="secondary" style={{ display: 'block' }}>{job.description}</Text>
                                                                                    <Text type="secondary" style={{ display: 'block' }}>{job.company === null ? "-" : job.company.company_name}</Text>
                                                                                    <Text type="secondary">{job.time_start + ' - ' + job.time_end}</Text>
                                                                                </Col>
                                                                                <Col span={4}>
                                                                                    <Switch checkedChildren="Finished" unCheckedChildren="In Progress" defaultChecked={job.status === 'Finished' ? true : false} onChange={(v) => changeStatusSave(job.emp_to_do_id, v)} />
                                                                                </Col>
                                                                                <Col span={3}>
                                                                                    <Space>
                                                                                        {/* {job.measurement_id === '6' ? <CustomButton icon={<UnorderedListOutlined />} link to={'/mom/1'}/> : null} */}
                                                                                        <EditButton size="small" onEdit={() => editJob(job)} />
                                                                                        <DeleteButton size="small" onConfirm={() => deleteJob(job.emp_to_do_id)} />
                                                                                    </Space>
                                                                                </Col>
                                                                            </Row>
                                                                        </Timeline.Item>
                                                                    </Timeline>
                                                                ))
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </TabPane>
                                ))
                            }
                        </Tabs>
                        : <Empty />
                    : <Spinner />
            }

            {
                (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveJob(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="emp_to_do_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Job Category" name="measurement_id" required rules={[{ required: true }]}>
                            <Select placeholder="Select category" style={{ width: "100%" }} onChange={changeCategory}>
                                {
                                    measurements.map((el, key) => (
                                        <Option key={key} value={el.measurement_id}>{el.title}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Description" name="description" required rules={[{ required: true }]}>
                            <Input placeholder="Description" />
                        </Form.Item>
                        <Form.Item label="Company" name="company_id" required rules={[{ required: true }]}>
                            <Select showSearch allowClear placeholder="Select company" style={{ width: "100%" }} onChange={changeCompany} optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {
                                    companies.map((el, key) => (
                                        <Option key={key} value={el.company_id}>{el.company_name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Date" name="do_date" required rules={[{ required: true }]}>
                            <DatePicker format="DD MMMM YYYY" style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item label="Time" name="time" required rules={[{ required: true }]}>
                            <RangePicker format="HH:mm" style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item label="Status" name="status">
                            <Switch checkedChildren="Finished" unCheckedChildren="In Progress" defaultChecked={status} onChange={changeStatus} />
                        </Form.Item>
                    </FormModal> :
                    null
            }

        </div>
    );
}

export default JobDesc;

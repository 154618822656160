import { useState, useEffect } from 'react'
import { Card, Row, Select, Typography } from 'antd'
import { DataTable, NewButton, PageTitle, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import authData from '../../../helpers/authData'

const { Option } = Select
const { Text } = Typography

const Legalitas = () => {
    const [loaded, setLoaded] = useState(false)
    const [company, setCompany] = useState([])
    const [document, setDocument] = useState([])

    const fetchDocument = (v) => {
        setLoaded(false)
        SentralModel.action('Documentation', 'getLegalitas', v, 0)
            .then((res) => {
                setDocument(res.data)
                setLoaded(true)
            })
    }

    const fetchCompany = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'ISOP']], orderBy: 'order_key', order: 'asc' })
            .then(res => {
                setCompany(res.data)
            })
    }

    useEffect(() => {
        fetchDocument({ company_code: '' })
        fetchCompany()

    }, [])

    const changeCompany = (v) => {
        if (v === undefined) {
            fetchDocument({ company_code: '' })
        } else {
            fetchDocument({ company_code: v })
        }
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
            align: 'center',
            width: '5%'
        },
        {
            title: 'Company',
            key: 'iso_company',
            render: (row) => (
                row?.company?.description
            ),
            align: 'center',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            align: 'center',
        },
        {
            title: 'Attachment',
            render: (row) => (
                row.attachment ?
                    <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + row.attachment}>
                        Show Attachment
                    </a>
                    : '-'
                // <div className="text-center">
                //     {text}
                // </div>
            ),
            align: 'center',
        },
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Legalitas" breadcrumbs={[['Legalitas']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Legalitas List</Text>
                        {
                            authData.division_id === 31 && authData.position_id === 63 ?
                                <NewButton right to="/documentation/legalitas/detail/0" title="New Legalitas" />
                                :
                                null
                        }
                    </div>
                }>
                    <Row justify='center'>
                        <Select showSearch allowClear size="large" style={{ width: "15%" }} placeholder="Select Company" optionFilterProp="children" onChange={changeCompany} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {
                                company.map((el, key) => (
                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                ))
                            }
                        </Select>
                    </Row>

                    {
                        loaded ?
                            <DataTable size='small' bordered dataSource={document} columns={columns} />
                            : <Spinner />
                    }
                </Card>

            </Card>
        </div>
    )
}

export default Legalitas
import React from 'react'
import { Card, /*Tabs*/ } from 'antd'
import { PageTitle } from '../../../components'
import OvertimeCompany from './OvertimeCompany'

// const { TabPane } = Tabs

const Index = () => {
    return (
        <Card className="content-container">
            <PageTitle title="Setup Overtime" breadcrumbs={[['Setup Overtime']]}></PageTitle>
            <OvertimeCompany />
            {/* <Tabs defaultActiveKey="period" tabPosition="left" >
                <TabPane tab="Overtime Company" key="overtime_company">
                    <OvertimeCompany />
                </TabPane>
                <TabPane tab="Master Overtime" key="master_overtime">
                </TabPane>
                <TabPane tab="Overtime Default" key="overtime_default">
                </TabPane>
                <TabPane tab="Master Overtime Employee" key="master_overtime_employee">
                </TabPane>
            </Tabs> */}
        </Card>
    )
}

export default Index
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, message } from 'antd';
import { SubmitButton, Uploader } from '../../../../components'
import authData from '../../../../helpers/authData'
import { toIdr, extractNumber } from '../../../../utils/currencyHandler'
import { SentralModel } from '../../../../models/SentralModel'
import { ImageViewer } from '../../../../components/ImageViewer';

const RequestForm = (props) => {
    const [form] = Form.useForm()
    const [attachmentFile, setAttachmentFile] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [medicalInfo, setMedicalInfo] = useState(null);

    const fileUploaded = (v) => {
        setAttachmentFile(v.message)
    }

    const changeTotal = (v) => {
        if (v.target.value) {
            form.setFieldsValue({ medical_amount: toIdr(extractNumber(v.target.value)) })
            form.setFieldsValue({ reimbursed: toIdr(extractNumber(v.target.value) * 0.8) })
        }
    }

    const submitMedical = (v) => {
        if (!attachmentFile) {
            message.error('Attachment is required', 2)
        } else {
            let claimed = extractNumber(v.medical_amount) * 80 / 100;
            if (claimed > extractNumber(v.balance)) {
                message.error('Reimbursed must be less then ' + v.balance, 2)
            } else {
                let data = {
                    attachment: attachmentFile,
                    employee_code: authData.employee_code,
                    description: v.description,
                    medical_amount: extractNumber(v.medical_amount),
                    claimed_amount: claimed,
                }
                props.onSubmit(data)
            }
        }
    }

    const fetchMedicalInfo = () => {
        SentralModel.action('Submission', 'getMedicalBalance', null, null).then((res) => {
            setMedicalInfo(res.data[0])
        })
    }

    useEffect(() => {
        fetchMedicalInfo()
    }, []);

    form.setFieldsValue({ balance: toIdr(medicalInfo ? medicalInfo.balance < 0 ? 0 : medicalInfo.balance : 0) })
    
    return (
        <Form size="middle" form={form} layout="vertical" onFinish={submitMedical}>
            <Row justify="center">
                <Col span={20}>
                    <Row>
                        <Col span={12} style={{ padding: 10 }}>
                            <Form.Item name="medical_amount" label="Total" rules={[{ required: true }]}>
                                <Input onChange={changeTotal} />
                            </Form.Item>
                            <Form.Item name="reimbursed" label="Reimbursed" rules={[{ required: true }]}>
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ padding: 10 }}>
                            <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                                <Input autoSize={{ minRows: 2 }} />
                            </Form.Item>
                            <Form.Item name="balance" label="Remaining Balance">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Row span={12} style={{ padding: 10 }}>
                            <Form.Item label="Attachment">
                                <Uploader folder="submission-medical" onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} view={true} />
                            </Form.Item>
                            <div style={{ width: 200, maxWidth: '100%', marginLeft: 20, marginTop: 25, border: attachmentFile !== null ? attachmentFile.includes('.pdf') ? '0px' : '1px solid #ededed' : '0px' }}>
                                {
                                    attachmentFile !== null ?
                                        attachmentFile.includes('.pdf') ?
                                            <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + attachmentFile}>
                                                Show Attachment
                                            </a> :
                                            <ImageViewer previewable={attachmentFile ? true : false} src={process.env.REACT_APP_API_FILE + "/" + attachmentFile} alt="attachment" /> :
                                        null
                                }
                            </div>
                        </Row>
                    </Row>
                    <Row>
                        <Col span={24} className="text-right">
                            <SubmitButton loading={props.saving || uploading} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default RequestForm;

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Card, Col, Row, Space, Tag, Typography, message } from 'antd'
import { DataTable, PageTitle, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import DetailHeader from '../DetailHeader'
import moment from 'moment'
import { toIdr } from '../../../utils/currencyHandler'

const { Text } = Typography

const Detail = () => {
    const { requestId } = useParams()
    const [loan, setLoan] = useState({})
    const [loaded, setLoaded] = useState(false)

    const fetchLoan = (requestId) => {
        setLoaded(false)
        SentralModel.get('SubmissionLoan', {}, requestId).then((res) => {
            setLoan(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchLoan(requestId)

    }, [requestId])

    const onChangePaymentStatus = (v) => {
        SentralModel.action("Loan", "changePaymentStatus", { status: v.status }, v.loan_payment_id).then((res) => {
            message.success("Success Change Payment Status")
            fetchLoan(requestId)
        }).catch((err) => {
            message.warning("Failed Change Payment Status")
        })
    }


    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
        },
        {
            title: 'Payment Month Period',
            key: 'month_period',
            render: (row) => (
                // row.month_period
                moment(`${row.month_period > 12 ? moment().year() + 1 : moment().year()}-${row.month_period > 12 ? row.month_period - 12 : row.month_period}-25`).format("DD MMMM YYYY")
            )
        },
        {
            title: 'Total Payment',
            key: 'total_payment',
            render: (row) => (
                toIdr(row?.total_payment)
            )
        },
        {
            title: 'Status',
            key: 'status',
            render: (row) => (
                row?.status === null ? "-" :
                    <Tag color={row.status === 'WAITING' ? 'blue' : row.status === 'APPROVED' ? 'green' : 'red'}>{row.status}</Tag>
            ),
            align: 'center'
        },
        {
            title: 'Attachment',
            key: 'attachment',
            render: (row) => (
                (row.attachment) ?
                    <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + row.attachment}>
                        Show Attachment
                    </a> : '-'
            ),
            align: 'center'
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                <Space>
                    <Button type='primary' disabled={row.status === 'WAITING' && row.attachment !== null ? false : true} onClick={() => onChangePaymentStatus({ ...row, status: 'APPROVED' })}>
                        Approve
                    </Button>
                    <Button type='primary' danger disabled={row.status !== 'WAITING' ? true : false} onClick={() => onChangePaymentStatus({ ...row, status: 'REJECT' })}>
                        Reject
                    </Button>
                </Space >
            ),
            align: 'center'
        }
    ]

    return (
        <Card className="content-container">
            <PageTitle title="Loan Payment Detail" breadcrumbs={[['Submission List', '/submission-list'], ['Detail']]}></PageTitle>
            <Card title={
                <div className="full-width">
                    <Text strong style={{ float: 'left' }}>Loan Payment Detail</Text>
                </div>
            }>
                <DetailHeader
                    name={loan?.employee?.name}
                    date={loan?.created_at}
                    status={loan?.status} />
                {
                    loaded ?
                        <>
                            <Card bordered className="mb-4">
                                <Row justify='space-between'>
                                    <Col>
                                        <Text strong>LOAN PAYMENT DETAIL</Text>
                                    </Col>
                                </Row>
                                <Row className="mb-4 mt-1">
                                    <Col span={12}>
                                        <table className="table-collapse table-default" style={{ width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <th>Employee Name</th><td>{loan?.employee?.name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Division</th><td>{loan?.employee?.division?.division_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Position</th><td>{loan?.employee?.position?.position_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Total Amount</th><td>{toIdr(loan?.total_amount)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Loan Period</th><td>{`${loan?.loan_period} Month`}</td>
                                                </tr>
                                                <tr>
                                                    <th>Start Period</th><td>{moment(loan?.start_period).format("DD MMMM YYYY")}</td>
                                                </tr>
                                                <tr>
                                                    <th>End Period</th><td>{moment(loan?.end_period).format("DD MMMM YYYY")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </Card>
                            <Card title="Payment List">
                                {
                                    loan.loan_payments &&
                                    <DataTable columns={columns} dataSource={loan?.loan_payments} bordered size="small" search={false} pagination={false} />
                                }
                            </Card>
                        </>
                        : <Spinner />
                }
            </Card>
        </Card>
    )
}

export default Detail
import React, { useState } from 'react';
import { Card, Select, Button, Col, Row, DatePicker, message } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons'
import { PageTitle, DataTable, Spinner } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import { toIdr } from '../../../utils/currencyHandler';
import moment from 'moment';

const { Option } = Select;

const Index = () => {
    const [loaded, setLoaded] = useState(true);
    const [payrollData, setPayrollData] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(0);

    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const fetchPeriods = (year) => {
        setLoaded(false)
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, { orderBy: 'from_date', order: 'asc' }).then(res => {
            setLoaded(true)
            setSelectedYear(year)
            setPeriods(res.data)
        })
    }

    const onChangeYear = (v) => {
        let year = moment(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
    }

    const changePeriod = (v) => {
        setLoaded(false)
        SentralModel.action('Payroll', 'reportSalary', { period_id: v }, 0).then(res => {
            setPayrollData(res.data)
            setSelectedPeriod(v)
            setLoaded(true)
        })
    }

    const disabledDate = (current) => {
        let currentMonth = moment().format('MM');
        let addMonth = 1;

        if (currentMonth === '12') {
            addMonth = 2;
        }

        let customDate = moment(new Date()).add(addMonth, 'y').format('YYYY');
        return current && current > moment(customDate, "YYYY");
    };

    const exportReport = () => {
        if (selectedPeriod === 0) {
            message.error('Please select Period first !!')
        } else {
            window.open(process.env.REACT_APP_API_URL + `/report/salary?period_id=${selectedPeriod}`)
        }
    }

    const exportOther = (employee_code) => {
        if (selectedPeriod === 0) {
            message.error('Please select Period first !!')
        } else {
            window.open(process.env.REACT_APP_API_URL + `/report/payment?year=${selectedYear}&employee_code=${employee_code}`)
        }
    }

    const dataSource = [
        ...payrollData.map(el => {
            return {
                name: el.employee !== null ? el.employee.name : '',
                employee_code: el.employee_code,
                position_name: el.employee !== null ? el.employee.position !== null ? el.employee.position.position_name : '' : '',
                division_name: el.employee !== null ? el.employee.division !== null ? el.employee.division.division_name : '' : '',
                grade: el.employee !== null ? el.employee.employee_grade !== null ? el.employee.employee_grade : '' : '',
                base_salary: el.base_salary ?? 0,
                daily_incentive: el.daily_incentive ?? 0,
                weekly_incentive: el.weekly_incentive ?? 0,
                overtime: el.overtime ?? 0,
                jkk: el.jkk ?? 0,
                jkm: el.jkm ?? 0,
                bpjs_kes: el.bpjs_healthy ?? 0,
                other_allowance: el.other_allowance ?? 0,
                total_allowance: el.total_allowance ?? 0,
                late_fee: el.late_fee ?? 0,
                absence: el.absence ?? 0,
                jht: el.jht ?? 0,
                jip: el.jip ?? 0,
                pph: el.pph ?? 0,
                other_deduction: el.other_deduction ?? 0,
                total_deduction: el.total_deduction ?? 0,
                take_home_pay: el.take_home_pay ?? 0,
            }
        })
    ];

    const payrollColumn = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '80px',
            fixed: 'left',
        },
        {
            title: 'Admin',
            dataIndex: 'employee_code',
            key: 'employee_code',
            width: '250px',
        },
        {
            title: 'Admin',
            dataIndex: 'employee_code',
            key: 'employee_code',
            width: '250px',
        },
        {
            title: 'Admin',
            dataIndex: 'employee_code',
            key: 'employee_code',
            width: '250px',
        },
        {
            title: 'Admin',
            dataIndex: 'employee_code',
            key: 'employee_code',
            width: '250px',
        },
        {
            title: 'Admin',
            dataIndex: 'employee_code',
            key: 'employee_code',
            width: '250px',
        },
        {
            title: 'Admin',
            dataIndex: 'employee_code',
            key: 'employee_code',
            width: '250px',
        },
        {
            title: 'Admin',
            dataIndex: 'employee_code',
            key: 'employee_code',
            width: '250px',
        },
        {
            title: 'Admin',
            dataIndex: 'employee_code',
            key: 'employee_code',
            width: '250px',
        },
        {
            title: 'Admin',
            dataIndex: 'employee_code',
            key: 'employee_code',
            width: '250px',
        },
        {
            title: 'Admin',
            dataIndex: 'employee_code',
            key: 'employee_code',
            width: '250px',
        },
        {
            title: 'Admin',
            dataIndex: 'employee_code',
            key: 'employee_code',
            width: '250px',
        },
        {
            title: 'Admin',
            dataIndex: 'employee_code',
            key: 'employee_code',
            width: '250px',
        },
    ];

    return (
        <Card className="content-container">
            <PageTitle title="Shift Scheduler" breadcrumbs={[['Shift Scheduler']]}></PageTitle>

            <Row justify="end" className="mb-3">
                <Col span={24} >
                    <span style={{ float: 'right' }}>
                        <Select placeholder="Select Period" style={{ width: 200, marginRight: 10 }} onChange={changePeriod}>
                            {
                                periods.map((el, key) => (
                                    <Option key={key} value={el.payroll_period_id}>{el.period}</Option>
                                ))
                            }
                        </Select>
                        <Button type="primary" onClick={() => exportReport()} color="#ededed" icon={<FileExcelOutlined />}>
                            Export
                        </Button>
                    </span>
                    <span style={{ float: 'right', marginRight: '20px' }}>
                        <DatePicker picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year" />
                    </span>
                </Col>
            </Row>
            {
                (loaded) ?
                    <DataTable scroll={{ x: 1500 }} pagination={false} size="small" columns={payrollColumn} dataSource={dataSource} bordered />
                    : <Spinner />
            }
        </Card>
    );
}

export default Index;

import React, { useState } from 'react';
import { Descriptions, Modal, Typography, Input, Button, message } from 'antd';
import { toIdr } from '../../../utils/currencyHandler';
import { Spinner } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import moment from 'moment';

const { TextArea } = Input
const { Text } = Typography

const ConfirmPayment = (props) => {
    const { data, paymentId, employee } = props
    const [remarks, setRemarks] = useState(null)
    const [saving, setSaving] = useState(false)
    const salaryValue = {
        float: 'right'
    }

    const getArray = (type) => {
        let keys = Object.keys(data[type])
        let values = Object.values(data[type])
        let x = [];
        keys.forEach((el, key) => {
            x.push([el, values[key]])
        })
        return x
    }

    const getTotal = (type) => {
        // if(type==='deductions'){
        //     console.log(Object.values(data['deductions']))
        // }
        let values = Object.values(data[type])
        let total = 0;
        values.forEach(el => {
            // if (typeof el === "object") {
            //     let values2 = Object.values(el)
            //     values2.forEach(el2 => {
            //         total+=el2
            //     })
            // } else {
            //     total+=el
            // }

            if (typeof el !== "object") {
                total += el
            }
        })
        return total;
    }

    const allAllowances = () => {
        let alw = [['Salary Rate', data.earnings.base]]

        getArray('bonus').forEach(el => {
            alw.push(el)
        })
        getArray('allowances').forEach(el => {
            alw.push(el)
        })
        return alw
    }

    const allDeductions = () => {
        return getArray('deductions')
    }

    const getTotalAllowances = () => {
        return getTotal('allowances') + getTotal('bonus') + data.earnings.base
    }

    const getTotalDeductions = () => {
        return getTotal('deductions')
    }

    const submitPayment = () => {
        setSaving(true)
        let a = [];
        allAllowances().filter(alw => alw[1] > 0).map((el) => {
            return a.push({ description: el[0], amount: el[1] })
        })

        let d = [];
        allDeductions().filter(ded => ded[1] > 0).map((el) => {
            return d.push({ description: el[0], amount: el[1] })
        })

        let totalA = 0;
        a.forEach(el => {
            totalA += el.amount;
        })
        let totalD = 0;
        d.forEach(el => {
            totalD += el.amount;
        })
        let pph = d.find(el => el.description.match('PPh21'))
        let bpjsKes = d.find(el => el.description === 'BPJS Kesehatan')
        let base = a.find(el => el.description === 'Salary Rate')

        const payload = {
            remarks: remarks,
            allowances: a,
            deductions: d,
            payment_id: paymentId,
            period_id: 68,
            employee_code: employee.employee_code,
            base_salary: (base) ? base.amount : 0,
            bruto: data.earnings.bruto,
            daily_incentive: 0,
            weekly_incentive: 0,
            overtime: 0,
            jkk: 0,
            jkm: 0,
            bpjs_kes: (bpjsKes) ? bpjsKes.amount : 0,
            other_allowance: 0,
            total_allowance: totalA,
            late_fee: 0,
            absence: 0,
            jht: 0,
            jip: 0,
            pph21: (pph) ? pph.amount : 0,
            other_deduction: 0,
            total_deduction: 0,
            take_home_pay: 0
        }

        // console.log(payload)

        SentralModel.action('Payroll', 'confirmDailyPayment', payload, 0).then(() => {
            message.success('Payment Confirmed', 2)
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }).catch(() => {
            setSaving(false)
        })
    }

    return (
        <div>
            <Modal
                title="Salary Detail"
                style={{ top: 20 }}
                visible={props.showModal}
                width={1000}
                onCancel={() => props.setModal(false)}
                footer={null}>
                {
                    (employee) ?
                        <Descriptions className="mb-3" size="middle" column={5} bordered layout="vertical">
                            <Descriptions.Item label={<div className="text-center"><Text strong>Employee Name</Text></div>}>
                                <div className="text-center">{employee.name}</div>
                            </Descriptions.Item>
                            <Descriptions.Item label={<div className="text-center"><Text strong>Division</Text></div>}>
                                <div className="text-center">{employee.division_name}</div>
                            </Descriptions.Item>
                            <Descriptions.Item label={<div className="text-center"><Text strong>Position</Text></div>}>
                                <div className="text-center">{employee.position_name}</div>
                            </Descriptions.Item>
                            <Descriptions.Item label={<div className="text-center"><Text strong>Date</Text></div>}>
                                <div className="text-center">{moment(employee.date).format('DD MMMM yyyy')}</div>
                            </Descriptions.Item>
                        </Descriptions>
                        : null
                }
                {
                    (data) ?
                        <div>
                            <table className="table-collapse table-default mb-3" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ width: '50%' }}>Allowance</th>
                                        <th className="text-center" style={{ width: '50%' }}>Deduction</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}>
                                            <Descriptions size="small" column={1} bordered layout="horizontal">
                                                {
                                                    (allAllowances().filter(alw => alw[1] > 0).map((el, key) => (
                                                        <Descriptions.Item key={key} label={<Text strong>{el[0]}</Text>}>
                                                            <span style={salaryValue}>{toIdr(el[1])}</span>
                                                        </Descriptions.Item>
                                                    )))
                                                }
                                            </Descriptions>
                                        </td>
                                        <td style={{ verticalAlign: 'top' }}>
                                            <Descriptions size="small" column={1} bordered layout="horizontal">
                                                {
                                                    (allDeductions().filter(ded => ded[1] > 0).map((el, key) => (
                                                        <Descriptions.Item key={key} label={<Text strong>{el[0]}</Text>}>
                                                            <span style={salaryValue}>{toIdr(el[1])}</span>
                                                        </Descriptions.Item>
                                                    )))
                                                }
                                            </Descriptions>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-right">
                                            <Text strong>Allowance Total : {toIdr(getTotalAllowances())}</Text>
                                        </td>
                                        <td className="text-right">
                                            <Text strong>Deduction Total: {toIdr(getTotalDeductions())}</Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-right">
                                            <Text strong>Take Home Pay : {toIdr(getTotalAllowances() - getTotalDeductions())}</Text>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <TextArea onChange={(v) => setRemarks(v.target.value)} placeholder="Write remarks" />
                            {/* <div className="full-width mt-2 text-right">
                                <Button size="middle" type="primary" onClick={submitPayment} loading={saving}>Confirm</Button>
                            </div> */}
                        </div> : <Spinner />
                }
                <div className="full-width mt-2 text-right">
                    <Button size="middle" type="primary" onClick={submitPayment} loading={saving}>Confirm</Button>
                </div>
            </Modal>
        </div>
    );
}

export default ConfirmPayment;

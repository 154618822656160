import { useEffect, useState } from 'react'
import { Card, Descriptions, Col, Form, Input, Row, Select, Space, Typography, message } from 'antd'
import { AddButton, DataTable, DeletationButton, DeleteButton, EditButton, FormModal } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { extractNumber, toIdr } from '../../../utils/currencyHandler'
import { toFullDate } from '../../../utils/dateHandler'
import moment from 'moment';

const { Title } = Typography

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const Prorate = () => {
    const forceUpdate = useForceUpdate()
    const [form] = Form.useForm()
    const [showModal, setShowModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [employees, setEmployees] = useState([])
    const [divisions, setDivisions] = useState([])
    const [prorateData, setProrateData] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(0)

    const [empCode, setEmpCode] = useState(null)
    const [joinDate, setJoinDate] = useState(null)
    const [workingDuration, setWorkingDuration] = useState(null)
    const [basicSalary, setBasicSalary] = useState(null)
    const [prorateSalary, setProrateSalary] = useState(null)

    const fetchEmployees = () => {
        SentralModel.list('Employee', { filter: [['employee_status', ["ESPB", "ESKN"]], ['is_active', '=', '1']], orderBy: 'name', order: 'asc' }).then(res => {
            const arr = []
            res.data.forEach((el) => {
                arr.push({
                    value: el.employee_code,
                    label: el.name,
                    employee_status: el.employee_status,
                })
            })
            setEmployees(arr)
        })
    }

    const fetchDivision = () => {
        SentralModel.list('Division', { filter: [['is_active', '=', '1']], orderBy: 'division_name', order: 'asc' }).then(res => {
            const arr = []
            res.data.forEach((el) => {
                arr.push({
                    value: el.division_id,
                    label: el.division_name
                })
            })
            setDivisions(arr)
        })
    }

    const fetchProrateData = () => {
        SentralModel.action("EmployeeProrate", "getProrate", null, null).then((res) => {
            setProrateData(res.data)
        })
    }

    useEffect(() => {
        fetchEmployees()
        fetchDivision()
        fetchProrateData()

    }, [])

    useEffect(() => {
        const fetchBasicSalary = () => {
            SentralModel.action('EmployeeBasicSalary', 'getSalaryEmp', { employee_code: empCode }, null).then((res) => {
                res.data === null ? setBasicSalary('0') : setBasicSalary(res.data.basic_salary)
            })
        }
        fetchBasicSalary()

        const fetchEmployee = () => {
            SentralModel.list('Employee', { filter: [['employee_code', empCode], ['is_active', '=', '1']], orderBy: 'name', order: 'asc' }).then(res => {
                setJoinDate(res.data[0].join_date)
            })
        }
        fetchEmployee()

        const generateProrate = () => {
            if (joinDate !== null) {
                var dateFrom = new Date(joinDate);
                var dateTo = new Date();

                var difference = (dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear())));
                setWorkingDuration(difference);

                const salary = (((difference <= 12 ? difference : 12) / 12) * basicSalary);
                setProrateSalary(salary);
            }
        }
        generateProrate()

    }, [empCode, basicSalary, joinDate])

    const addComponent = () => {
        setShowModal(true)
        setModalTitle('Add Prorate')
        form.setFieldsValue({
            employee_prorate_id: 0,
            employee_code: null,
            basic_salary: null,
            join_date: null,
            prorate_salary: null
        })
    }

    const editComponent = (v) => {
        setShowEditModal(true)
        setModalTitle('Edit Prorate')
        form.setFieldsValue({
            employee_prorate_id: v.employee_prorate_id,
            employee_code: v.employee_code,
            basic_salary: toIdr(v.basic_salary),
            join_date: moment(v.join_date, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            prorate_salary: toIdr(v.prorate_salary),
        })
    }

    const saveProrate = (v) => {
        setLoaded(true)

        let payload = {
            employee_code: v.employee_code,
            basic_salary: modalTitle === 'Add Prorate' ? basicSalary : extractNumber(v.basic_salary),
            join_date: moment(joinDate, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            prorate_salary: modalTitle === 'Add Prorate' ? prorateSalary : extractNumber(v.prorate_salary),
        }
        // console.log(payload)

        SentralModel.action("EmployeeProrate", "saveProrate", payload, v.employee_prorate_id === 0 ? 0 : v.employee_prorate_id).then((res) => {
            if (v.employee_prorate_id === 0) {
                message.success("Success Add Prorate")
            } else {
                message.success("Success Edit Prorate")
            }
            fetchProrateData()
            setShowModal(false)
            setShowEditModal(false)
            setLoaded(false)
            form.resetFields()
        }).catch((err) => {
            if (v.employee_prorate_id === 0) {
                message.warning("Failed Add Prorate")
            } else {
                message.warning("Failed Edit Prorate")
            }
        })
    }

    const deleteProrate = (v) => {
        SentralModel.action("EmployeeProrate", "deleteProrate", null, v).then((res) => {
            message.success("Success Delete Data")
            fetchProrateData()
        }).catch((err) => {
            message.warning("Failed Delete Data")
        })
    }

    const deleteAllProrate = (v) => {
        SentralModel.action("EmployeeProrate", "deleteAllProrate", null, v).then((res) => {
            message.success("Success Delete Data")
            fetchProrateData()
        }).catch((err) => {
            message.warning("Failed Delete Data")
        })
    }

    // const allComponent = () => {
    //     setShowModalAll(true)
    //     form.resetFields()
    // }

    // const generateAll = (v) => {
    //     setLoaded(true)
    //     SentralModel.action("EmployeeProrate", "generateAll", v, null).then((res) => {
    //         message.success("Success Generate All Data")
    //         fetchProrateData()
    //         setShowModalAll(false)
    //         setLoaded(false)
    //     }).catch((err) => {
    //         message.warning("Failed Generate Data")
    //         setLoaded(false)
    //     })
    // }

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
        align: 'center',
    },
    {
        title: 'Employee',
        key: 'employee',
        render: (row) => (
            row?.employee?.name ?? "-"
        )
    }, {
        title: 'Basic Salary',
        key: 'basic_salary',
        render: (row) => (
            toIdr(row?.basic_salary ?? 0)
        )
    }, {
        title: 'Join Date',
        key: 'join_date',
        render: (row) => (
            toFullDate(row?.join_date)
        )
    }, {
        title: 'Prorate Salary',
        key: 'prorate_salary',
        render: (row) => (
            toIdr(row?.prorate_salary ?? 0)
        )
    }, {
        title: 'Action',
        align: 'center',
        render: (row) => (
            <Space>
                <EditButton onEdit={() => editComponent(row)} />
                <DeleteButton onConfirm={() => deleteProrate(row.employee_prorate_id)} />
            </Space>
        )
    },
    ];

    const changeBasicSalary = (v) => {
        let number = extractNumber(v.target.value)
        form.setFieldsValue({ basic_salary: toIdr(number) })
    }

    const changeProrateSalary = (v) => {
        let number = extractNumber(v.target.value)
        form.setFieldsValue({ prorate_salary: toIdr(number) })
    }

    return (
        <div>
            <Title level={4}>Prorate</Title>
            <Row justify='space-between' style={{ marginBottom: 10 }}>
                <Col span={5}>
                    <Select
                        allowClear
                        showSearch
                        options={divisions}
                        placeholder="Select Division Filter"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: '100%' }}
                        onChange={(v) => setSelectedDivision(v)}
                        onClear={() => {
                            setSelectedDivision(0)
                            forceUpdate()
                        }}
                    />
                </Col>
                <Col>
                    <Space>
                        <AddButton onAdd={addComponent} disabled={loaded} title="Add Prorate" />
                        <DeletationButton onConfirm={deleteAllProrate} disabled={loaded} title="Delete All Prorate" />
                    </Space>
                </Col>
            </Row>

            {
                prorateData &&
                <DataTable
                    dataSource={
                        selectedDivision === 0 ? prorateData : prorateData.filter((el) => el.employee.division_id === selectedDivision)
                    }
                    columns={columns}
                    bordered
                />
            }

            {
                showModal ?
                    <FormModal submitForm={saveProrate} form={form} showModal={showModal} onCloseModal={() => setShowModal(false)} title={modalTitle}>
                        <Form.Item name="employee_prorate_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="employee_code" label="Employee" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                options={employees.filter((el) => el.employee_status === 'ESPB' || el.employee_status === 'ESKN')}
                                placeholder="Select Employee"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(v) => {
                                    setEmpCode(v)
                                }}
                            />
                        </Form.Item>

                        {
                            empCode === null ? null :
                                <Card>
                                    <Descriptions title="Prorate Info" bordered >
                                        <Descriptions.Item span={3} label="Basic Salary">{toIdr(basicSalary)}</Descriptions.Item>
                                        <Descriptions.Item span={3} label="Join Date">{toFullDate(joinDate)}</Descriptions.Item>
                                        <Descriptions.Item span={3} label="Working Duration">{workingDuration} Months</Descriptions.Item>
                                        <Descriptions.Item span={3} label="Prorate Salary">{toIdr(prorateSalary)}</Descriptions.Item>
                                    </Descriptions>
                                </Card>
                        }
                    </FormModal> : null
            }

            {
                showEditModal ?
                    <FormModal submitForm={saveProrate} form={form} showModal={showEditModal} onCloseModal={() => setShowEditModal(false)} title={modalTitle}>
                        <Form.Item name="employee_prorate_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="employee_code" label="Employee" rules={[{ required: true }]} hidden>
                            <Select
                                showSearch
                                options={employees.filter((el) => el.employee_status === 'ESPB' || el.employee_status === 'ESKN')}
                                placeholder="Select Employee"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(v) => {
                                    setEmpCode(v)
                                }}
                            />
                        </Form.Item>
                        <Form.Item name="basic_salary" label="Basic Salary" onChange={changeBasicSalary} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="prorate_salary" label="Prorate Salary" onChange={changeProrateSalary} >
                            <Input />
                        </Form.Item>
                    </FormModal> : null
            }
        </div>
    )
}

export default Prorate

import React, { useState, useEffect } from 'react';
import { Typography, Tabs, Form, Input, Select, message, Space } from 'antd'
import { DollarOutlined } from '@ant-design/icons'
import { AddButton, FormModal, DataTable, Spinner, EditButton, DeleteButton } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
const { Title } = Typography
const { TabPane } = Tabs

const Component = () => {
    const [componentForm] = Form.useForm()
    const [formModal, setFormModal] = useState(false)
    const [payrollComponents, setPayrollComponents] = useState([])
    const [saving, setSaving] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [formState, setFormState] = useState('add')

    const fetchComponents = () => {
        setLoaded(false)
        SentralModel.list('PayrollComponent', { orderBy: 'description', order: 'asc' }).then(res => {
            setPayrollComponents(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchComponents()
    }, []);

    const editComponent = (row) => {
        setFormState('edit')
        componentForm.setFieldsValue({
            component_id: row.payroll_component_id,
            component_code: row.component_code,
            component_name: row.description,
            category: row.category,
            type: row.type
        })
        setFormModal(true)
    }

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
    },
    {
        title: 'Code',
        dataIndex: 'component_code',
        key: 'code',
    }, {
        title: 'Name',
        dataIndex: 'description',
        key: 'name',
    }, {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    }, {
        title: 'Action',
        align: 'center',
        render: (row) => (
            <Space>
                <EditButton onEdit={() => editComponent(row)} />
                <DeleteButton onConfirm={() => deleteComponent(row.payroll_component_id)} />
            </Space>
        )
    },
    ];

    const addComponent = () => {
        setFormState('add')
        componentForm.resetFields()
        componentForm.setFieldsValue({ component_id: 0 })
        setFormModal(true)
    }

    const saveComponent = (v) => {
        setSaving(true)
        let data = {
            component_code: v.component_code,
            description: v.component_name,
            category: v.category,
            type: v.type
        }
        SentralModel.save('PayrollComponent', data, v.component_id).then(() => {
            fetchComponents()
            message.success('Component saved');
            setSaving(false)
            setFormModal(false)
        })
    }

    const deleteComponent = (v) => {
        SentralModel.action('Payroll', 'deletePayrollComponent', null, v).then(() => {
            message.success('Success Delete Component');
            fetchComponents()
        }).catch((err) => {
            message.success('Failed Delete Component');
        })

    }

    return (
        <div>
            <Title level={4}>Salary Components</Title>
            <Tabs defaultActiveKey="allowance" type="card" tabBarExtraContent={
                <AddButton onAdd={addComponent} title="Add Component" />
            }>
                <TabPane tab={
                    <span>
                        <DollarOutlined style={{ color: 'green' }} /> Allowances
                    </span>
                } key="allowance">
                    {
                        loaded ?
                            <DataTable bordered size="small" columns={columns} dataSource={payrollComponents.filter(el => el.category === "ALLOWANCE")} />
                            : <Spinner />
                    }
                </TabPane>
                <TabPane tab={
                    <span>
                        <DollarOutlined style={{ color: 'red' }} /> Deductions
                    </span>
                } key="deduction">
                    {
                        loaded ?
                            <DataTable bordered size="small" columns={columns} dataSource={payrollComponents.filter(el => el.category === "DEDUCTION")} />
                            : <Spinner />
                    }
                </TabPane>
                <TabPane tab={
                    <span>
                        <DollarOutlined style={{ color: 'blue' }} /> Benefits
                    </span>
                } key="benefit">
                    {
                        loaded ?
                            <DataTable bordered size="small" columns={columns} dataSource={payrollComponents.filter(el => el.category === "BENEFIT")} />
                            : <Spinner />
                    }
                </TabPane>
            </Tabs>

            <FormModal form={componentForm} title={(formState === "add") ? 'Add new Component' : 'Edit Component'}
                showModal={formModal}
                onCloseModal={() => setFormModal(false)}
                submitForm={(v) => saveComponent(v)}
                saving={saving}>
                <Form.Item name="component_id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="component_code" label="Component Code">
                    <Input disabled={(formState === "edit")} />
                </Form.Item>
                <Form.Item name="component_name" label="Component Name">
                    <Input />
                </Form.Item>
                <Form.Item name="category" label="Category">
                    <Select>
                        <Select.Option value="ALLOWANCE">ALLOWANCE</Select.Option>
                        <Select.Option value="DEDUCTION">DEDUCTION</Select.Option>
                        <Select.Option value="BENEFIT">BENEFIT</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="type" label="Type">
                    <Select>
                        <Select.Option value="GAPOK">GAPOK</Select.Option>
                        <Select.Option value="HONORARIUM">HONORARIUM</Select.Option>
                        <Select.Option value="TRANSPORT">TRANSPORT</Select.Option>
                        <Select.Option value="LEMBUR">LEMBUR</Select.Option>
                        <Select.Option value="LAINNYA">LAINNYA</Select.Option>
                    </Select>
                </Form.Item>
            </FormModal>
        </div>
    );
}

export default Component;

import React, { useState } from 'react';
import { Card, Select, Button, Col, Row, DatePicker, message } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons'
import { PageTitle, DataTable, Spinner } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import { toIdr } from '../../../utils/currencyHandler';
import moment from 'moment';

const { Option } = Select;

const Salary = () => {
    const [loaded, setLoaded] = useState(true);
    const [payrollData, setPayrollData] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(0);

    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const fetchPeriods = (year) => {
        setLoaded(false)
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, { orderBy: 'from_date', order: 'asc' }).then(res => {
            setLoaded(true)
            setSelectedYear(year)
            setPeriods(res.data)
        })
    }

    const onChangeYear = (v) => {
        let year = moment(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
    }

    const changePeriod = (v) => {
        setLoaded(false)
        SentralModel.action('Payroll', 'reportSalary', { period_id: v }, 0).then(res => {
            setPayrollData(res.data)
            setSelectedPeriod(v)
            setLoaded(true)
        })
    }

    const disabledDate = (current) => {
        let currentMonth = moment().format('MM');
        let addMonth = 1;

        if (currentMonth === '12') {
            addMonth = 2;
        }

        let customDate = moment(new Date()).add(addMonth, 'y').format('YYYY');
        return current && current > moment(customDate, "YYYY");
    };

    const exportReport = () => {
        if (selectedPeriod === 0) {
            message.error('Please select Period first !!')
        } else {
            window.open(process.env.REACT_APP_API_URL + `/report/salary?period_id=${selectedPeriod}`)
        }
    }

    const exportOther = (employee_code) => {
        if (selectedPeriod === 0) {
            message.error('Please select Period first !!')
        } else {
            window.open(process.env.REACT_APP_API_URL + `/report/payment?year=${selectedYear}&employee_code=${employee_code}`)
        }
    }

    const dataSource = [
        ...payrollData.map(el => {
            return {
                name: el.employee !== null ? el.employee.name : '',
                employee_code: el.employee_code,
                position_name: el.employee !== null ? el.employee.position !== null ? el.employee.position.position_name : '' : '',
                division_name: el.employee !== null ? el.employee.division !== null ? el.employee.division.division_name : '' : '',
                grade: el.employee !== null ? el.employee.employee_grade !== null ? el.employee.employee_grade : '' : '',
                base_salary: el.base_salary ?? 0,
                daily_incentive: el.daily_incentive ?? 0,
                weekly_incentive: el.weekly_incentive ?? 0,
                overtime: el.overtime ?? 0,
                jkk: el.jkk ?? 0,
                jkm: el.jkm ?? 0,
                bpjs_kes: el.bpjs_healthy ?? 0,
                other_allowance: el.other_allowance ?? 0,
                total_allowance: el.total_allowance ?? 0,
                late_fee: el.late_fee ?? 0,
                absence: el.absence ?? 0,
                jht: el.jht ?? 0,
                jip: el.jip ?? 0,
                pph: el.pph ?? 0,
                other_deduction: el.other_deduction ?? 0,
                total_deduction: el.total_deduction ?? 0,
                take_home_pay: el.take_home_pay ?? 0,
            }
        })
    ];

    const payrollColumn = [
        {
            title: 'Full Name',
            dataIndex: 'name',
            key: 'name',
            width: '250px',
            fixed: 'left',
        },
        {
            title: 'Employee Code',
            dataIndex: 'employee_code',
            key: 'employee_code',
            width: '150px',
            fixed: 'left',
        },
        {
            title: 'Job Position',
            dataIndex: 'position_name',
            key: 'position_name',
            width: '250px',
            fixed: 'left',
        },
        {
            title: 'Department',
            dataIndex: 'division_name',
            key: 'division_name',
            width: '250px',
        },
        {
            title: 'Grade',
            dataIndex: 'grade',
            key: 'grade',
            width: '150px',
        },
        {
            title: 'Basic Salary',
            dataIndex: 'base_salary',
            key: 'base_salary',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'Daily Incentive',
            dataIndex: 'daily_incentive',
            key: 'daily_incentive',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'Weekly Incentive',
            dataIndex: 'weekly_incentive',
            key: 'weekly_incentive',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'Overtime',
            dataIndex: 'overtime',
            key: 'overtime',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'JKK',
            dataIndex: 'jkk',
            key: 'jkk',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'JKM',
            dataIndex: 'jkm',
            key: 'jkm',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'BPJS Kesehatan',
            dataIndex: 'bpjs_kes',
            key: 'bpjs_kes',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'Other Allowance',
            dataIndex: 'other_allowance',
            key: 'other_allowance',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'Total Allowance',
            dataIndex: 'total_allowance',
            key: 'total_allowance',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'Late',
            dataIndex: 'late_fee',
            key: 'late_fee',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'Absence',
            dataIndex: 'absence',
            key: 'absence',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'JHT',
            dataIndex: 'jht',
            key: 'jht',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'JIP',
            dataIndex: 'jip',
            key: 'jip',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'Goverment Tax',
            dataIndex: 'pph',
            key: 'pph',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'Other Deduction',
            dataIndex: 'other_deduction',
            key: 'other_deduction',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'Total Deduction',
            dataIndex: 'total_deduction',
            key: 'total_deduction',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'Take Home Pay',
            dataIndex: 'take_home_pay',
            key: 'take_home_pay',
            width: 200,
            render: (text) => (
                <div className="text-right">
                    {text === 0 ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Button type="primary" onClick={() => exportOther(row.employee_code)} color="#ededed" icon={<FileExcelOutlined />} />
                </div>
            )
        },
    ];

    return (
        <Card className="content-container">
            <PageTitle title="Salary Report" breadcrumbs={[['Salary Report']]}></PageTitle>

            <Row justify="end" className="mb-3">
                <Col span={24} >
                    <span style={{ float: 'right' }}>
                        <Select placeholder="Select Period" style={{ width: 200, marginRight: 10 }} onChange={changePeriod}>
                            {
                                periods.map((el, key) => (
                                    <Option key={key} value={el.payroll_period_id}>{el.period}</Option>
                                ))
                            }
                        </Select>
                        <Button type="primary" onClick={() => exportReport()} color="#ededed" icon={<FileExcelOutlined />}>
                            Export
                        </Button>
                    </span>
                    <span style={{ float: 'right', marginRight: '20px' }}>
                        <DatePicker picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year" />
                    </span>
                </Col>
            </Row>
            {
                (loaded) ?
                    <DataTable scroll={{ x: 1500 }} pagination={false} size="small" columns={payrollColumn} dataSource={dataSource} bordered />
                    : <Spinner />
            }
        </Card>
    );
}

export default Salary;

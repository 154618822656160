import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Card, Col, Form, Input, InputNumber, Row, Tag, Typography, message } from 'antd'
import { DataTable, EditButton, FormModal, PageTitle, Spinner, Uploader } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import DetailHeader from '../DetailHeader'
import moment from 'moment'
import { toIdr } from '../../../utils/currencyHandler'

const { Text } = Typography

const Detail = () => {
    const { id } = useParams()
    const [form] = Form.useForm()
    const [loan, setLoan] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [paymentModal, setPaymentModal] = useState(false)
    const [attachmentFile, setAttachmentFile] = useState(null)
    const [uploading, setUploading] = useState(false)

    const fetchLoan = (id) => {
        setLoaded(false)
        SentralModel.get('SubmissionLoan', {}, id).then((res) => {
            setLoan(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchLoan(id)

    }, [id])

    const fileUploaded = (v) => {
        setAttachmentFile(v.message)
    }

    const savePayment = (v) => {
        let payload = {
            total_payment: v.total_payment,
            attachment: attachmentFile
        }

        if (attachmentFile === null) {
            message.warning("Attachment Cannot Empty")
            return
        }

        SentralModel.action("Loan", 'paymentLoan', payload, v.loan_payment_id).then((res) => {
            fetchLoan(id)
            message.success("Success Save Data")
            setPaymentModal(false)
        }).catch((err) => {
            message.warning("Failed Save Data")
        })
    }

    const onShowModalPayment = (v) => {
        form.setFieldsValue({
            sub_loan_id: id,
            loan_payment_id: v.loan_payment_id,
            month_period: v.month_period,
            total_payment: v.total_payment,
        })
        setPaymentModal(true)
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
        },
        {
            title: 'Payment Month Period',
            key: 'month_period',
            render: (row) => (
                // row.month_period
                moment(`${row.month_period > 12 ? moment().year() + 1 : moment().year()}-${row.month_period > 12 ? row.month_period - 12 : row.month_period}-25`).format("DD MMMM YYYY")
            )
        },
        {
            title: 'Total Payment',
            key: 'total_payment',
            render: (row) => (
                toIdr(row?.total_payment)
            )
        },
        {
            title: 'Status',
            key: 'status',
            render: (row) => (
                <Tag color={row.status === 'WAITING' ? 'blue' : row.status === 'APPROVED' ? 'green' : 'red'}>{row.status}</Tag>
            )
        },
        {
            title: 'Attachment',
            key: 'attachment',
            render: (row) => (
                row?.attachment ?
                    <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + row.attachment}>
                        Show Attachment
                    </a> :
                    <a disabled href='/'>
                        Show Attachment
                    </a>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                // row.status === 'WAITING' ?
                loan?.status !== 'WAITING' ?
                    <EditButton onEdit={() => onShowModalPayment(row)} />
                    : <EditButton disabled onEdit={() => onShowModalPayment(row)} />
            ),
            align: 'center'
        }
    ]

    return (
        <Card className="content-container">
            <PageTitle title="Loan Detail" breadcrumbs={[['Loan', '/loan'], ['Detail']]}></PageTitle>
            <Card title={
                <div className="full-width">
                    <Text strong style={{ float: 'left' }}>Loan Detail</Text>
                </div>
            }>
                <DetailHeader
                    name={loan?.employee?.name}
                    date={loan?.created_at}
                    status={loan?.status} />
                {
                    loaded ?
                        <>
                            <Card bordered className="mb-4">
                                <Row justify='space-between'>
                                    <Col>
                                        <Text strong>LOAN DETAIL</Text>
                                    </Col>
                                </Row>
                                <Row className="mb-4 mt-1">
                                    <Col span={12}>
                                        <table className="table-collapse table-default" style={{ width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <th>Employee Name</th><td>{loan?.employee?.name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Division</th><td>{loan?.employee?.division?.division_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Position</th><td>{loan?.employee?.position?.position_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Total Amount</th><td>{toIdr(loan?.total_amount)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Loan Period</th><td>{`${loan?.loan_period} Month`}</td>
                                                </tr>
                                                <tr>
                                                    <th>Start Period</th><td>{moment(loan?.start_period).format("DD MMMM YYYY")}</td>
                                                </tr>
                                                <tr>
                                                    <th>End Period</th><td>{moment(loan?.end_period).format("DD MMMM YYYY")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </Card>
                            <Card title="Payment List">
                                <DataTable columns={columns} dataSource={loan?.loan_payments} bordered size="small" search={false} pagination={false} />
                            </Card>
                        </>
                        : <Spinner />
                }
            </Card>

            {
                paymentModal ?
                    <FormModal submitForm={savePayment} form={form} showModal={paymentModal} onCloseModal={() => setPaymentModal(false)} title="Payment" width={600} loading={uploading}>
                        <Form.Item name="loan_payment_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="month_period" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="total_payment" label="Total Payment" rules={[{ required: true }]}>
                            <InputNumber disabled min={0} placeholder='Rp. 1.000.000' style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item label="Attachment">
                            <Uploader folder="submission-loan-payment" onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} view={true} />
                        </Form.Item>
                    </FormModal> : null
            }
        </Card>
    )
}

export default Detail
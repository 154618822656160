import { message } from 'antd'
import { encrypt } from '../helpers/crypto'
import moment from 'moment'

export const AuthModel = {
    auth,
    logout,
    handleRes,
    forgot,
    changePassword,
    handleChangePassword,
    authSSO
};

function auth(email, password) {
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: email,
            password: password,
        })
    };

    return fetch(`${process.env.REACT_APP_API_URL}/auth/login`, requestOptions).then(this.handleRes);
}

function handleRes(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                this.logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        // localStorage.setItem('userToken', encrypt(data.access_token));

        const startDate = moment(data.data.user.last_change_password).format('YYYY-MM-DD');
        const endDate = moment(new Date()).format('YYYY-MM-DD');

        const diffInDays = moment(endDate).diff(moment(startDate), 'days');

        if (diffInDays > 90) {
            localStorage.setItem('userToken', encrypt(data.access_token));
            message.destroy()
            message.error('Password expiring after 90 days', 2)
            setTimeout(() => {
                window.location.href = "/password-expired"
            }, 2000);
        } else {
            localStorage.setItem('userToken', encrypt(data.access_token));
            localStorage.setItem('userTokenUnencrypted', data.access_token);
            localStorage.setItem('currentUser', encrypt(JSON.stringify(data.data)));
            localStorage.setItem('role', encrypt(JSON.stringify(data.privileges)))
            localStorage.setItem('firstLogin', true);
            message.destroy()
            message.success('successfully authenticated', 2)
            setTimeout(() => {
                const urlParams = new URLSearchParams(window.location.search);
                const redirectUri = urlParams.get('redirect');
                if (redirectUri) {
                    window.location.href = redirectUri
                } else {
                    window.location.href = "/"
                }
            }, 1000);
        }
    });
}

function logout(redirect = '') {
    localStorage.clear();
    if (redirect === '') {
        window.location.href = "/login"
    } else {
        window.location.href = "/login?redirect=" + redirect
    }
}

function forgot(email, code) {
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: email,
            code: code
        })
    };

    return fetch(`${process.env.REACT_APP_API_URL}/auth/forgot`, requestOptions);
}

function changePassword(email, password) {
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: email,
            password: password
        })
    };

    return fetch(`${process.env.REACT_APP_API_URL}/auth/changePassword`, requestOptions).then(this.handleChangePassword);
}

function handleChangePassword(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                this.logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function authSSO(token) {
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            sso_token: token
        })
    };

    return fetch(`${process.env.REACT_APP_API_URL}/auth/authSSO`, requestOptions).then(this.handleRes);
}

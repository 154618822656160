// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mb-1{
    margin-bottom: 5px
}
.mb-2{
    margin-bottom: 10px
}
.mb-3{
    margin-bottom: 15px
}
.mb-4{
    margin-bottom: 20px
}
.mb-5{
    margin-bottom: 25px
}

.mt-1{
    margin-top: 5px
}
.mt-2{
    margin-top: 10px
}
.mt-3{
    margin-top: 15px
}
.mt-4{
    margin-top: 20px
}
.mt-5{
    margin-top: 25px
}

.w-100{
    width:'100%'
}

.p-1{
    padding:5px
}
.p-2{
    padding:10px
}
.p-3{
    padding:15px
}
.p-4{
    padding:20px
}
.p-5{
    padding:25px
}

.pt-1{
    padding-top:5px
}
.pt-2{
    padding-top:10px
}
.pt-3{
    padding-top:15px
}
.pt-4{
    padding-top:20px
}
.pt-5{
    padding-top:25px
}`, "",{"version":3,"sources":["webpack://./src/assets/size.css"],"names":[],"mappings":"AAAA;IACI;AACJ;AACA;IACI;AACJ;AACA;IACI;AACJ;AACA;IACI;AACJ;AACA;IACI;AACJ;;AAEA;IACI;AACJ;AACA;IACI;AACJ;AACA;IACI;AACJ;AACA;IACI;AACJ;AACA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;AACA;IACI;AACJ;AACA;IACI;AACJ;AACA;IACI;AACJ;AACA;IACI;AACJ;;AAEA;IACI;AACJ;AACA;IACI;AACJ;AACA;IACI;AACJ;AACA;IACI;AACJ;AACA;IACI;AACJ","sourcesContent":[".mb-1{\n    margin-bottom: 5px\n}\n.mb-2{\n    margin-bottom: 10px\n}\n.mb-3{\n    margin-bottom: 15px\n}\n.mb-4{\n    margin-bottom: 20px\n}\n.mb-5{\n    margin-bottom: 25px\n}\n\n.mt-1{\n    margin-top: 5px\n}\n.mt-2{\n    margin-top: 10px\n}\n.mt-3{\n    margin-top: 15px\n}\n.mt-4{\n    margin-top: 20px\n}\n.mt-5{\n    margin-top: 25px\n}\n\n.w-100{\n    width:'100%'\n}\n\n.p-1{\n    padding:5px\n}\n.p-2{\n    padding:10px\n}\n.p-3{\n    padding:15px\n}\n.p-4{\n    padding:20px\n}\n.p-5{\n    padding:25px\n}\n\n.pt-1{\n    padding-top:5px\n}\n.pt-2{\n    padding-top:10px\n}\n.pt-3{\n    padding-top:15px\n}\n.pt-4{\n    padding-top:20px\n}\n.pt-5{\n    padding-top:25px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import { Card } from 'antd'
import { PageTitle } from '../../../components'
import RateHourly from './RateHourly'

const Index = () => {
    return (
        <Card className="content-container">
            <PageTitle title="Setup Rate Hourly" breadcrumbs={[['Setup Rate Hourly']]}></PageTitle>
            <RateHourly />
        </Card>
    )
}

export default Index

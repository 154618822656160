import { useState } from 'react';
import { Row, Col, Select, Form, Input, TimePicker, DatePicker, message, Space } from 'antd'
import { EditButton, Spinner, DataTable, FormModal } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import authData from '../../../helpers/authData';
import dayjs from 'dayjs';

const { Option } = Select

const Attendance = () => {
    const [loaded, setLoaded] = useState(true);
    const [periods, setPeriods] = useState([]);
    const [stagings, setStagings] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({ job_id: 0, finished: false });
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [limitDays, setLimitDays] = useState(0)

    const fetchPeriods = (year) => {
        setLoaded(false)
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, { orderBy: 'from_date', order: 'desc' }).then(res => {
            setLoaded(true)
            setPeriods(res.data)
        })
    }

    const fetchSettingData = () => {
        SentralModel.action("ManualAttendanceSetting", "getOne", null, null).then((res) => {
            if (res.data === null) {
                setLimitDays(0)
            } else {
                setLimitDays(res?.data?.limit_days)
            }
        })
    }

    const onChangeYear = (v) => {
        fetchSettingData()
        let year = dayjs(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
    }

    const changePeriod = (value) => {
        setLoaded(false);
        SentralModel.action('Attendance', 'logAttendance', { attendance_id: authData.attendance_id, division_id: authData.division_id, period_id: value }, 0).then(res => {
            setLoaded(true)
            setSelectedPeriod(value);
            getLog(value);
        })
    }

    const getLog = (value) => {
        setLoaded(false)
        SentralModel.action('Attendance', 'getLog', { attendance_id: authData.attendance_id, period_id: value }).then(res => {
            setStagings(res.data)
            setLoaded(true)
        })
    }

    const disabledDate = (current) => {
        let currentMonth = dayjs(Date.now()).format('MM');
        let addYear = 1;

        if (currentMonth === '12') {
            addYear = 2;
        }

        let customDate = dayjs(new Date()).add(addYear, 'y').format('YYYY');
        return current && current > dayjs(customDate, "YYYY");
    };

    const dataSource = [
        ...stagings.map(el => {
            return {
                attendance_id: el.attendance_id,
                date: el.date,
                in_time: el.in_time,
                out_time: el.out_time,
                late_time: el.late_time,
                effective_time: el.effective_time,
                in_description: el.in_description,
                out_description: el.out_description,
                description: el.description,
                status: el.status,
                in_verified: el.in_verified,
                out_verified: el.out_verified,
                is_verified: el.is_verified,
                remark_verified: el.remark_verified

            }
        })
    ];

    const columns = [
        {
            title: 'Date',
            align: 'center',
            render: (row) => (
                <div className="text-center">
                    {dayjs(row.date).format('DD MMM YYYY')}
                </div>
            )
        },
        {
            title: 'In',
            align: 'center',
            render: (row) => {
                let backgroundText;
                if (row.description === 'In') {
                    backgroundText = '#2efe64';
                } else if (row.description === 'Alpha') {
                    backgroundText = '#000000';
                } else if (row.description === 'WFH') {
                    backgroundText = '#000000';
                } else if (row.description === 'Leave') {
                    backgroundText = '#e74c3c'
                } else {
                    backgroundText = '#bdbdbd';
                }

                let inTime;
                if (row.description !== 'In') {
                    inTime = '';
                } else {
                    inTime = row.in_time;
                }

                return {
                    props: {
                        style: { background: backgroundText }
                    },
                    children: <span>{inTime}</span>
                }
            }
        },
        {
            title: 'Out',
            align: 'center',
            render: (row) => {
                let backgroundText;
                if (row.description === 'In') {
                    backgroundText = '#2efe64';
                } else if (row.description === 'Alpha') {
                    backgroundText = '#000000';
                } else if (row.description === 'WFH') {
                    backgroundText = '#000000';
                } else if (row.description === 'Leave') {
                    backgroundText = '#e74c3c'
                } else {
                    backgroundText = '#bdbdbd';
                }

                let colorText;
                if (row.description === 'Alpha') {
                    colorText = '#bf0900';
                } else if (row.description === 'WFH') {
                    colorText = '#ffffff'
                } else if (row.description === 'Leave') {
                    colorText = '#060606'
                } else {
                    colorText = '#000';
                }

                let outTime;
                if (row.description === 'In' && row.out_time === '00:00:00') {
                    outTime = 'Not Absent';
                } else if (row.description === 'In') {
                    outTime = row.out_time;
                } else if (row.description === 'WFH') {
                    outTime = 'WFH';
                } else if (row.description === 'Alpha') {
                    outTime = 'Not Absent';
                } else if (row.description === 'Leave') {
                    outTime = row.status;
                } else {
                    outTime = '';
                }

                return {
                    props: {
                        style: { background: backgroundText, color: colorText }
                    },
                    children: <span>{outTime}</span>
                }
            }
        },
        {
            title: 'Late',
            align: 'center',
            render: (row) => {
                let backgroundText;
                if (row.description === 'In') {
                    row.late_time === '00:00:00' ? backgroundText = '#2efe64' : backgroundText = '#f78181';
                } else if (row.description === 'Alpha') {
                    backgroundText = '#000000';
                } else if (row.description === 'WFH') {
                    backgroundText = '#000000';
                } else if (row.description === 'Leave') {
                    backgroundText = '#e74c3c'
                } else {
                    backgroundText = '#bdbdbd';
                }

                let lateTime;
                if (row.description !== 'In') {
                    lateTime = '';
                } else {
                    lateTime = row.late_time;
                }

                return {
                    props: {
                        style: { background: backgroundText }
                    },
                    children: <span>{lateTime === '00:00:00' ? '' : lateTime}</span>
                }
            }
        },
        {
            title: 'Effective',
            align: 'center',
            render: (row) => {
                let effectiveTime;
                if (row.description !== 'In') {
                    effectiveTime = '';
                } else {
                    effectiveTime = row.effective_time;
                }

                return (
                    <div className="text-center">
                        {effectiveTime}
                    </div>
                )
            }
        },
        {
            title: 'Source In',
            align: 'center',
            render: (row) => {
                let remarksIn;
                row.description === 'Weekend' ? remarksIn = null : remarksIn = row.in_description;

                return (
                    <div className="text-center">
                        {remarksIn}
                    </div>
                )
            }
        },
        {
            title: 'Source Out',
            align: 'center',
            render: (row) => {
                let remarksOut;
                row.description === 'Weekend' ? remarksOut = null : remarksOut = row.out_description;

                return (
                    <div className="text-center">
                        {remarksOut}
                    </div>
                )
            }
        },
        {
            title: 'Description',
            align: 'center',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Action',
            align: 'center',
            render: (row) => (
                <Space>
                    {
                        dayjs(row.date, 'YYYY-MM-DD') < dayjs(Date.now()).subtract(limitDays === 0 ? 7 : limitDays, 'days') || authData.employee_code === 'SKI20220627018' || authData.employee_code === 'SKI20220609017' ?
                            <div className="text-center"></div>
                            :
                            <div className="text-center">
                                <EditButton size="small" onEdit={() => editAttendance(row.attendance_id, row.date)} />
                            </div>
                    }
                </Space>
            )
        },
    ];

    const editAttendance = (attendance_id, date) => {
        setModalTitle('Manual Attendance')
        setDefaultValues({
            emp_attendance_id: 0,
            attendance_id: attendance_id,
            attendance_date: date
        })
        setFormModal(true)
    }

    const saveAttendance = (v) => {
        setSaving(true)
        let payload = {
            attendance_id: v.attendance_id,
            source: 'Manual',
            attendance_date: v.attendance_date,
            attendance_time: dayjs(v.time).format('HH:mm:ss'),
            information: v.type,
            remarks: v.remarks,
            latitude: 0,
            longitude: 0,
        }
        SentralModel.action('Area', 'takeAbsenceManual', payload, 0).then(() => {
            setFormModal(false)
            setLoaded(false)
            if (selectedPeriod) {
                getLog(selectedPeriod);
            }
            setSaving(false)
            message.success('Absence has been updated', 2);
        })
    }

    return (
        <div style={{ maxHeight: 600, overflowY: "auto" }}>
            <Row justify="end" className="mb-3">
                <Col>
                    <Space>
                        <DatePicker picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year" />
                        <Select placeholder="Select Period" style={{ width: 200, marginRight: 10 }} onChange={changePeriod}>
                            {
                                periods.map((el, key) => (
                                    <Option key={key} value={el.payroll_period_id}>{el.period}</Option>
                                ))
                            }
                        </Select>
                    </Space>
                </Col>
            </Row>

            {
                loaded ?
                    <DataTable columns={columns} dataSource={dataSource} bordered={false} search={false} size="small" />
                    : <Spinner />
            }
            {
                (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveAttendance(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="emp_attendance_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item name="attendance_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item name="attendance_date" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Type" name="type" rules={[{ required: true }]}>
                            <Select placeholder="Select Type">
                                <Option key={'In'} value={'In'}>Clock In</Option>
                                <Option key={'Out'} value={'Out'}>Clock Out</Option>
                            </Select >
                        </Form.Item >
                        <Form.Item label="Remarks" name="remarks" rules={[{ required: true }]}>
                            <Input placeholder="Remarks" />
                        </Form.Item>
                        <Form.Item label="Time" name="time" rules={[{ required: true }]}>
                            <TimePicker format="HH:mm" style={{ width: '100%' }} placeholder="Select Time" />
                        </Form.Item>
                    </FormModal > :
                    null
            }
        </div >
    );
}

export default Attendance

import React, { useState, useEffect } from 'react';
import { Card, Button, Input, Select, message, Popconfirm } from 'antd';
import { useParams } from 'react-router-dom'
import { CloseCircleOutlined, PlusCircleOutlined, CheckCircleOutlined, SaveOutlined, UndoOutlined, PoweroffOutlined } from '@ant-design/icons';
import { PageTitle, Spinner } from '../../../components'
import { toIdr, extractNumber } from '../../../utils/currencyHandler'
import PaymentHeader from '../daily-payment/PaymentHeader'
import { SentralModel } from '../../../models/SentralModel'
import ConfirmPayment from '../daily-payment/ConfirmPayment'
import PaidDetail from '../daily-payment/PaidDetail'

const { Option } = Select

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const Detail = () => {
    const { employeeCode, periodId, periodDate } = useParams()
    const forceUpdate = useForceUpdate()
    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmPaymentData, setConfirmPaymentData] = useState(null)
    const [allowances, setAllowances] = useState([])
    const [deductions, setDeductions] = useState([])
    const [late, setLate] = useState([])
    const [overtimes, setOvertimes] = useState([])
    const [employee, setEmployee] = useState(null)
    const [components, setComponents] = useState([])
    const [saving, setSaving] = useState(false)
    const [schemaId, setSchemaId] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [rateSalary, setRateSalary] = useState(0)
    const [paymentId, setPaymentId] = useState(null)
    const [ovtData, setOvtData] = useState([])
    const [ovtAmount, setOvtAmount] = useState(0)
    const [rateHour, setRateHour] = useState([])
    const [workingMinute, setWorkingMinute] = useState(0)
    const [regulation, setRegulation] = useState(0)

    const fetchComponents = () => {
        SentralModel.list('PayrollComponent').then((res) => {
            setComponents(res.data)
        })
    }

    const fetchRegulation = () =>{
        SentralModel.get('PayrollRegulation',{},1).then(res=>{
            setRegulation(res.data.tax_value)
        })
    }

    const fetchActivitySummary = async (employeeCode, periodId, periodDate) => {
        await SentralModel.action('Payroll', 'getDailyActivitySummary', { employee_code: employeeCode, period_id: periodId, date: periodDate }, 0).then((res) => {
            setWorkingMinute(res.data?.attendance?.working_hours)
        })
    }

    const fetchWorkingHour = async () => {
        await SentralModel.list('HourCategory', { filter: [["description", "WEEKDAY"]] }).then((res) => { // TODO: WEEKDAY ganti dynamic
            setRateHour(res.data[0]?.rate)
        })
    }

    const fetchPaymentHeader = (employeeCode, periodId, periodDate) => {
        setLoaded(false)
        SentralModel.action('Payroll', 'getEmployeeDailyPaymentHeader', { employee_code: employeeCode, period_id: periodId, period_date: periodDate }, 0).then((res) => {
            setEmployee(res.data)
            setLoaded(true)
        })
    }

    const fetchDailyLate = (employeeCode, periodId, periodDate) => {
        setLoaded(false)
        SentralModel.action('Payroll', 'getDailyActivitySummary', { employee_code: employeeCode, period_id: periodId, date: periodDate }, 0).then((res) => {
            setLate(res.data.attendance)
            setLoaded(true)
        })
    }

    const fetchSchema = (employeeCode, periodId, periodDate) => {
        setLoaded(false)
        SentralModel.action('Payroll', 'getSchemaDailyPayment', { employee_code: employeeCode, period_id: periodId, date: periodDate }, 0).then((res) => {
            setSchemaId(res.data?.schema?.employee_payroll_schema_id)
            setAllowances(res.data?.schema?.allowances)
            setDeductions(res.data?.schema?.deductions)
            setPaymentId(res.data.payment_id)
            setLoaded(true)
        })
    }

    const fetchOvertimeByDate = (employeeCode, periodDate) => {
        setLoaded(false)
        SentralModel.action("Overtime", "getOvertimeByDate", { employee_code: employeeCode, date: periodDate }).then((res) => {
            res.data ? setOvtAmount(res.data.total_value) : setOvtAmount(0)
            setOvtData(res.data)
            setOvertimes(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchComponents()
        fetchOvertimeByDate(employeeCode, periodDate)
        fetchDailyLate(employeeCode, periodId, periodDate)
        fetchSchema(employeeCode, periodId, periodDate)
        fetchPaymentHeader(employeeCode, periodId, periodDate)

    }, [employeeCode, periodId, periodDate]);

    useEffect(() => {
        fetchWorkingHour()
        fetchActivitySummary(employeeCode, periodId, periodDate)
        fetchRegulation()

    }, []);

    useEffect(() => {
        generateSalary()

    }, [rateHour, workingMinute]);

    const addAllowances = () => {
        let x = allowances;
        x.push({
            component_code: null,
            description: null,
            amount: null,
            type: null
        })
        setAllowances(x)
        forceUpdate()
    }

    const addDeductions = () => {
        let x = deductions;
        x.push({
            component_code: null,
            description: null,
            amount: null,
            type: null
        })
        setDeductions(x)
        forceUpdate()
    }

    const generatePayment = () => {
        setSaving(true)

        let l = [];
        if (late.late_penalty != null) {
            l.push({ total: late.late_time, amount: late.late_penalty.fine, description: late.late_penalty.description })
        }

        let ov = [];
        if (ovtData) {
            ov.push({ sub_overtime_id: ovtData.sub_overtime_id, total: ovtData.total_value, description: ovtData.description })
        }

        let all
        if (ovtAmount === 0) {
            all = allowances
        } else {
            all = allowances
            all.push({ amount: ovtAmount, component_code: "OVT", description: "Overtime", type: "OVT" })
        }

        const payload = {
            employee_code: employeeCode,
            period_id: periodId,
            schema_id: schemaId,
            date: periodDate,
            remarks: null,
            paid_leave: null,
            unpaid_leave: null,
            daily_incentive: null,
            lates: l,
            overtimes: ov,
            allowances: all,
            deductions: deductions,
        }

        SentralModel.action('Payroll', 'generateDailyPayment', payload, 0).then(() => {
            message.success("Payment generated")
            setSaving(false)
            fetchSchema(employeeCode, periodId, periodDate)
            fetchPaymentHeader(employeeCode, periodId, periodDate)
        })
    }

    const openConfirmModal = () => {
        setConfirmModal(true)

        SentralModel.action('Payroll', 'dailyFinalDetail', { employee_code: employeeCode, period_id: periodId, date: periodDate }, 0).then((res) => {
            setConfirmPaymentData(res.data)
        })
    }

    const closeConfirmModal = () => {
        setConfirmModal(false)
        setConfirmPaymentData(null)
    }

    const taxSwitcher = () => {
        let payload = {
            tax_value: regulation === "1" ? "0" : "1",
        }

        SentralModel.save('PayrollRegulation', payload, 1).then(() => {
            message.success('regulation updated')
            fetchSchema(employeeCode, periodId, periodDate)
            fetchRegulation()
        })
    }

    const resetPayment = () => {
        let ov = [];
        if (ovtData) {
            ov.push({ sub_overtime_id: ovtData.sub_overtime_id, total: ovtData.total_value, description: ovtData.description })
        }

        let payload = {
            employee_code: employeeCode,
            period_id: periodId,
            date: periodDate,
            overtimes: ov,
        }

        SentralModel.action('Payroll', 'resetDailyPayment', payload, 0).then(() => {
            message.success("Payment Reset")
            setSaving(false)
            fetchSchema(employeeCode, periodId, periodDate)
            fetchPaymentHeader(employeeCode, periodId, periodDate)
        })
    }

    const generateSalary = () => {
        let rateSalary = (parseFloat(rateHour) * (parseFloat(workingMinute) / 60))

        setRateSalary(rateSalary)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Payment" breadcrumbs={[['Payroll Daily Payment', '/payroll/daily-payment'], ['Edit']]}></PageTitle>
                {
                    (loaded && employee) ?
                        <div>
                            <PaymentHeader employee={employee} periodId={periodId} periodDate={periodDate} />
                            {
                                (employee.payment !== 'PAID') ?
                                    <div>
                                        <div className="full-width mt-2 mb-2 text-right">
                                            {
                                                (employee.payment === 'GENERATED') ?
                                                    <Popconfirm title={regulation === "1" ? "Switch payroll into tax free?" : "Switch payroll into tax included?"} okText="Yes" cancelText="No" onConfirm={() => taxSwitcher()}>
                                                        <Button size="middle" type="info" icon={<PoweroffOutlined />}>{regulation === "1" ? "Tax Off" : "Tax On"}</Button> &nbsp;
                                                    </Popconfirm>
                                                    : null
                                            }
                                            {
                                                (employee.payment === 'GENERATED') ?
                                                    <Popconfirm title="Reset generated payment...?" okText="Yes" cancelText="No" onConfirm={() => resetPayment()}>
                                                        <Button size="middle" type="primary" icon={<UndoOutlined />}>Reset</Button> &nbsp;
                                                    </Popconfirm>
                                                    : null
                                            }
                                            <Button disabled={(employee.payment !== 'GENERATED')} size="middle" onClick={() => openConfirmModal()} type="danger" icon={<CheckCircleOutlined />}>Confirm Payment</Button>
                                        </div>

                                        <table className="table-default table-collapse" style={{ width: '100%', marginBottom: 15 }}>
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Allowances</th>
                                                    <th className="text-center">Deductions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: "top" }}>
                                                        <table className="table-noborder" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: '60%' }}>Component</th>
                                                                    <th>Value</th>
                                                                    <th style={{ width: '5%' }}>
                                                                        {
                                                                            (!employee.payment) ?
                                                                                <Button type={allowances ? "primary" : "ghost"} onClick={allowances ? addAllowances : null} icon={<PlusCircleOutlined />} />
                                                                                : null
                                                                        }
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    <tr>
                                                                        <td>
                                                                            <Input readOnly value={'Salary Rate'} />
                                                                        </td>
                                                                        <td>
                                                                            <Input value={toIdr(rateSalary)} readOnly />
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    allowances && allowances.filter((el) => el.component_code !== 'OVT' && el.component_code !== 'GAPOK').map((alw, key) => (
                                                                        <tr key={'allowance_item_' + key}>
                                                                            <td>
                                                                                <Select value={alw.component_code} style={{ width: '100%' }} placeholder="select component" onChange={(v, e) => {
                                                                                    let x = allowances
                                                                                    x[key].component_code = v
                                                                                    x[key].description = e.children
                                                                                    let f = components.find(a => a.component_code === v)
                                                                                    x[key].type = f.type
                                                                                    setAllowances(x)
                                                                                    forceUpdate()
                                                                                }}>
                                                                                    {
                                                                                        components.filter(el => el.category === "ALLOWANCE").map((el, key2) => (
                                                                                            <Option key={key2} value={el.component_code}>{el.description}</Option>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            </td>
                                                                            <td>
                                                                                <Input value={toIdr(alw.amount)} onChange={(v) => {
                                                                                    let x = allowances
                                                                                    x[key].amount = extractNumber(v.target.value)
                                                                                    setAllowances(x)
                                                                                    forceUpdate()
                                                                                }} />
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {
                                                                                    (!employee.payment) ?
                                                                                        <Button type="danger" onClick={() => {
                                                                                            let x = allowances
                                                                                            x.splice(key, 1);
                                                                                            setAllowances(x)
                                                                                            forceUpdate()
                                                                                        }} icon={<CloseCircleOutlined />} />
                                                                                        : null
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                {
                                                                    overtimes ?
                                                                        (overtimes.total_hours > 0 && overtimes.total_value > 0) ?
                                                                            <tr>
                                                                                <td>
                                                                                    <Input readOnly value={'Overtime ' + '(' + overtimes.total_hours + ' Hours' + ')'} />
                                                                                </td>
                                                                                <td>
                                                                                    <Input value={toIdr(overtimes.total_value)} readOnly />
                                                                                </td>
                                                                                <td className="text-center"></td>
                                                                            </tr>
                                                                            : null
                                                                        : null
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td style={{ verticalAlign: "top" }}>
                                                        <table className="table-noborder" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: '60%' }}>Component</th>
                                                                    <th>Value</th>
                                                                    <th style={{ width: '5%' }}>
                                                                        {
                                                                            (!employee.payment) ?
                                                                                <Button type={deductions ? "primary" : "ghost"} onClick={deductions ? addDeductions : null} icon={<PlusCircleOutlined />} />
                                                                                : null
                                                                        }
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    deductions && deductions.filter((el) => el.component_code !== "LOANPAYMENT").map((ded, key) => (
                                                                        <tr key={'deduction_item_' + key}>
                                                                            <td>
                                                                                <Select value={ded.component_code} style={{ width: '100%' }} placeholder="select component" onChange={(v, e) => {
                                                                                    let x = deductions
                                                                                    x[key].component_code = v
                                                                                    x[key].description = e.children
                                                                                    setDeductions(x)
                                                                                    forceUpdate()
                                                                                }}>
                                                                                    {
                                                                                        components.filter(el => el.category === "DEDUCTION").map((el, key2) => (
                                                                                            <Option key={key2} value={el.component_code}>{el.description}</Option>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            </td>
                                                                            <td>
                                                                                <Input value={toIdr(ded.amount)} onChange={(v) => {
                                                                                    let x = deductions
                                                                                    x[key].amount = extractNumber(v.target.value)
                                                                                    setDeductions(x)
                                                                                    forceUpdate()
                                                                                }} />
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {
                                                                                    (!employee.payment) ?
                                                                                        <Button type="danger" onClick={() => {
                                                                                            let x = deductions
                                                                                            x.splice(key, 1);
                                                                                            setDeductions(x)
                                                                                            forceUpdate()
                                                                                        }} icon={<CloseCircleOutlined />} />
                                                                                        : null
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                {
                                                                    (late.late_penalty != null) ?
                                                                        <tr>
                                                                            <td>
                                                                                <Input readOnly value={'Late : ' + late.late_penalty?.description} />
                                                                            </td>
                                                                            <td>
                                                                                <Input value={toIdr(late.late_penalty?.fine)} readOnly />
                                                                            </td>
                                                                            <td className="text-center"></td>
                                                                        </tr>
                                                                        : null
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="full-width text-right mt-2 mb-2">
                                            <Button disabled={employee.payment === 'GENERATED' || employee.payment === 'PAID'} loading={saving} type="primary" onClick={generatePayment} icon={<SaveOutlined />} size="large">Generate Payment</Button>
                                        </div>
                                    </div>
                                    : <PaidDetail data={{ allowances: allowances, deductions: deductions }} />
                            }
                        </div>
                        :
                        <Spinner />
                }
                <br />
            </Card>

            <ConfirmPayment data={confirmPaymentData} paymentId={paymentId} employee={employee} setModal={(v) => closeConfirmModal(v)} showModal={confirmModal} />
        </div>
    );
}

export default Detail;

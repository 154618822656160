import { useState, useEffect } from 'react'
import { Card, Col, Form, Row, Space, Typography, Select, Input, message } from 'antd'
import { AddButton, DataTable, DeleteButton, EditButton, FormModal, PageTitle, Spinner } from '../../../../components'
import { SentralModel } from '../../../../models/SentralModel'
import { extractNumber, toIdr } from '../../../../utils/currencyHandler'

const { Text } = Typography

const Index = () => {
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(false)
    const [setupData, setSetupData] = useState([])
    const [empStatus, setEmpStatus] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')

    const fetchSetupLoanLimit = () => {
        setLoaded(false)
        SentralModel.action("Loan", "getSetupLoan", null, null).then((res) => {
            setSetupData(res.data)
            setLoaded(true)
        })
    }

    const fetchEmployeeStatus = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'ES'], ['detail_code', ['ESMG', 'ESPR', 'ESKN', 'ESPB']]], orderBy: 'order_key', order: 'asc' })
            .then(res => {
                let arr = []
                res.data.forEach((el) => {
                    arr.push({
                        value: el.detail_code,
                        label: el.description
                    })
                })

                setEmpStatus(arr)
            })
    }

    useEffect(() => {
        fetchSetupLoanLimit()
        fetchEmployeeStatus()

    }, [])

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
        },
        {
            title: 'Employee Status',
            key: 'employee_status',
            render: (row) => (
                row?.description?.description ?? "-"
            )
        },
        {
            title: 'Loan Rate',
            dataIndex: 'loan_rate',
            key: 'loan_rate',
            render: (row) => (
                `${row}%`
            )
        },
        {
            title: 'Total Loan Limit',
            dataIndex: 'total_loan_limit',
            key: 'total_loan_limit',
            render: (row) => (
                toIdr(row)
            )
        },
        {
            title: 'Action',
            align: 'center',
            render: (row) => (
                <Space>
                    <EditButton onEdit={() => editSetupLoan(row)} />
                    <DeleteButton onConfirm={() => deleteSetup(row.loan_limit_id)} />
                </Space>
            )
        }
    ];

    const addSetupLoan = () => {
        setShowModal(true)
        setModalTitle('Add Setup Loan')
        form.setFieldsValue({
            loan_limit_id: 0,
            employee_status: null,
            loan_rate: null,
            total_loan_limit: null,
        })
    }

    const editSetupLoan = (v) => {
        setShowModal(true)
        setModalTitle('Edit Setup Loan')
        form.setFieldsValue({
            loan_limit_id: v.loan_limit_id,
            employee_status: v.employee_status,
            loan_rate: v.loan_rate,
            total_loan_limit: toIdr(v.total_loan_limit),
        })
    }

    const saveSetup = (v) => {
        if (modalTitle === 'Add Setup Loan' && setupData.find((el) => el.employee_status === v.employee_status)) {
            message.warning("Employee Status Exists")
            return
        }

        setLoaded(true)

        let payload = {
            loan_limit_id: v.loan_limit_id,
            employee_status: v.employee_status,
            loan_rate: v.loan_rate,
            total_loan_limit: extractNumber(v.total_loan_limit),
        }
        // console.log(payload)

        SentralModel.action("Loan", "setupLoan", payload, v.loan_limit_id === 0 ? 0 : v.loan_limit_id).then((res) => {
            if (v.loan_limit_id === 0) {
                message.success("Success Add Data")
            } else {
                message.success("Success Edit Data")
            }
            fetchSetupLoanLimit()
            setShowModal(false)
            setLoaded(false)
            form.resetFields()
        }).catch((err) => {
            if (v.loan_limit_id === 0) {
                message.warning("Failed Add Data")
            } else {
                message.warning("Failed Edit Data")
            }
        })
    }

    const deleteSetup = (v) => {
        SentralModel.action("Loan", "deleteSetupLoan", null, v).then((res) => {
            message.success("Success Delete Data")
            fetchSetupLoanLimit()
        }).catch((err) => {
            message.warning("Failed Delete Data")
        })
    }

    const changeMaxLoan = (v) => {
        let number = extractNumber(v.target.value)
        form.setFieldsValue({ total_loan_limit: toIdr(number) })
    }

    return (
        <Card className="content-container">
            <PageTitle title="Setup Loan" breadcrumbs={[['Setup Loan']]}></PageTitle>
            <Card title={
                <div className="full-width">
                    <Row justify='space-between'>
                        <Col>
                            <Text strong>Setup Loan</Text>
                        </Col>
                        <Col>
                            <AddButton title="Add Setup Loan" onAdd={addSetupLoan} />
                        </Col>
                    </Row>
                </div>
            }>
                {
                    loaded ?
                        <DataTable size="small" columns={columns} dataSource={setupData} bordered />
                        : <Spinner />
                }
            </Card>

            {
                showModal ?
                    <FormModal showModal={showModal} onCloseModal={() => setShowModal(false)} title={modalTitle} width={600} form={form} submitForm={saveSetup}>
                        <Form.Item name="loan_limit_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Employee Status" name="employee_status" rules={[{ required: true }]}>
                            <Select
                                options={empStatus}
                                placeholder="Select Employee Status"
                            />
                        </Form.Item>
                        <Form.Item label="Loan Rate" name="loan_rate" rules={[{ required: true }]}>
                            <Input min={0} max={100} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item label="Total Loan Limit" name="total_loan_limit" onChange={changeMaxLoan} rules={[{ required: true }]}>
                            <Input min={0} style={{ width: '100%' }} />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </Card>
    )
}

export default Index

import React from 'react';
import { Popconfirm, Button } from 'antd'
import { Link } from 'react-router-dom'
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, DiffOutlined, ZoomInOutlined, SaveOutlined, CheckCircleOutlined, ArrowLeftOutlined, EyeOutlined, FilePdfOutlined, SyncOutlined } from '@ant-design/icons'

export const DeleteButton = (props) => {
    const { size = "small", title = "Delete this data...?", disabled = false } = props
    return (
        <Popconfirm title={title} okText="Yes" cancelText="No" onConfirm={() => props.onConfirm()}>
            {
                disabled ?
                    <Button disabled type="link" size={size} shape="circle" icon={<DeleteOutlined />} />
                    :
                    <Button type="link" size={size} shape="circle" icon={<DeleteOutlined />} />
            }
        </Popconfirm>
    );
}

export const DeleteButton2 = (props) => {
    const { title = 'Delete Data', popupTitle = "Delete this data...?", right = false, size = "middle" } = props
    return (
        <Popconfirm title={popupTitle} okText="Yes" cancelText="No" onConfirm={() => props.onConfirm()}>
            <Button size={size} type="primary" icon={<DeleteOutlined />} style={(right) ? { float: 'right' } : null}>
                {title}
            </Button>
        </Popconfirm>
    );
}

export const EditButton = (props) => {
    const { link = false, to = "/", size = "small", disabled = false, onEdit } = props
    if (link) {
        return (
            <Link to={to}>
                <Button type="link" icon={<EditOutlined />} onClick={onEdit} />
            </Link>
        )
    }
    return (
        disabled ?
            <Button type="link" size={size} shape="circle" disabled onClick={() => props.onEdit()} icon={<EditOutlined />} />
            :
            <Button type="link" size={size} shape="circle" onClick={() => props.onEdit()} icon={<EditOutlined />} />
    );
}

export const EditButton2 = (props) => {
    const { title = 'Edit Data', right = false, size = "middle" } = props
    return (
        <Button size={size} type="primary" onClick={() => props.onEdit()} icon={<EditOutlined />} style={(right) ? { float: 'right' } : null}>
            {title}
        </Button>
    );
}

export const TakeButton = (props) => {
    const { link = false, to = "/", size = "small", disabled = false } = props
    if (link) {
        return (
            <Link to={to}>
                <Button type="link" icon={<DiffOutlined />} />
            </Link>
        )
    }
    return (
        disabled ?
            <Button type="link" size={size} shape="circle" disabled onClick={() => props.onTake()} icon={<DiffOutlined />} />
            :
            <Button type="link" size={size} shape="circle" onClick={() => props.onTake()} icon={<DiffOutlined />} />
    );
}

export const ShowButton = (props) => {
    const { link = false, to = "/", size = "small" } = props
    if (link) {
        return (
            <Link to={to}>
                <Button type="link" icon={<ZoomInOutlined />} />
            </Link>
        )
    }
    return (
        <Button type="link" size={size} shape="circle" onClick={() => props.onShow()} icon={<ZoomInOutlined />} />
    );
}

export const NewButton = (props) => {
    const { to = null, title = 'Add Data', right = false, disabled = false } = props
    return (
        <Link to={to} style={(right) ? { float: 'right' } : null}>
            <Button disabled={disabled} type="primary" icon={<PlusCircleOutlined />}> {title}</Button>
        </Link>
    );
}

export const BackButton = (props) => {
    const { to = null, title = 'Back', right = false } = props
    return (
        <Link to={to} style={(right) ? { float: 'right' } : null}>
            <Button type="danger" icon={<ArrowLeftOutlined />}> {title}</Button>
        </Link>
    );
}

export const AddButton = (props) => {
    const { title = 'Add Data', right = false, size = "middle", disabled } = props
    return (
        <Button size={size} disabled={disabled} type="primary" onClick={() => props.onAdd()} icon={<PlusCircleOutlined />} style={(right) ? { float: 'right' } : null}>
            {title}
        </Button>
    );
}

export const DeletationButton = (props) => {
    const { title = 'Delete this data...?', size = "middle", disabled = false } = props
    return (
        <Popconfirm title={title} okText="Yes" cancelText="No" onConfirm={() => props.onConfirm()}>
            {
                disabled ?
                    <Button size={size} disabled={disabled} icon={<DeleteOutlined />} style={{ background: 'red', color: 'white' }}>
                        {title}
                    </Button>
                    :
                    <Button size={size} disabled={disabled} icon={<DeleteOutlined />} style={{ background: 'red', color: 'white' }}>
                        {title}
                    </Button>
            }
        </Popconfirm>
    );
}

export const SelectButton = (props) => {
    const { title = 'Select Item', right = false, size = "middle" } = props
    return (
        <Button size={size} type="primary" onClick={() => props.onSelect()} icon={<CheckCircleOutlined />} style={(right) ? { float: 'right' } : null}>
            {title}
        </Button>
    );
}

export const ViewButton = (props) => {
    const { to = null, size = "small" } = props
    return (
        <Link to={to}>
            <Button type="link" shape="circle" icon={<EyeOutlined />} size={size}></Button>
        </Link>
    );
}

export const ViewButton2 = (props) => {
    const { size = "small", color = null, icon = null } = props
    return (
        <Button icon={icon} onClick={() => props.onView()} type="primary" style={{ background: color, border: "none" }} size={size}>
            {props.children}
        </Button>
    );
}

export const DetailButton = (props) => {
    const { to = null, title = 'View Detail', right = false } = props
    return (
        <Link to={to} style={(right) ? { float: 'right' } : null}>
            <Button type="primary" icon={<EyeOutlined />}> {title}</Button>
        </Link>
    );
}

export const SubmitButton = (props) => {
    const { title = 'save', size = "middle", right = false, loading = false } = props
    return (
        <Button loading={loading} type="primary" htmlType="submit" size={size} style={(right) ? { float: 'right' } : null} icon={<SaveOutlined />} >{title}</Button>
    )
}

export const PrintPdfButton = (props) => {
    const { title = 'Print', right = false, size = "middle" } = props
    return (
        <Button size={size} type="primary" onClick={() => props.onPrint()} icon={<FilePdfOutlined />} style={(right) ? { float: 'right' } : null}>
            {title}
        </Button>
    );
}

export const DownloadPdfButton = (props) => {
    const { title = 'Download', right = false, size = "middle", disabled = false } = props
    return (
        disabled ?
            <Button size={size} type="primary" disabled onClick={() => props.onPrint()} icon={<FilePdfOutlined />} style={(right) ? { float: 'right' } : null}>
                {title}
            </Button>
            :
            <Button size={size} type="primary" onClick={() => props.onPrint()} icon={<FilePdfOutlined />} style={(right) ? { float: 'right' } : null}>
                {title}
            </Button>
    );
}


export const SyncButton = (props) => {
    const { title = 'sync', size = "middle", right = false, loading = false } = props
    return (
        <Button loading={loading} type="primary" size={size} style={(right) ? { float: 'right' } : null} onClick={() => props.onClick()} icon={<SyncOutlined />} >{title}</Button>
    )
}

export const CustomButton = (props) => {
    const { link = false, to = "/", size = "small", icon = <EditOutlined /> } = props
    if (link) {
        return (
            <Link to={to}>
                <Button type="link" icon={icon} />
            </Link>
        )
    }
    return (
        <Button type="link" size={size} shape="circle" onClick={() => props.onEdit()} icon={icon} />
    );
}


export const ActiveButton = (props) => {
    const { link = false, to = "/", size = "small", disabled = false, onActive } = props
    if (link) {
        return (
            <Link to={to}>
                <Button type="link" icon={<CheckCircleOutlined />} onClick={onActive} />
            </Link>
        )
    }
    return (
        disabled ?
            <Button type="link" size={size} shape="circle" disabled onClick={() => props.onActive()} icon={<CheckCircleOutlined />} />
            :
            <Button type="link" size={size} shape="circle" onClick={() => props.onActive()} icon={<CheckCircleOutlined />} />
    );
}
import React from 'react';
import {Tabs, Space, Button} from 'antd'
import {LoginOutlined} from '@ant-design/icons'
import {FieldTimeOutlined, CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import {DataTable, ShowButton} from '../../../components'
import {toFullDate} from '../../../utils/dateHandler'
import {Link} from 'react-router-dom'

const {TabPane} = Tabs

const OvertimeRequest = (props) => {
    // const showLogs = (row) => {
    //     props.showRequestLog(row)
    // }

    const overtimes = [
        ...props.data.map((el) => {
            return {
                id: el.sub_overtime_id,
                date: toFullDate(el.from_date),
                type: el.overtime_type.description,
                from_time: el.from_time.substring(0,5),
                to_time: el.to_time.substring(0,5),
                necessity: el.description,
                status: el.status,
                status_realization: el.status_realization,
                approver: el.approver,
                created_at: el.created_at
            }
        })
    ];

    const overtimeColumn = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        },{
            title: 'Time',
            align:'center',
            render: (row) => (
                <span>{row.from_time} - {row.to_time}</span>
            )
        },{
            title: 'Overtime Type',
            dataIndex:'type',
            key:'type'
        },{
            title: 'Necessity',
            dataIndex: 'necessity',
            key: 'necessity',
        },{
            title: 'Action',
            align:'center',
            render : (row) => (
                <Space>
                    <ShowButton link to={'/overtime/' + row.id} />
                    {/* <ShowButton onShow={() => showLogs(row)}/> */}
                    {/* {
                        (row.status==='APPROVED') ? 
                            <Link to={`/overtime/create-realization/`+row.id}>
                                <Button type="link" icon={<LoginOutlined />}/>
                            </Link>
                        : null
                    } */}
                </Space>
            )
        }
    ];

    return (
        <div>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={overtimeColumn} dataSource={overtimes.filter(el => el.status==="WAITING" && !el.status_realization)} bordered/>
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                    {/* <DataTable size="small" columns={overtimeColumn} dataSource={overtimes.filter(el => el.status==="APPROVED" && !el.status_realization)} bordered/> */}
                    <DataTable size="small" columns={overtimeColumn} dataSource={overtimes.filter(el => el.status==="APPROVED")} bordered/>
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={overtimeColumn} dataSource={overtimes.filter(el => el.status==="REJECTED" && !el.status_realization)} bordered/>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default OvertimeRequest;

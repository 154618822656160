import React, { useState } from 'react';
import { Descriptions, Typography, Tag, Modal, Button } from 'antd';
import { SentralModel } from '../../../models/SentralModel';
import { Spinner } from '../../../components';
import moment from 'moment';

const { Text } = Typography

const PaymentHeader = (props) => {
    const { employee, periodId, periodDate } = props
    const [activityModal, setActivityModal] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [activity, setActivity] = useState(null)

    const showActivityDetail = () => {
        setLoaded(false)
        setActivityModal(true)
        SentralModel.action('Payroll', 'getDailyActivitySummary', { employee_code: employee.employee_code, period_id: periodId, date: periodDate }, 0).then((res) => {
            setActivity(res.data)
            setLoaded(true)
        })
    }

    return (
        <div>
            <Descriptions className="mb-3" size="middle" column={5} bordered layout="vertical">
                <Descriptions.Item label={<div className="text-center"><Text strong>Employee Name</Text></div>}>
                    <div className="text-center">{employee.name}</div>
                </Descriptions.Item>
                <Descriptions.Item label={<div className="text-center"><Text strong>Position / Division</Text></div>}>
                    <div className="text-center">{employee.division_name} / {employee.position_name}</div>
                </Descriptions.Item>
                <Descriptions.Item label={<div className="text-center"><Text strong>Date</Text></div>}>
                    <div className="text-center">{moment(employee.date).format('dddd, DD MMMM yyyy')}</div>
                </Descriptions.Item>
                <Descriptions.Item label={<div className="text-center"><Text strong>Payment Status</Text></div>}>
                    <div className="text-center">
                        {
                            (employee.payment === 'GENERATED') ?
                                <Tag color="blue">GENERATED</Tag> : null
                        }
                        {
                            (employee.payment === 'PAID') ?
                                <Tag color="green">PAID</Tag> : null
                        }
                        {
                            (!employee.payment) ?
                                <Tag color="red">UNPAID</Tag> : null
                        }
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label={<div className="text-center"><Text strong>Activities</Text></div>}>
                    <div className="text-center">
                        <Tag color="blue" style={{ cursor: 'pointer' }} onClick={showActivityDetail}>SHOW ACTIVITIES</Tag>
                    </div>
                </Descriptions.Item>
            </Descriptions>
            <Modal
                title="Activity Detail"
                style={{ top: 20 }}
                visible={activityModal}
                width={1000}
                onCancel={() => setActivityModal(false)}
                footer={null}>
                <Descriptions className="mb-3" size="middle" column={3} bordered layout="vertical">
                    <Descriptions.Item label={<div className="text-center"><Text strong>Employee Name</Text></div>}>
                        <div className="text-center">{employee.name}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label={<div className="text-center"><Text strong>Division / Position</Text></div>}>
                        <div className="text-center">{employee.division_name} / {employee.position_name}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label={<div className="text-center"><Text strong>Date</Text></div>}>
                        <div className="text-center">{moment(employee.date).format('DD MMMM yyyy')}</div>
                    </Descriptions.Item>
                </Descriptions>
                {
                    (loaded) ?
                        <div>
                            <Text strong>Working Hours</Text>
                            <Descriptions size="small" column={6} bordered layout="vertical">
                                <Descriptions.Item className="text-center" label={<Text strong>Working Hours</Text>}>
                                    {Math.round(activity.attendance?.working_hours)} minutes
                                </Descriptions.Item>
                                <Descriptions.Item className="text-center" label={<Text strong>Late</Text>}>
                                    {Math.round(activity.attendance?.late_time)} minutes
                                </Descriptions.Item>
                                <Descriptions.Item className="text-center" label={<Text strong>Overtime</Text>}>
                                    {activity.attendance?.isOvertime?.total_hours != null ? (activity.attendance?.isOvertime?.total_hours * 60) : 0} minutes
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                        : <Spinner />
                }

                <div className="full-width mt-2 text-right">
                    <Button size="middle" onClick={() => setActivityModal(false)}>Close</Button>
                </div>
            </Modal>
        </div>
    );
}

export default PaymentHeader;

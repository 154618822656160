import React, { useState, useEffect } from 'react';
import { Typography, Tabs, Form, Input, DatePicker, message } from 'antd'
import { EditButton, FormModal, DataTable, AddButton, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel';
import { toFullDate } from '../../../utils/dateHandler'
import moment from 'moment'

const { Title } = Typography
const { TabPane } = Tabs
const { RangePicker } = DatePicker
const Period = () => {
    const [periodForm] = Form.useForm()
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [periods, setPeriods] = useState([])
    const [loaded, setLoaded] = useState(false)

    const fetchPeriods = () => {
        setLoaded(false)
        SentralModel.list('PayrollPeriod').then((res) => {
            setPeriods(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchPeriods()
    }, []);

    const editPeriod = (row) => {
        setFormModal(true)
        periodForm.setFieldsValue({
            period_id: row.payroll_period_id,
            year: row.year ? moment(row.year) : null,
            period_name: row.period,
            date_range: [moment(row.from_date), moment(row.to_date)]
        })
    }

    const addPeriod = () => {
        setFormModal(true)
        periodForm.setFieldsValue({
            period_id: 0,
            period_name: '',
            year: '',
            date_range: null
        })
    }

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
    },
    {
        title: 'Period Name',
        dataIndex: 'period',
        key: 'period',
    },
    {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
    },
    {
        title: 'Period Date',
        children: [
            {
                title: 'From',
                dataIndex: 'from_date',
                align: 'center',
                key: 'from_date',
                render: (text) => (
                    toFullDate(text)
                )
            }, {
                title: 'To',
                dataIndex: 'to_date',
                align: 'center',
                key: 'to_date',
                render: (text) => (
                    toFullDate(text)
                )
            }
        ]
    },
    {
        title: 'Action',
        align: 'center',
        render: (row) => (
            <EditButton onEdit={() => editPeriod(row)} />
        )
    },
    ];
    const incomingData = () => {
        let prd = [];
        periods.forEach(el => {
            if (moment().isBefore(moment(el.to_date))) {
                prd.push(el)
            }
        })
        return prd
    }
    const pastData = () => {
        let prd = [];
        periods.forEach(el => {
            if (!moment().isBefore(moment(el.to_date))) {
                prd.push(el)
            }
        })
        return prd
    }

    const savePeriod = (v) => {
        setSaving(true)
        let data = {
            period: v.period_name,
            year: moment(v.year).format("YYYY"),
            from_date: moment(v.date_range[0]).format("YYYY-MM-DD"),
            to_date: moment(v.date_range[1]).format("YYYY-MM-DD"),
        }
        SentralModel.save('PayrollPeriod', data, v.period_id).then(() => {
            fetchPeriods()
            message.success('Period saved');
            setSaving(false)
            setFormModal(false)
        })
    }
    return (
        <div>
            <Title level={4}>Salary Period</Title>
            <Tabs defaultActiveKey="incoming" type="card" tabBarExtraContent={
                <AddButton onAdd={addPeriod} title="Add Period" />
            }>

                <TabPane tab="Incoming" key="incoming">
                    {
                        loaded ?
                            <DataTable bordered size="small" columns={columns} dataSource={incomingData()} />
                            : <Spinner />
                    }
                </TabPane>
                <TabPane tab="Past" key="past">
                    {
                        loaded ?
                            <DataTable bordered size="small" columns={columns} dataSource={pastData()} />
                            : <Spinner />
                    }
                </TabPane>
            </Tabs>
            <FormModal form={periodForm} title="Add New Period"
                showModal={formModal}
                onCloseModal={() => setFormModal(false)}
                submitForm={(v) => savePeriod(v)}
                saving={saving}>
                <Form.Item name="period_id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="period_name" label="Period Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="year" label="Year">
                    <DatePicker picker="year" placeholder="" style={{ width: '100%' }} rules={[{ required: true }]} />
                </Form.Item>
                <Form.Item name="date_range" label="Date Range" rules={[{ required: true }]}>
                    <RangePicker />
                </Form.Item>
            </FormModal>
        </div>
    );
}

export default Period;

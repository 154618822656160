// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-hover-container {
  position: relative;
  width: 100%;
}

.image-hover {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .3s ease;
  backface-visibility: hidden;
}

.image-hover-middle {
  transition: .3s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.image-hover-container:hover .image-hover {
  opacity: 0.3;
}

.image-hover-container:hover .image-hover-middle {
  opacity: 1;
}

.image-hover-text {
  font-size: 16px;
  padding: 16px 32px;
}`, "",{"version":3,"sources":["webpack://./src/assets/image-hover.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,cAAc;EACd,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,2BAA2B;AAC7B;;AAEA;EACE,oBAAoB;EACpB,UAAU;EACV,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,oCAAoC;EACpC,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".image-hover-container {\n  position: relative;\n  width: 100%;\n}\n\n.image-hover {\n  opacity: 1;\n  display: block;\n  width: 100%;\n  height: auto;\n  transition: .3s ease;\n  backface-visibility: hidden;\n}\n\n.image-hover-middle {\n  transition: .3s ease;\n  opacity: 0;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  -ms-transform: translate(-50%, -50%);\n  text-align: center;\n}\n\n.image-hover-container:hover .image-hover {\n  opacity: 0.3;\n}\n\n.image-hover-container:hover .image-hover-middle {\n  opacity: 1;\n}\n\n.image-hover-text {\n  font-size: 16px;\n  padding: 16px 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

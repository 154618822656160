import { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom'
import { Layout } from 'antd'
import Dashboard from '../views/dashboard/Index'
import Header from './Header'
import Footer from './Footer'
import Sider from './Sider'

import Division from '../views/organization/division/Index'
import DivisionCustom from '../views/organization/division/Custom'
import Position from '../views/organization/position/Index'
import ImportantDate from '../views/organization/importantDate/Index'
import ApprovalSchema from '../views/organization/approvalSchema/Index'
import ApprovalSchemaDetail from '../views/organization/approvalSchema/Detail'
import ApprovalCustom from '../views/organization/approvalSchema/Custom'
import ApprovalCustomDetail from '../views/organization/approvalSchema/CustomDetail'
import Budget from '../views/organization/budget/Index'
import BudgetRealization from '../views/organization/budget/Realization'
import Employee from '../views/organization/employee/Index'
import CompanyInformation from '../views/organization/company/Detail'
import Branch from '../views/organization/branch/Index'
import Announcement from '../views/organization/announcement/Index'
import AnnouncementDetail from '../views/organization/announcement/Detail'
import Kpi from '../views/organization/kpi/Index'
import Mom from '../views/dashboard/tabContents/Mom'

import DashboardAttendance from '../views/report/dashboard/Attendance'
import DashboardEmployee from '../views/report/dashboard/Employee'
import DashboardLate from '../views/report/dashboard/Late'
import DashboardAdmin from '../views/report/dashboard/Admin'
import DashboardHrEmployee from '../views/report/dashboardHr/Employee'
import DashboardHrAttendance from '../views/report/dashboardHr/Attendance'
import DashboardHrAdmin from '../views/report/dashboardHr/Admin'

import ReportEmployee from '../views/report/employee/Index'
import ReportAttendance from '../views/report/activity/Attendance'
import ReportDailyJob from '../views/report/activity/Job'
import ReportPayroll from '../views/report/activity/Payroll'
import ReportSalary from '../views/report/activity/Salary'
import ReportPayrollOperational from '../views/report/activity/PayrollOperational'
import ReportBudget from '../views/report/information/Budget'
import ReportLeave from '../views/report/activity/Leave'
import ReportOvertime from '../views/report/activity/Overtime'
import ReportReimbursement from '../views/report/submission/Reimbursement'
import ReportEntertainment from '../views/report/submission/Entertainment'
import ReportMedical from '../views/report/submission/Medical'
import ReportBusinessTrip from '../views/report/submission/BusinessTrip'
import ReportStationery from '../views/report/submission/Stationery'
import ReportPurchaseRequest from '../views/report/submission/PurchaseRequest'
import ReportMedicalHistory from '../views/report/medical-history/Index'
// import ReportVote from '../views/report/activity/Vote'
import VoteResult from '../views/report/activity/voteResult/Index';
import VoteResultDetail from '../views/report/activity/voteResult/Detail'
import ReportTraining from '../views/report/submission/training/Index';
import ReportTrainingDetail from '../views/report/submission/training/Detail';
import ReportKPI from '../views/report/kpi/KPI'
import ReportComponentKPI from '../views/report/kpi/Component'
import ReportTrainingHours from '../views/report/activity/TrainingHours'
import ReportComission from '../views/report/submission/Comission'

import ReportVacancy from '../views/report/vacancy/Index';
import ReportVacancyDetail from '../views/report/vacancy/Detail';
import ReportApplicantDetail from '../views/report/vacancy/ApplicantDetail';
import AnnualLeaveReport from '../views/report/annual-report';
import AnnualLeaveReportDetail from '../views/report/annual-report/detail';

import Leave from '../views/submission/leave/Index'
import LeaveCreate from '../views/submission/leave/Create'
import LeaveDetail from '../views/submission/leave/Detail'
import Overtime from '../views/submission/overtime/Index'
import OvertimeCreate from '../views/submission/overtime/Create'
import OvertimeDetail from '../views/submission/overtime/Detail'
import Reimbursement from '../views/submission/fund/reimbursement/Index'
import ReimbursementCreate from '../views/submission/fund/reimbursement/Create'
import ReimbursementDetail from '../views/submission/fund/reimbursement/Detail'
import Entertainment from '../views/submission/fund/entertainment/Index'
import EntertainmentCreate from '../views/submission/fund/entertainment/Create'
import EntertainmentDetail from '../views/submission/fund/entertainment/Detail'
import Medical from '../views/submission/fund/medical/Index'
import MedicalCreate from '../views/submission/fund/medical/Create'
import MedicalDetail from '../views/submission/fund/medical/Detail'
import BusinessTrip from '../views/submission/businessTrip/Index'
import BusinessTripCreate from '../views/submission/businessTrip/Create'
import BusinessTripDetail from '../views/submission/businessTrip/Detail'
import OfficeStationary from '../views/submission/stationery/Index'
import OfficeStationaryCreate from '../views/submission/stationery/Create'
import OfficeStationaryDetail from '../views/submission/stationery/Detail'
import PurchaseRequest from '../views/submission/purchaseRequest/Index'
import PurchaseRequestCreate from '../views/submission/purchaseRequest/Create'
import PurchaseRequestDetail from '../views/submission/purchaseRequest/Detail'
import Training from '../views/management/training/Index'
import TrainingCreate from '../views/management/training/Create'
import TrainingEdit from '../views/management/training/Edit'

import Project from '../views/submission/softdev/Index'
import ObjectiveDetail from '../views/submission/softdev/objective/Detail'

import SubmissionList from '../views/submissionList/Index'
import SubmissionListBusinessTrip from '../views/submissionList/businessTrip/Detail'
import SubmissionListLeave from '../views/submissionList/leave/request/Detail'
import SubmissionListOvertime from '../views/submissionList/overtime/request/Detail'
import SubmissionListMedical from '../views/submissionList/medical/Detail'
import SubmissionListReimbursement from '../views/submissionList/reimbursement/Detail'
import SubmissionListPurchaseRequest from '../views/submissionList/purchaseRequest/Detail'
import SubmissionListEntertainment from '../views/submissionList/entertainment/Detail'
import SubmissionListStationery from '../views/submissionList/officeStationery/Detail'
import SubmissionListTraining from '../views/submissionList/training/Detail'
import SubmissionListAttendance from '../views/submissionList/attendance/Detail'
import SubmissionListResign from '../views/submissionList/resign/Detail'
import SubmissionListRecruitment from '../views/submissionList/recruitment/Detail'
import SubmissionListVoucher from '../views/submissionList/voucher/Detail'
import SubmissionListLoan from '../views/submissionList/loan/Detail'
import SubmissionListLoanPayment from '../views/submissionList/loanPayment/Detail'

import SubmissionTraining from '../views/submission/training/Index'
import SubmissionTrainingDetail from '../views/submission/training/Detail'
import SubmissionTrainingEvaluation from '../views/submission/training/Evaluation'
import SubmissionTrainingExternalForm from '../views/submission/training/ExternalForm'
import SubmissionTrainingExternalDetail from '../views/submission/training/ExternalDetail'
import SubmissionTrainingDone from '../views/submission/training/Done'
import RewardPunishment from '../views/submission/rewardPunishement/Index'

import Loan from '../views/submission/loan/Index'
import LoanDetail from '../views/submission/loan/Detail'
import CreateLoan from '../views/submission/loan/Create'
import SetupLoan from '../views/submission/loan/setup/Index'

import CompanyRegulation from '../views/documentation/regulation/Index'
import CompanyRegulationDetail from '../views/documentation/regulation/Detail'
import Sop from '../views/documentation/sop/Index'
import SopDetail from '../views/documentation/sop/Detail'
import ExitClearance from '../views/documentation/exitClearance/Index'
import ExitClearanceDetail from '../views/documentation/exitClearance/Detail'
import ClassificationISO from '../views/documentation/sop/classifications/Index'
import Legalitas from '../views/documentation/legalitas';
import LegalitasDetail from '../views/documentation/legalitas/Detail';
import Iso from '../views/documentation/iso/Index';

import Payroll from '../views/payroll/Monthly'
import PayrollPayment from '../views/payroll/payment/Index'
import PayrollPaymentDetail from '../views/payroll/payment/Detail'
import PayrollSetup from '../views/payroll/setup/Index'
import PayrollSchema from '../views/payroll/schema/Index'
import PayrollSchemaDetail from '../views/payroll/schema/Detail'

import PayrollRateHourly from '../views/payroll/setupRateHourly/Index'
import PayrollPaymentDaily from '../views/payroll/daily-payment/Index'
import PayrollPaymentDailyDetail from '../views/payroll/daily-payment/Detail'

// import PayrollSpt from '../views/payroll/spt/Index'
import PayrollSptDetail from '../views/payroll/spt/Detail'
import PayrollSync from '../views/payroll/sync/Index'

import OvertimeSchema from '../views/payroll/overtimeSchema/Index'

import ValidityHour from '../views/management/validityHour/Index'
import Vacancy from '../views/program/vacancy/Index'
import VacancyDetail from '../views/program/vacancy/Detail'
import Applicant from '../views/program/recruitment/Index'
import Appraisal from '../views/management/appraisal/Index'
import AppraisalComponent from '../views/management/appraisal/Component'
import AppraisalSetItemKpi from '../views/management/appraisal/SetItem'
import AppraisalSetPeriod from '../views/management/appraisal/Period'
import AppraisalSetEvaluator from '../views/management/appraisal/Evaluator'
import AppraisalForm from '../views/management/appraisal/AppraisalForm'
import AppraisalDetail from '../views/management/appraisal/Detail'
import AppraisalSetup from '../views/management/appraisal/Akumulasi'
import AppraisalRanking from '../views/management/appraisal/Ranking'
import AppraisalRankingEvaluator from '../views/management/appraisal/RankingEvaluator'
import MasterAppraisalItem from '../views/management/appraisal/SetItemMaster'
import Shift from '../views/management/shift/Index'
import Reward from '../views/management/reward/Index'
import RewardCreate from '../views/management/reward/Create'
import RewardEdit from '../views/management/reward/Edit'
import Vote from '../views/management/vote/Index'
import VoteDetail from '../views/management/vote/Detail'
import Voting from '../views/management/vote/Voting'

import EmployeeStatus from '../views/management/employeeStatus/Index'
import LeaveRights from '../views/management/leaveRights/Index'
import MedicalAllowance from '../views/management/medicalAllowance/Index'

import ProfileApplicant from '../views/program/applicant/Index'

import TestMultipleChoice from '../views/management/test/multipleChoice/Index'
import TestMultipleChoiceCreate from '../views/management/test/multipleChoice/Create'
import TestEssay from '../views/management/test/essay/Index'
import TestEssayCreate from '../views/management/test/essay/Create'

import User from '../views/setting/user/Index'
import Bank from '../views/setting/bank/Index'
import Coa from '../views/setting/coa/Index'
import Country from '../views/setting/country/Index'
import Province from '../views/setting/province/Index'
import City from '../views/setting/city/Index'
import Company from '../views/setting/company/Index'
import Email from '../views/setting/email/Index'
import General from '../views/setting/general/Index'
import GeneralDetail from '../views/setting/general/Detail'
import Instruction from '../views/setting/instruction/Index'
import InstructionForm from '../views/setting/instruction/Form'
import ItemStationery from '../views/setting/itemStationery/Index'
import Area from '../views/setting/area/Index'
import CustomAttendanceArea from '../views/setting/area/CustomAttendanceArea'
import DivisionAttendanceArea from '../views/setting/area/DivisionAttendanceArea'
import Version from '../views/setting/version/Index'
import VersionDetail from '../views/setting/version/Detail'
import Application from '../views/setting/applications/Index'
import UserAccess from '../views/setting/applications/Detail'

import StationeryCategory from '../views/generalAffair/stationery/category/Index'
import StationeryGoodsReceipt from '../views/generalAffair/stationery/goods-receipt/Index'
import StationeryGoodsReceiptCreate from '../views/generalAffair/stationery/goods-receipt/Create'
import StationeryGoodsReceiptDetail from '../views/generalAffair/stationery/goods-receipt/Detail'
import StationeryGoodsDistribution from '../views/generalAffair/stationery/goods-distribution/Index'
import StationeryGoodsDistributionCreate from '../views/generalAffair/stationery/goods-distribution/Create'
import StationeryGoodsDistributionDetail from '../views/generalAffair/stationery/goods-distribution/Detail'
import StationeryStock from '../views/generalAffair/stationery/stock/Index'
import StationeryStockDetail from '../views/generalAffair/stationery/stock/Detail'

import AssetVendor from '../views/generalAffair/asset/vendor/Index'
import AssetCategory from '../views/generalAffair/asset/category/Index'
import AssetItem from '../views/generalAffair/asset/item/Index'
import AssetGoodsReceipt from '../views/generalAffair/asset/goods-receipt/Index'
import AssetGoodsReceiptCreate from '../views/generalAffair/asset/goods-receipt/Create'
import AssetGoodsReceiptDetail from '../views/generalAffair/asset/goods-receipt/Detail'
import AssetGoodsDistribution from '../views/generalAffair/asset/goods-distribution/Index'
import AssetGoodsDistributionCreate from '../views/generalAffair/asset/goods-distribution/Create'
import AssetGoodsDistributionDetail from '../views/generalAffair/asset/goods-distribution/Detail'
import AssetStock from '../views/generalAffair/asset/stock/Index'
import AssetStockDetail from '../views/generalAffair/asset/stock/Detail'

import Letter from '../views/generalAffair/letter/Index'
import LetterDetail from '../views/generalAffair/letter/Detail'

import Profile from '../views/profile/Index'
import ProfileEmployee from '../views/profile/Index'

import NotFound from '../views/NotFound'
import Approval from '../views/Approval'

import MyKpi from '../views/payroll/my-kpi';

const { Content } = Layout

const AppContent = () => {
    const [sidebarDisplay, setSidebarDisplay] = useState('auto')

    useEffect(() => {
        let sideBarMode = sessionStorage.getItem('sidebar')
        if (sideBarMode) {
            setSidebarDisplay(sideBarMode)
        } else {
            setSidebarDisplay('auto')
        }
    }, []);

    return (
        <div>
            {/* <HeaderOld/>  */}
            <Layout className="site-layout site-content">
                <Sider display={sidebarDisplay} />
                <Content style={{ margin: '10px 16px' }}>
                    <Header display={sidebarDisplay} setSidebar={(v) => setSidebarDisplay(v)} />
                    <Switch>
                        <Route exact path="/" component={Dashboard} />

                        <Route exact path="/profile" component={Profile} />
                        <Route exact path="/profile/:employeeCode" component={ProfileEmployee} />

                        <Route exact path="/organization/employee" component={Employee} />
                        <Route exact path="/organization/company" component={CompanyInformation} />
                        <Route exact path="/organization/branch" component={Branch} />
                        <Route exact path="/organization/division" component={Division} />
                        <Route exact path="/organization/division/custom" component={DivisionCustom} />
                        <Route exact path="/organization/position" component={Position} />

                        <Route exact path="/dashboard/attendance" component={DashboardAttendance} />
                        <Route exact path="/dashboard/employee" component={DashboardEmployee} />
                        <Route exact path="/dashboard/late" component={DashboardLate} />
                        <Route exact path="/dashboard/admin" component={DashboardAdmin} />

                        <Route exact path="/dashboardHr/employee" component={DashboardHrEmployee} />
                        <Route exact path="/dashboardHr/attendance" component={DashboardHrAttendance} />
                        <Route exact path="/dashboardHr/admin" component={DashboardHrAdmin} />

                        <Route exact path="/report/attendance" component={ReportAttendance} />
                        <Route exact path="/report/employee" component={ReportEmployee} />
                        <Route exact path="/report/jobs" component={ReportDailyJob} />
                        <Route exact path="/report/payroll" component={ReportPayroll} />
                        <Route exact path="/report/salary" component={ReportSalary} />
                        <Route exact path="/report/payroll-operational" component={ReportPayrollOperational} />
                        <Route exact path="/report/budget" component={ReportBudget} />
                        <Route exact path="/report/leave" component={ReportLeave} />
                        <Route exact path="/report/overtime" component={ReportOvertime} />
                        <Route exact path="/report/reimbursement" component={ReportReimbursement} />
                        <Route exact path="/report/entertainment" component={ReportEntertainment} />
                        <Route exact path="/report/medical" component={ReportMedical} />
                        <Route exact path="/report/business-trip" component={ReportBusinessTrip} />
                        <Route exact path="/report/office-stationery" component={ReportStationery} />
                        <Route exact path="/report/purchase-request" component={ReportPurchaseRequest} />
                        <Route exact path="/report/medical-history" component={ReportMedicalHistory} />
                        <Route exact path="/report/annual-report" component={AnnualLeaveReport} />
                        <Route exact path="/report/annual-report/detail/:employeeCode/:year" component={AnnualLeaveReportDetail} />
                        {/* <Route exact path="/report/vote" component={ReportVote} /> */}
                        <Route exact path="/report/kpi" component={ReportKPI} />
                        <Route exact path="/report/component-kpi" component={ReportComponentKPI} />
                        <Route exact path="/report/training-hours" component={ReportTrainingHours} />
                        <Route exact path="/report/comission" component={ReportComission} />

                        <Route exact path="/report/vote-result" component={VoteResult} />
                        <Route exact path="/report/vote-result/detail/:voteId" component={VoteResultDetail} />
                        <Route exact path="/report/training" component={ReportTraining} />
                        <Route exact path="/report/training/detail/:trainingId" component={ReportTrainingDetail} />

                        <Route exact path="/report/vacancy" component={ReportVacancy} />
                        <Route exact path="/report/vacancy/detail/:vacancyId" component={ReportVacancyDetail} />
                        <Route exact path="/report/vacancy/detail/applicant/:applicantId" component={ReportApplicantDetail} />

                        <Route exact path="/leave" component={Leave} />
                        <Route exact path="/leave/create" component={LeaveCreate} />
                        <Route exact path="/leave/:leaveId" component={LeaveDetail} />
                        <Route exact path="/overtime" component={Overtime} />
                        <Route exact path="/overtime/create" component={OvertimeCreate} />
                        <Route exact path="/overtime/:overtimeId" component={OvertimeDetail} />
                        <Route exact path="/reimbursement" component={Reimbursement} />
                        <Route exact path="/reimbursement/create" component={ReimbursementCreate} />
                        <Route exact path="/reimbursement/:reimbursementId" component={ReimbursementDetail} />
                        <Route exact path="/entertainment" component={Entertainment} />
                        <Route exact path="/entertainment/create" component={EntertainmentCreate} />
                        <Route exact path="/entertainment/:entertainmentId" component={EntertainmentDetail} />
                        <Route exact path="/medical" component={Medical} />
                        <Route exact path="/medical/create" component={MedicalCreate} />
                        <Route exact path="/medical/:medicalId" component={MedicalDetail} />
                        <Route exact path="/business-trip" component={BusinessTrip} />
                        <Route exact path="/business-trip/create" component={BusinessTripCreate} />
                        <Route exact path="/business-trip/:tripId" component={BusinessTripDetail} />
                        <Route exact path="/office-stationery" component={OfficeStationary} />
                        <Route exact path="/office-stationery/create" component={OfficeStationaryCreate} />
                        <Route exact path="/office-stationery/:stationeryId" component={OfficeStationaryDetail} />
                        <Route exact path="/purchase-request" component={PurchaseRequest} />
                        <Route exact path="/purchase-request/create" component={PurchaseRequestCreate} />
                        <Route exact path="/purchase-request/detail" component={PurchaseRequestDetail} />
                        <Route exact path="/request-training" component={SubmissionTraining} />
                        <Route exact path="/request-training/detail/:trainingId" component={SubmissionTrainingDetail} />
                        <Route exact path="/request-training/evaluation/:trainingId" component={SubmissionTrainingEvaluation} />
                        <Route exact path="/request-training/external-form" component={SubmissionTrainingExternalForm} />
                        <Route exact path="/request-training/external-detail/:trainingId" component={SubmissionTrainingExternalDetail} />
                        <Route exact path="/request-training/done/:trainingId" component={SubmissionTrainingDone} />
                        <Route exact path="/send-reward-punishment" component={RewardPunishment} />

                        <Route exact path="/loan" component={Loan} />
                        <Route exact path="/loan/detail/:id" component={LoanDetail} />
                        <Route exact path="/loan/create/:id" component={CreateLoan} />
                        <Route exact path="/setup-loan" component={SetupLoan} />

                        <Route exact path="/softdev" component={Project} />
                        <Route exact path="/softdev/objective/:objectiveId" component={ObjectiveDetail} />

                        <Route exact path="/submission-list" component={SubmissionList} />
                        <Route exact path="/submission-list/business-trip/:requestId" component={SubmissionListBusinessTrip} />
                        <Route exact path="/submission-list/leave/:requestId" component={SubmissionListLeave} />
                        <Route exact path="/submission-list/overtime/:requestId" component={SubmissionListOvertime} />
                        <Route exact path="/submission-list/medical/:requestId" component={SubmissionListMedical} />
                        <Route exact path="/submission-list/reimbursement/:requestId" component={SubmissionListReimbursement} />
                        <Route exact path="/submission-list/purchase-request/:requestId" component={SubmissionListPurchaseRequest} />
                        <Route exact path="/submission-list/entertainment/:requestId" component={SubmissionListEntertainment} />
                        <Route exact path="/submission-list/office-stationery/:requestId" component={SubmissionListStationery} />
                        <Route exact path="/submission-list/training/:requestId" component={SubmissionListTraining} />
                        <Route exact path="/submission-list/attendance/:requestId" component={SubmissionListAttendance} />
                        <Route exact path="/submission-list/resign/:requestId" component={SubmissionListResign} />
                        <Route exact path="/submission-list/recruitment/:requestId" component={SubmissionListRecruitment} />
                        <Route exact path="/submission-list/voucher/:requestId" component={SubmissionListVoucher} />
                        <Route exact path="/submission-list/loan/:requestId" component={SubmissionListLoan} />
                        <Route exact path="/submission-list/loan-payment/:requestId" component={SubmissionListLoanPayment} />

                        <Route exact path="/documentation/regulation" component={CompanyRegulation} />
                        <Route exact path="/documentation/regulation/detail/:chapterId" component={CompanyRegulationDetail} />
                        <Route exact path="/documentation/sop" component={Sop} />
                        <Route exact path="/documentation/sop/detail/:sopId" component={SopDetail} />
                        <Route exact path="/documentation/exit-clearance" component={ExitClearance} />
                        <Route exact path="/documentation/exit-clearance/:exitId" component={ExitClearanceDetail} />
                        <Route exact path="/documentation/classification-iso" component={ClassificationISO} />
                        <Route exact path="/documentation/legalitas" component={Legalitas} />
                        <Route exact path="/documentation/legalitas/detail/:id" component={LegalitasDetail} />
                        <Route exact path="/documentation/iso" component={Iso} />

                        <Route exact path="/payroll" component={Payroll} />
                        <Route exact path="/my-kpi" component={MyKpi} />
                        <Route exact path="/payroll/payment" component={PayrollPayment} />

                        <Route exact path="/payroll/setup-rate-hourly" component={PayrollRateHourly} />
                        <Route exact path="/payroll/daily-payment" component={PayrollPaymentDaily} />
                        <Route exact path="/payroll/daily-payment/:employeeCode/:periodId/:periodDate" component={PayrollPaymentDailyDetail} />
                        
                        {/* <Route exact path="/payroll/spt" component={PayrollSpt}/> */}
                        <Route exact path="/payroll/spt" component={PayrollSptDetail} />
                        <Route exact path="/payroll/payment/:employeeCode/:periodId" component={PayrollPaymentDetail} />
                        <Route exact path="/payroll/setup" component={PayrollSetup} />
                        <Route exact path="/payroll/schema" component={PayrollSchema} />
                        <Route exact path="/payroll/setup-overtime" component={OvertimeSchema} />
                        <Route exact path="/payroll/schema/:employeeCode" component={PayrollSchemaDetail} />
                        <Route exact path="/payroll/sync" component={PayrollSync} />


                        <Route exact path="/management/important-date" component={ImportantDate} />
                        <Route exact path="/management/approval-schema" component={ApprovalSchema} />
                        <Route exact path="/management/approval-schema/detail" component={ApprovalSchemaDetail} />
                        <Route exact path="/management/approval-schema/custom" component={ApprovalCustom} />
                        <Route exact path="/management/approval-schema/custom/detail" component={ApprovalCustomDetail} />
                        <Route exact path="/management/budget" component={Budget} />
                        <Route exact path="/management/budget-realization" component={BudgetRealization} />
                        <Route exact path="/management/announcement" component={Announcement} />
                        <Route exact path="/management/announcement/detail/:announcementId" component={AnnouncementDetail} />
                        <Route exact path="/management/validity-hour" component={ValidityHour} />

                        <Route exact path="/management/vacancy" component={Vacancy} />
                        <Route exact path="/management/vacancy/detail/:vacancyId" component={VacancyDetail} />
                        <Route exact path="/management/vacancy/applicant/:vacancyId/:applicantId" component={Applicant} />
                        <Route exact path="/management/vacancy/detail/applicant/:applicantId" component={ProfileApplicant} />

                        <Route exact path="/management/appraisal" component={Appraisal} />
                        <Route exact path="/management/appraisal/component" component={AppraisalComponent} />
                        <Route exact path="/management/appraisal/set-item/:employeeCode" component={AppraisalSetItemKpi} />
                        <Route exact path="/management/appraisal/set-period" component={AppraisalSetPeriod} />
                        <Route exact path="/management/appraisal/set-evaluator/:appraisalId" component={AppraisalSetEvaluator} />
                        <Route exact path="/management/appraisal/form-appraisal/:appraisalId" component={AppraisalForm} />
                        <Route exact path="/management/appraisal/detail" component={AppraisalDetail} />
                        <Route exact path="/management/appraisal/setup" component={AppraisalSetup} />
                        <Route exact path="/management/appraisal/ranking" component={AppraisalRanking} />
                        <Route exact path="/management/appraisal/ranking-evaluator" component={AppraisalRankingEvaluator} />
                        <Route exact path="/management/template-component" component={MasterAppraisalItem} />

                        <Route exact path="/management/shift" component={Shift} />

                        <Route exact path="/management/reward" component={Reward} />
                        <Route exact path="/management/reward/create" component={RewardCreate} />
                        <Route exact path="/management/reward/edit/:rewardId" component={RewardEdit} />

                        <Route exact path="/management/employee-status" component={EmployeeStatus} />
                        <Route exact path="/management/leave-rights" component={LeaveRights} />
                        <Route exact path="/management/medical-allowance" component={MedicalAllowance} />

                        <Route exact path="/management/vote" component={Vote} />
                        <Route exact path="/management/vote/detail/:voteId" component={VoteDetail} />
                        <Route exact path="/voting/:votingUrl" component={Voting} />

                        <Route exact path="/management/test/multiple-choice" component={TestMultipleChoice} />
                        <Route exact path="/management/test/multiple-choice/:testId" component={TestMultipleChoiceCreate} />
                        <Route exact path="/management/test/essay" component={TestEssay} />
                        <Route exact path="/management/test/essay/:testId" component={TestEssayCreate} />

                        <Route exact path="/training" component={Training} />
                        <Route exact path="/training/create" component={TrainingCreate} />
                        <Route exact path="/training/edit/:trainingId" component={TrainingEdit} />

                        <Route exact path="/setting/user" component={User} />
                        <Route exact path="/setting/bank" component={Bank} />
                        <Route exact path="/setting/coa" component={Coa} />
                        <Route exact path="/setting/country" component={Country} />
                        <Route exact path="/setting/province" component={Province} />
                        <Route exact path="/setting/city" component={City} />
                        <Route exact path="/setting/company" component={Company} />
                        <Route exact path="/setting/email" component={Email} />
                        <Route exact path="/setting/general" component={General} />
                        <Route exact path="/setting/general/detail/:generalCode" component={GeneralDetail} />
                        <Route exact path="/setting/area" component={Area} />
                        <Route exact path="/setting/custom-attendance-area" component={CustomAttendanceArea} />
                        <Route exact path="/setting/division-attendance-area" component={DivisionAttendanceArea} />
                        <Route exact path="/organization/kpi" component={Kpi} />
                        <Route exact path="/mom/:momId" component={Mom} />
                        <Route exact path="/setting/instruction" component={Instruction} />
                        <Route exact path="/setting/instruction/:instructionId" component={InstructionForm} />
                        <Route exact path="/setting/version-patch" component={Version} />
                        <Route exact path="/setting/version-patch/detail/:versionId" component={VersionDetail} />
                        <Route exact path="/setting/application" component={Application} />
                        <Route exact path="/setting/application/detail/:application_id" component={UserAccess} />

                        <Route exact path="/stationery/category" component={StationeryCategory} />
                        {/* <Route exact path="/stationery/item" component={StationeryItem}/> */}
                        <Route exact path="/stationery/item" component={ItemStationery} />
                        <Route exact path="/stationery/goods-receipt" component={StationeryGoodsReceipt} />
                        <Route exact path="/stationery/goods-receipt/create" component={StationeryGoodsReceiptCreate} />
                        <Route exact path="/stationery/goods-receipt/:goodsReceiptId" component={StationeryGoodsReceiptDetail} />
                        <Route exact path="/stationery/goods-distribution" component={StationeryGoodsDistribution} />
                        <Route exact path="/stationery/goods-distribution/create" component={StationeryGoodsDistributionCreate} />
                        <Route exact path="/stationery/goods-distribution/:distributionId" component={StationeryGoodsDistributionDetail} />
                        <Route exact path="/stationery/stock" component={StationeryStock} />
                        <Route exact path="/stationery/stock/:stockId" component={StationeryStockDetail} />

                        <Route exact path="/asset/vendor" component={AssetVendor} />
                        <Route exact path="/asset/category" component={AssetCategory} />
                        <Route exact path="/asset/item" component={AssetItem} />
                        <Route exact path="/asset/goods-receipt" component={AssetGoodsReceipt} />
                        <Route exact path="/asset/goods-receipt/create" component={AssetGoodsReceiptCreate} />
                        <Route exact path="/asset/goods-receipt/:goodsReceiptId" component={AssetGoodsReceiptDetail} />
                        <Route exact path="/asset/goods-distribution" component={AssetGoodsDistribution} />
                        <Route exact path="/asset/goods-distribution/create" component={AssetGoodsDistributionCreate} />
                        <Route exact path="/asset/goods-distribution/:distributionId" component={AssetGoodsDistributionDetail} />
                        <Route exact path="/asset/stock" component={AssetStock} />
                        <Route exact path="/asset/stock/:stockId" component={AssetStockDetail} />

                        <Route exact path="/letter" component={Letter} />
                        <Route exact path="/letter/detail/:letterId" component={LetterDetail} />

                        <Route exact path="/approval" component={Approval} />

                        <Route component={NotFound} />
                    </Switch>
                </Content>
            </Layout>
            <Footer />
        </div>
    );
}

export default AppContent;

import React from 'react';
import { Card, Tabs } from 'antd'
import { FieldTimeOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { ShowButton, DataTable } from '../../../components'
import { toFullDate, formatDateTime } from '../../../utils/dateHandler';

const { TabPane } = Tabs;

const Index = (props) => {
    const requestColumn = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    },
    {
        title: 'Destination',
        dataIndex: 'destination',
        key: 'destination'
    }, {
        title: 'Date',
        render: (row) => (
            <span>{toFullDate(row.from_date)} - {toFullDate(row.to_date)}</span>
        )
    }, {
        title: 'Purpose',
        dataIndex: 'purpose',
        key: 'purpose',
    }, {
        title: 'Request Date',
        dataIndex: 'created_at',
        render: (text) => (
            formatDateTime(text)
        )
    }, {
        title: 'Action',
        width: '10%',
        render: (row) => (
            <div className="text-center">
                <ShowButton link to={'/submission-list/business-trip/' + row.id} />
            </div>
        )
    }
    ];

    const requestData = props.data !== null ? [
        ...props.data?.map(el => {
            return {
                id: el?.request_id,
                from_date: el?.from_date,
                to_date: el?.to_date,
                purpose: el?.purpose,
                status: el?.status,
                destination: (el?.trip_type === "TTD") ? el?.city_name : el?.country_name,
                created_at: el?.created_at,
            }
        })
    ] : []

    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{ color: '#4ca3ff' }} /> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={requestColumn} dataSource={requestData.filter(el => el.status === "WAITING")} bordered />
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{ color: 'green' }} /> Approved</span>} key="approved">
                    <DataTable size="small" columns={requestColumn} dataSource={requestData.filter(el => el.status === "APPROVED")} bordered />
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{ color: 'red' }} /> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={requestColumn} dataSource={requestData.filter(el => el.status === "REJECTED")} bordered />
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;

import React, { useState, useEffect } from 'react';
import { Form, Input, message, Space } from 'antd'
import { FormModal, DataTable, Spinner, EditButton, DeleteButton, AddButton } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import { toIdr, extractNumber } from '../../../utils/currencyHandler'

const RateHourly = () => {
    const [hourCategoryForm] = Form.useForm()
    const [formModal, setFormModal] = useState(false)
    const [hourCategories, setHourCategories] = useState([])
    const [saving, setSaving] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [formState, setFormState] = useState('add')

    const fetchHourCategories = () => {
        setLoaded(false)
        SentralModel.list('HourCategory', { order: 'asc' }).then(res => {
            setHourCategories(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchHourCategories()
    }, []);

    const editComponent = (row) => {
        setFormState('edit')
        hourCategoryForm.setFieldsValue({
            working_rate_id: row.working_rate_id,
            description: row.description,
            limit: row.limit,
            rate: toIdr(row.rate)
        })
        setFormModal(true)
    }

    const deleteComponent = (id) => {
        message.loading('deleting component')
        SentralModel.deleteById('HourCategory', id).then((res) => {
            message.destroy()
            message.success('component deleted', 2)
            let tmp = hourCategories
            let index = tmp.findIndex(el => el.working_rate_id === id)
            tmp.splice(index, 1);
            setHourCategories([])
            setHourCategories(tmp)
            fetchHourCategories()
        })
    }

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    }, {
        title: 'Limit',
        dataIndex: 'limit',
        align: 'center',
        render: (text) => (
            text + ' minutes'
        )
    }, {
        title: 'Rate',
        dataIndex: 'rate',
        render: (text) => (
            toIdr(text)
        )
    }, {
        title: 'Action',
        align: 'center',
        render: (row) => (
            <div className="text-center">
                <Space>
                    <EditButton onEdit={() => editComponent(row)} />
                    <DeleteButton onConfirm={() => deleteComponent(row.working_rate_id)} />
                </Space>
            </div>
        )
    },
    ];

    const addComponent = () => {
        setFormState('add')
        hourCategoryForm.resetFields()
        hourCategoryForm.setFieldsValue({ working_rate_id: 0 })
        setFormModal(true)
    }


    const saveCategory = (v) => {
        setSaving(true)
        let payload = {
            working_rate_id: v.working_rate_id,
            description: v.description,
            limit: v.limit,
            rate: extractNumber(v.rate)
        }
        SentralModel.save('HourCategory', payload, v.working_rate_id).then(() => {
            fetchHourCategories()
            message.success('Hour Category saved');
            setSaving(false)
            setFormModal(false)
        })
    }

    const changeRate = (v) => {
        if (v.target.value) {
            hourCategoryForm.setFieldsValue({ rate: toIdr(extractNumber(v.target.value)) })
        } else {
            hourCategoryForm.setFieldsValue({ rate: 0 })
        }
    }

    return (
        <div>
            <div className="text-right mb-2">
                <AddButton onAdd={addComponent} title="Add Hour Category" />
            </div>
            {
                loaded ?
                    <DataTable bordered size="small" columns={columns} dataSource={hourCategories} />
                    : <Spinner />
            }

            <FormModal form={hourCategoryForm} title={(formState === "add") ? 'Add new Hour Category' : 'Edit Hour Category'}
                showModal={formModal}
                onCloseModal={() => setFormModal(false)}
                submitForm={(v) => saveCategory(v)}
                saving={saving}>
                <Form.Item name="working_rate_id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="description" label="Late Description">
                    <Input />
                </Form.Item>
                <Form.Item name="limit" label="Late Limit">
                    <Input addonAfter="minutes" />
                </Form.Item>
                <Form.Item name="rate" label="Rate">
                    <Input onChange={(v) => changeRate(v)} />
                </Form.Item>
            </FormModal>
        </div>
    );
}

export default RateHourly

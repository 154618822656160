import { useState } from 'react'
import { Card, message } from 'antd'
import { PageTitle } from '../../../components'
import RequestForm from './RequestForm'
import { SentralModel } from '../../../models/SentralModel'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const Create = () => {
    const history = useHistory()
    const [saving, setSaving] = useState(false)

    const submitLoan = (v) => {
        // const month = moment(Date.now()).format("M")
        // const dueDate = parseInt(month) + v.loan_period

        let payload = {
            ...v,
            start_period: moment(`${moment().year()}-${moment().month() + 2}-${moment().date()}`).format("YYYY-MM-25"),
            end_period: moment(`${(moment().month() + 1 + v.loan_period > 12) ? moment().year() + 1 : moment().year()}-${(moment().month() + 1 + v.loan_period > 12) ? moment().month() + 1 + v.loan_period - 12 : moment().month() + 1 + v.loan_period}-${moment().date()}`).format("YYYY-MM-25")
        }

        setSaving(true)
        SentralModel.action("Submission", "saveLoan", payload, null)
            .then((res) => {
                setSaving(false)
                message.success("Success Create Loan")
                history.goBack()
            }).catch((err) => {
                setSaving(false)
                message.warning("Failed Create Loan")
            })
    }

    return (
        <Card className="content-container">
            <PageTitle title="Loan Submission" breadcrumbs={[['Loan', '/loan'], ['Create']]}></PageTitle>
            <RequestForm onSubmit={(v) => submitLoan(v)} saving={saving} />
        </Card>
    )
}

export default Create
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Layout } from 'antd';
import AppContent from './layouts/AppContent'
import Login from './views/auth/Login'
import Forgot from './views/auth/Forgot'
import PasswordExpired from './views/auth/PasswordExpired'
import ScrollToTop from './Hooks/ScrollToTop'
import { Provider } from 'react-redux';
import store from './redux/store';
import 'antd/dist/antd.min.css';
import './assets/main.css'
import './assets/size.css'

function App() {
  const [authenticated, setAuthenticated] = useState(true)

  useEffect(() => {
    let user = localStorage.getItem('currentUser')
    if (!user) {
      setAuthenticated(false)
      if (
        document.location.pathname !== "/login" && document.location.pathname !== "/sso" && document.location.pathname !== "/approval" && document.location.pathname !== "/password-expired"
      ) {
        window.location.href = "/login?redirect=" + window.location.pathname
      }
    } else {
      if (document.location.pathname === "/login" || document.location.pathname === "/sso") {
        window.location.href = "/"
      }
    }
  }, []);

  return (
    <Provider store={store}>
      <div>
        <Router>
          <ScrollToTop />
          <Layout theme="light">
            <Layout style={{ minHeight: '100vh' }}>
              {
                (authenticated) ?
                  <AppContent />
                  :
                  <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot-password" component={Forgot} />
                    <Route exact path="/password-expired" component={PasswordExpired} />
                  </Switch>
              }
            </Layout>
          </Layout>
        </Router>
      </div>
    </Provider>
  );
}

export default App;

import { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Input, Row, Select, Space, Tag, Typography, message } from 'antd'
import { SentralModel } from '../../../models/SentralModel'
import { ActiveButton, DataTable, DeleteButton, EditButton, FormModal } from '../../../components'
import { toIdr } from '../../../utils/currencyHandler'

const { Text } = Typography

const OvertimeCompany = () => {
    const [ovtMultiplierData, setOvtMultiplierData] = useState([])
    const [ovtCompensationData, setOvtCompensationData] = useState([])
    const [ovtMultiplierModal, setOvtMultiplierModal] = useState(false)
    const [ovtCompensationModal, setOvtCompensationModal] = useState(false)
    const [ovtMultiplierDefault, setOvtMultiplierDefault] = useState({})
    const [ovtCompensationDefault, setOvtCompensationDefault] = useState({})

    const fetchOvtMultipler = () => {
        SentralModel.action("Overtime", "getOvertimeMultiplier", null, null).then((res) => {
            setOvtMultiplierData(res.data)
        })
    }

    const fetchOvtCompensation = () => {
        SentralModel.action("Overtime", "getOvertimeCompensation", null, null).then((res) => {
            setOvtCompensationData(res.data)
        })
    }

    useEffect(() => {
        fetchOvtMultipler()
        fetchOvtCompensation()

    }, [])

    const ovtMultColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
            align: 'center'
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category'
        },
        {
            title: 'First Hour',
            dataIndex: 'first_hour',
            key: 'first_hour'
        },
        {
            title: 'Second Hour',
            dataIndex: 'second_hour',
            key: 'second_hour'
        },
        {
            title: 'Multiply',
            dataIndex: 'multiply',
            key: 'multiply'
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                <Space>
                    <EditButton onEdit={() => onEditShowOvtMultiplier(row)} />
                    <DeleteButton onConfirm={() => onDeleteOvtMultiplier(row.overtime_multiplier_id)} />
                </Space>
            ),
            align: 'center'
        }
    ]

    const ovtCompColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
            align: 'center'
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category'
        },
        {
            title: 'Value',
            render: (row) => (
                row?.category === 'DIVIDER' ? row?.value : `${toIdr(row?.value)} /hour`
            ),
            key: 'value'
        },
        {
            title: 'Status',
            render: (row) => (
                <Tag color={row?.status === 1 ? "green" : "red"}>{row?.status === 1 ? "Active" : "Not Active"}</Tag>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                <Space>
                    {
                        row?.status === 0 ? <ActiveButton onActive={() => onActiveCompensation(row)} /> : null
                    }
                    <EditButton onEdit={() => onEditShowOvtCompensation(row)} />
                    <DeleteButton onConfirm={() => onDeleteOvtCompensation(row.overtime_compensation_id)} />
                </Space>
            ),
            align: 'center'
        }
    ]

    const onShowOvtMultiplier = () => {
        setOvtMultiplierModal(true)
        setOvtMultiplierDefault({
            overtime_multiplier_id: 0,
            first_hour: 0,
            second_hour: 0,
            multiply: 0,
            category: null
        })
    }

    const onEditShowOvtMultiplier = (v) => {
        setOvtMultiplierModal(true)
        setOvtMultiplierDefault({
            overtime_multiplier_id: v.overtime_multiplier_id,
            first_hour: v.first_hour,
            second_hour: v.second_hour,
            multiply: parseFloat(v.multiply),
            category: v.category
        })
    }

    const onShowOvtCompensation = () => {
        setOvtCompensationModal(true)
        setOvtCompensationDefault({
            overtime_compensation_id: 0,
            category: null,
            value: 0,
            status: 0,
        })
    }

    const onEditShowOvtCompensation = (v) => {
        setOvtCompensationModal(true)
        setOvtCompensationDefault({
            overtime_compensation_id: v.overtime_compensation_id,
            category: v.category,
            value: v.value,
            status: v.status
        })
    }

    const onSubmitMultiplier = (v) => {
        let payload = {
            first_hour: v.first_hour,
            second_hour: v.second_hour,
            multiply: parseFloat(v.multiply),
            category: v.category
        }

        SentralModel.action("Overtime", "saveOvertimeMultiplier", payload, v.overtime_multiplier_id).then((res) => {
            message.success("Success Save Data")
            fetchOvtMultipler()
            setOvtMultiplierModal(false)
        }).catch((err) => {
            message.warning("Failed Save Data")
        })
    }

    const onSubmitCompensation = (v) => {
        let payload = {
            category: v.category,
            value: v.value,
            status: v.status
        }

        SentralModel.action("Overtime", "saveOvertimeCompensation", payload, v.overtime_compensation_id).then((res) => {
            message.success("Success Save Data")
            fetchOvtCompensation()
            setOvtCompensationModal(false)
        }).catch((err) => {
            message.warning("Failed Save Data")
        })
    }

    const onActiveCompensation = (v) => {
        SentralModel.action("Overtime", "changeStatusOvertimeCompensation", null, v.overtime_compensation_id).then((res) => {
            message.success("Success Save Data")
            fetchOvtCompensation()
            setOvtCompensationData(false)
        }).catch((err) => {
            message.warning("Failed Save Data")
        })
    }

    const onDeleteOvtMultiplier = (v) => {
        SentralModel.action("Overtime", "deleteOvertimeMultiplier", null, v).then((res) => {
            message.success("Success Delete Data")
            fetchOvtMultipler()
        }).catch((err) => {
            message.warning("Failed Delete Data")
        })
    }

    const onDeleteOvtCompensation = (v) => {
        SentralModel.action("Overtime", "deleteOvertimeCompensation", null, v).then((res) => {
            message.success("Success Delete Data")
            fetchOvtCompensation()
        }).catch((err) => {
            message.warning("Failed Delete Data")
        })
    }

    return (
        <Card>
            <Card style={{ marginBottom: 25 }} title={
                <Row justify='space-between'>
                    <Col>
                        <Text>Overtime Multiplier</Text>
                    </Col>
                    <Col>
                        <Button type='primary' onClick={onShowOvtMultiplier}>Add Overtime Multiplier</Button>
                    </Col>
                </Row>
            }>
                {
                    ovtMultiplierData &&
                    <DataTable dataSource={ovtMultiplierData} columns={ovtMultColumns} search={false} pagination={false} />
                }
            </Card>

            <Card title={
                <Row justify='space-between'>
                    <Col>
                        <Text>Overtime Compensation</Text>
                    </Col>
                    <Col>
                        <Button type='primary' onClick={onShowOvtCompensation}>Add Overtime Compensation</Button>
                    </Col>
                </Row>
            }>
                {
                    ovtCompensationData &&
                    <DataTable dataSource={ovtCompensationData} columns={ovtCompColumns} search={false} pagination={false} />
                }
            </Card>

            {
                ovtMultiplierModal ?
                    <FormModal
                        title="Add Overtime Multiplier"
                        showModal={ovtMultiplierModal}
                        onCloseModal={() => setOvtMultiplierModal(false)}
                        defaultValues={ovtMultiplierDefault}
                        submitForm={onSubmitMultiplier}
                    >
                        <Form.Item hidden name="overtime_multiplier_id">
                            <Input />
                        </Form.Item>
                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="First Hour" name="first_hour" rules={[{ required: true }]}>
                                    <Input type='number' style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Second Hour" name="second_hour" rules={[{ required: true }]}>
                                    <Input type='number' style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Multiply" name="multiply" rules={[{ required: true }]}>
                            <Input type='number' style={{ width: '100%' }} addonAfter="/hour" />
                        </Form.Item>
                        <Form.Item label="Category" name="category" rules={[{ required: true }]}>
                            <Select
                                placeholder="Select Category"
                                options={
                                    [
                                        { value: 'WORKINGDAYS', label: 'WORKING DAYS' },
                                        { value: 'HOLIDAY', label: 'HOLIDAY' }
                                    ]
                                }
                            />
                        </Form.Item>

                    </FormModal> : null
            }

            {
                ovtCompensationModal ?
                    <FormModal
                        title="Add Overtime Multiplier"
                        showModal={ovtCompensationModal}
                        onCloseModal={() => setOvtCompensationModal(false)}
                        defaultValues={ovtCompensationDefault}
                        submitForm={onSubmitCompensation}
                    >
                        <Form.Item hidden name="overtime_compensation_id">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Category" name="category" rules={[{ required: true }]}>
                            <Select
                                placeholder="Select Category"
                                options={
                                    [
                                        { value: 'DIVIDER', label: 'DIVIDER' },
                                        { value: 'VALUE', label: 'VALUE' }
                                    ]
                                }
                            />
                        </Form.Item>
                        <Form.Item label="Value" name="value" rules={[{ required: true }]}>
                            <Input type='number' style={{ width: '100%' }} addonAfter="/hour" />
                        </Form.Item>
                    </FormModal> : null
            }
        </Card>
    )
}

export default OvertimeCompany
import React, { useEffect, useState } from 'react';
import '../../../assets/spt.css'
import { SentralModel } from '../../../models/SentralModel';
import {formatNumber} from '../../../utils/currencyHandler'
const SptFinal = (props) => {
    const {pemotong=null, period=null} = props
    const [spt, setSpt] = useState(null)
    const [employees, setEmployees] = useState([])

    const fetchSptDetail = (period) => {
        SentralModel.action('Spt','getSptDetail', {period:period}).then((res)=>{
            setSpt(res.data)
        })
    }

    const fetchEmployee = (period) => {
        SentralModel.action('Spt','getSptBulananList',{period_id:period},0).then((res) => {
            setEmployees(res.data)
        })
    }

    useEffect(() => {
        fetchSptDetail(period)
        fetchEmployee(period)
    }, [period]);

    const getTotal = (type) => {
        let total=0;
        employees.forEach(el => {
            total+=el.summary[type]
        })
        return formatNumber(total)
    }
    return (
        <div>
            <div style={{position:'relative',  overflowX:'auto'}}>
                <img style={{width:1150, border:'1px solid #ededed'}} src={process.env.PUBLIC_URL+'/images/spt/final.jpg'} alt="SPT Masa 1"/>
                <span className="spt-item-masa" style={{top:101,left:307}}>{(spt) ? spt.month_period : "-"}</span>
                <span className="spt-item-masa" style={{top:101,left:352}}>{(spt) ? spt.year_period : "-"}</span>

                <span className="spt-item-masa" style={{top:101,left:552}}></span>
                <span className="spt-item-masa" style={{top:101,left:755}}></span>
                <span className="spt-item-masa" style={{top:101,left:815}}></span>
                {
                    employees.map((el, key) => (
                        <div key={key}>
                            <div className="spt-item-bulanan" style={{top:(210 + (key*24)),left:65,width:'145px'}}></div>
                            <div className="spt-item-bulanan" style={{top:(210 + (key*24)),left:230,width:'145px'}}></div>
                            <div className="spt-item-bulanan" style={{top:(210 + (key*24)),left:380,width:'140px'}}></div>
                            <div className="spt-item-bulanan" style={{top:(210 + (key*24)),left:530,width:'140px'}}></div>
                            <div className="spt-item-bulanan" style={{top:(210 + (key*24)),left:725,width:'85px'}}></div>
                            <div className="spt-item-bulanan" style={{top:(210 + (key*24)),left:810,width:'135px'}}></div>
                            <div className="spt-item-bulanan" style={{top:(210 + (key*24)),left:975,width:'135px'}}></div>
                        </div>
                    ))
                }

                <div className="spt-item-bulanan" style={{top:700,left:810,width:'135px'}}>{getTotal('bruto')}</div>
                <div className="spt-item-bulanan" style={{top:700,left:975,width:'135px'}}>{getTotal('pph')}</div>

                {/* <div className="spt-item-bulanan" style={{top:697,left:763,width:'135px'}}>13.999.234</div>
                <div className="spt-item-bulanan" style={{top:697,left:918,width:'110px'}}>240.234</div> */}
            </div>
        </div>
    );
}

export default SptFinal;
